import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { RecurlyProvider, Elements } from '@recurly/react-recurly';
import { useMutation } from '@tanstack/react-query';
import { useHistory } from 'react-router-dom';
import HeaderBar from 'components/HeaderBar';
import PageLayout from 'components/PageLayout';
import { getDeviceInfo } from 'state/localStorageTyped';
import useMembershipState, {
  MEMBERSHIP_CODES,
  MEMBERSHIP_DESCRIPTION_TRANS_KEYS,
  MEMBERSHIP_TITLE_TRANS_KEYS,
} from 'state/useMembershipState';
import {
  ProcessMembershipPaymentTokenData,
  SavePaymentMethodData,
} from 'shared/types';
import { API } from 'api';
import RecurlyBillingInformation from 'components/RecurlyBillingInformation';
import { usePaymentApi } from 'hooks/usePaymentApi';
import { MEMBERSHIP_PLANS_AMOUNTS } from '../../../constants';

const Content = (): JSX.Element => {
  const { t } = useTranslation();
  const {
    email,
    firstName,
    lastName,
    clientToken,
    optInToUpdates,
    verificationToken,
    userId,
    updatePaymentMethod,
    plan,
  } = useMembershipState();
  const history = useHistory();

  const { processMembershipPaymentMutation } =
    usePaymentApi<ProcessMembershipPaymentTokenData>({
      onSuccess: () => {
        history.push('/membership/success');
      },
    });

  const savePaymentMethodMutation = useMutation<
    void,
    Error,
    SavePaymentMethodData,
    unknown
  >({
    mutationFn: async (data) => {
      await API.post('recurly_integration/update_billing_info/', data);
    },
    onSuccess: () => {
      history.push('/membership/account_info');
    },
  });

  return (
    <>
      <Helmet>
        <title>{t('membershipBillingInformation.title')}</title>
      </Helmet>
      <RecurlyProvider
        publicKey={import.meta.env.VITE_RECURLY_PUBLIC_TOKEN as string}
      >
        <Elements>
          <RecurlyBillingInformation<ProcessMembershipPaymentTokenData>
            transactionType="membership"
            initialState={{
              email,
              firstName,
              lastName,
              clientToken,
              optInToUpdates,
              transactionAmount: MEMBERSHIP_PLANS_AMOUNTS[plan],
              verificationToken,
              userId,
              updatePaymentMethod,
            }}
            processPaymentMutation={processMembershipPaymentMutation}
            savePaymentMethodMutation={savePaymentMethodMutation}
            membershipConfig={{
              code: MEMBERSHIP_CODES[plan],
              title: t(MEMBERSHIP_TITLE_TRANS_KEYS[plan]),
              description: t(MEMBERSHIP_DESCRIPTION_TRANS_KEYS[plan]),
            }}
          />
        </Elements>
      </RecurlyProvider>
    </>
  );
};

const MembershipBillingInformation = (): JSX.Element => {
  const deviceInfo = getDeviceInfo();
  const isMobilePlatform = !!deviceInfo?.isMobilePlatform;

  return (
    <PageLayout
      data-cname="MembershipBillingInformationPage"
      showBanner={false}
    >
      {{
        content: <Content />,
        headerBar: (
          <HeaderBar action={isMobilePlatform ? 'noControls' : 'back'} />
        ),
      }}
    </PageLayout>
  );
};

export default MembershipBillingInformation;
