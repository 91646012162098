import {
  CircularProgress,
  CircularProgressProps,
  Grid,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

type LoadingAndErrorsProps = {
  isLoading?: boolean;
  errorMessage?: string;
  textColor?:
    | 'initial'
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'textPrimary'
    | 'textSecondary'
    | 'error';
  progressColor?: CircularProgressProps['color'];
};

export const LoadingAndErrors = (props: LoadingAndErrorsProps): JSX.Element => {
  const { t } = useTranslation();
  const { isLoading, errorMessage, textColor = 'error', progressColor } = props;
  const errorText = errorMessage || t('common.unknownError');

  if (isLoading) {
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '20vh',
        }}
      >
        <Grid item>
          <CircularProgress
            sx={progressColor ? undefined : { color: 'black' }}
            color={progressColor}
          />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '10vh',
      }}
    >
      <Grid item>
        <Typography
          color={textColor}
          align="center"
          variant="h3"
          sx={{ padding: 2 }}
        >
          {errorText}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default LoadingAndErrors;
