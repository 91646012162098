import { Grid, Typography, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Trans, useTranslation } from 'react-i18next';
import {
  getAcreageStrParts,
  getRegionsDisplay,
  isWildFireScheduled,
} from 'shared/utils';
import { setNaiveISODateStringToUTC } from 'shared/dates';
import { GeoEvent } from 'shared/types';
import { differenceInDays } from 'date-fns';
import { StatTable } from 'components/StatTable';

type EmbedGeoEventInfoProps = {
  geoEvent: GeoEvent;
};

const useStyles = makeStyles()((theme: Theme) => ({
  infoBox: {
    flex: 1,
    padding: '0px 12px',
  },
  infoIcon: {
    width: 12,
    height: 12,
    marginLeft: theme.spacing(0.25),
  },
  titleHeader: {
    fontWeight: 600,
    marginRight: theme.spacing(0.75),
  },
  borderRight: {
    borderRight: '1px solid rgba(0, 0,0, 0.2)',
  },
  upperCaseText: {
    textTransform: 'uppercase',
  },
  centeredContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const EmbedGeoEventInfo = (props: EmbedGeoEventInfoProps): JSX.Element => {
  const { geoEvent } = props;
  const { classes } = useStyles();
  const { t, i18n } = useTranslation();

  const {
    name,
    dateCreated,
    data: { acreage, containment, isFps },
    address,
    regions,
    isActive,
    createdByName,
  } = geoEvent;

  const [acreageStr] = getAcreageStrParts(
    acreage,
    containment,
    i18n.resolvedLanguage,
  );

  const getStatusText = (): string => {
    const isScheduled = isWildFireScheduled(geoEvent);

    if (isScheduled && isActive) {
      return t('geoEvent.scheduled');
    }
    if (isActive) {
      return t('geoEvent.active');
    }
    return t('geoEvent.inactive');
  };

  const postedDaysAgo = isActive
    ? differenceInDays(
        new Date(),
        new Date(setNaiveISODateStringToUTC(dateCreated)),
      ) || null
    : null;

  const stats = [
    {
      label: t('geoEvent.acres'),
      value: acreageStr,
      testId: 'acresBox',
    },
    {
      label: t('geoEvent.containment'),
      value:
        !isFps && typeof containment === 'number' ? `${containment}%` : null,
      testId: 'containmentBox',
    },
    {
      label: t('geoEvent.status'),
      value: getStatusText(),
      testId: 'statusBox',
    },
  ];

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography className={classes.titleHeader} variant="h3">
          {name}
        </Typography>

        {!!address && <Typography>{address}</Typography>}

        <Typography variant="body2" color="textSecondary">
          {getRegionsDisplay(regions)}
        </Typography>
      </Grid>

      <Grid item xs={12} md={10}>
        <StatTable stats={stats} />
      </Grid>

      <Grid item xs={12} data-testid="createdBy">
        <Typography variant="subtitle1" color="textSecondary">
          {postedDaysAgo ? (
            <Trans
              i18nKey="geoEvent.embed.createdByDaysAgo"
              values={{
                name: createdByName.displayNameShort || t('geoEvent.watchDuty'),
                count: postedDaysAgo,
              }}
            >
              Created by <b>Watch Duty</b> • 1 day ago
            </Trans>
          ) : (
            <Trans
              i18nKey="geoEvent.embed.createdBy"
              values={{
                name: createdByName.displayNameShort || t('geoEvent.watchDuty'),
              }}
            >
              Created by <b>Watch Duty</b>
            </Trans>
          )}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default EmbedGeoEventInfo;
