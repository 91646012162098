import { App as CapacitorApp } from '@capacitor/app';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { createRoot } from 'react-dom/client';
import App from './App';
import '../css/index.css';
import '../css/react-spring-bottom-sheet.css';
import history from '../shared/history';
import { setDeviceInfo } from '../shared/mobile';
import { applyPolyfills } from '../shared/polyfills';

import { initSentry } from '../shared/sentry';
// import for bundling
import '../i18n/config';

applyPolyfills();

const handleAndroidBackButton = (): void => {
  if (window.location.pathname !== '/') {
    history.back();
    return;
  }

  // Back button on home page closes app
  CapacitorApp.exitApp();
};

const init = async (): Promise<void> => {
  await Promise.all([initSentry(), setDeviceInfo()]);
  defineCustomElements(window);

  CapacitorApp.addListener('backButton', handleAndroidBackButton);
  const container = document.getElementById('root');
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const root = createRoot(container!);

  root.render(
    /**
     * Strict mode only affects development builds, and it's currently breaking react-spring-bottom-sheet lib.
     * We can either disable it or be more specific in the components we want this feature on.
     * We should probably replace react-spring-bottom-sheet with a newer library that supports React v18.
     * Check this discussion for more context - https://github.com/facebook/react/issues/16362
     */
    // <React.StrictMode>
    <App />,
    // </React.StrictMode>
  );
};

init();
