import { useHistory } from 'react-router-dom';
import RegionSubscriptionEditor from 'components/RegionSubscriptionsEditor';
import { NotificationSetting } from 'shared/types';
import EmptyNotifications from 'components/EmptyNotifications';
import { NotificationTypes } from '../../../../constants';

type NotificationsContentProps = {
  regionSettings: NotificationSetting[];
};

const NotificationsContent = (
  props: NotificationsContentProps,
): JSX.Element => {
  const { regionSettings } = props;
  const history = useHistory();

  const handleAddCounties = (): void => {
    history.push('/notifications/add_counties');
  };

  const isAnyCountySelected = regionSettings.some(
    (rS) => rS.setting !== NotificationTypes.OFF.key,
  );

  if (isAnyCountySelected) {
    return (
      <RegionSubscriptionEditor
        regionSettings={regionSettings}
        onAddCounty={handleAddCounties}
      />
    );
  }

  return <EmptyNotifications />;
};

export default NotificationsContent;
