import { Box, Button, Container, Grid, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import VectorLogo from 'components/VectorLogo';

type PhotoSubmissionSuccessProps = {
  geoEventId?: number;
};

const useStyles = makeStyles()((theme) => ({
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: 'max(env(safe-area-inset-bottom), 16px)',
    overflowY: 'auto',
  },
  centeredContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const PhotoSubmissionSuccess = (
  props: PhotoSubmissionSuccessProps,
): JSX.Element => {
  const { geoEventId } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const goBack = (): void => {
    if (geoEventId) {
      history.replace(`/i/${geoEventId}`);
    } else {
      history.replace('/');
    }
  };

  return (
    <Container maxWidth="md" className={classes.container}>
      <Grid container direction="column" spacing={1}>
        <Grid item>
          <Box className={classes.centeredContainer}>
            <VectorLogo sx={{ paddingTop: 3 }} />
          </Box>

          <Typography variant="h3" align="center">
            {t('photoSubmission.success.title')}
          </Typography>
        </Grid>

        <Grid item>
          <Typography sx={{ marginBottom: 3 }}>
            {t('photoSubmission.success.description1')}
          </Typography>

          <Typography
            sx={{
              marginBottom: '16px',
            }}
          >
            {t('photoSubmission.success.description2')}
          </Typography>
        </Grid>

        <Grid item>
          <Button fullWidth size="large" onClick={goBack}>
            {t('photoSubmission.success.button')}
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default PhotoSubmissionSuccess;
