import { Capacitor } from '@capacitor/core';
import { Grid, Tooltip, Typography, Button } from '@mui/material';
import { useTranslation, Trans } from 'react-i18next';
import {
  getAcreageStrParts,
  getCurrentLocationAsShareUrl,
  isMobile,
  isWildFireScheduled,
} from 'shared/utils';
import {
  getTimePass,
  getDateFormatted,
  setNaiveISODateStringToUTC,
  formatDate,
  SHORT_MONTH_DAY_12HOUR_FORMAT,
} from 'shared/dates';
import { GeoEvent } from 'shared/types';
import { differenceInDays } from 'date-fns';
import { useShareOrCopy } from 'hooks/useShareOrCopy';
import { StatTable } from 'components/StatTable';
import { NotificationSwitch } from './NotificationSwitch';
import GeoEventStopped from './GeoEventStopped';

type GeoEventInfoProps = {
  geoEvent: GeoEvent;
};

const GeoEventUpdateTime = (props: GeoEventInfoProps): JSX.Element | null => {
  const { geoEvent } = props;
  const { dateModified } = geoEvent;
  const isScheduled = isWildFireScheduled(geoEvent);
  const { t } = useTranslation();

  if (!geoEvent.isActive) {
    return null;
  }

  if (isScheduled) {
    const prescribedDateStart = geoEvent.data.prescribedDateStart
      ? new Date(setNaiveISODateStringToUTC(geoEvent.data.prescribedDateStart))
      : null;

    const scheduledStart = prescribedDateStart
      ? formatDate(prescribedDateStart, SHORT_MONTH_DAY_12HOUR_FORMAT, true)
      : null;

    return (
      <Typography>
        {/* eslint-disable-next-line i18next/no-literal-string */}
        {t('geoEvent.scheduledStart')} &bull; <b>{scheduledStart}</b>
      </Typography>
    );
  }

  return (
    <Typography>
      {t('common.updated')}&nbsp;
      <b>{getTimePass(dateModified)}</b>
    </Typography>
  );
};

const GeoEventInfo = (props: GeoEventInfoProps): JSX.Element => {
  const { geoEvent } = props;
  const { t, i18n } = useTranslation();

  const {
    name,
    dateCreated,
    dateModified,
    data: { acreage, containment, isFps },
    address,
    isActive,
    createdByName,
  } = geoEvent;

  const { canShareOrCopy, shareOrCopyUrl } = useShareOrCopy();

  const isScheduled = isWildFireScheduled(geoEvent);

  const handleShare = (): void => {
    const hashTag = `#${name.replace(/\s+/g, '')}`;
    const shareText = address ? `${name} @ ${address} - ${hashTag}` : name;

    shareOrCopyUrl({
      androidDialogTitle: t('geoEvent.shareIncident'),
      title: name,
      text: shareText,
      url: getCurrentLocationAsShareUrl(new Date(dateModified)),
    });
  };

  const shareTooltipText = Capacitor.isNativePlatform()
    ? t('common.share')
    : t('common.copyLinkToClipboard');

  const [acreageStr] = getAcreageStrParts(
    acreage,
    containment,
    i18n.resolvedLanguage,
  );

  const getStatusText = (): string => {
    if (isScheduled && isActive) {
      return t('geoEvent.scheduled');
    }
    if (isActive) {
      return t('geoEvent.active');
    }
    return t('geoEvent.inactive');
  };

  const postedDaysAgo = isActive
    ? differenceInDays(
        new Date(),
        new Date(setNaiveISODateStringToUTC(dateCreated)),
      ) || null
    : null;

  const stats = [
    {
      label: t('geoEvent.acres'),
      value: acreageStr,
      testId: 'acresBox',
    },
    {
      label: t('geoEvent.containment'),
      value: typeof containment === 'number' ? `${containment}%` : null,
      testId: 'containmentBox',
    },
    {
      label: t('geoEvent.status'),
      value: getStatusText(),
      testId: 'statusBox',
    },
  ];

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <StatTable stats={stats} />
      </Grid>

      <Grid item xs={12} data-testid="createdBy">
        {isFps && <GeoEventStopped />}

        <GeoEventUpdateTime geoEvent={geoEvent} />

        <Typography variant="subtitle1" color="textSecondary">
          {postedDaysAgo ? (
            <Trans
              i18nKey="geoEvent.createdByDaysAgo"
              values={{
                name: createdByName.displayNameShort || t('geoEvent.watchDuty'),
                dateFormatted: getDateFormatted(dateCreated),
                count: postedDaysAgo,
              }}
            >
              Created by <b>Watch Duty</b> • 1 day ago • Aug 4 at 12:54 PM
            </Trans>
          ) : (
            <Trans
              i18nKey="geoEvent.createdBy"
              values={{
                name: createdByName.displayNameShort || t('geoEvent.watchDuty'),
                dateFormatted: getDateFormatted(dateCreated, {
                  includeYear: true,
                }),
              }}
            >
              Created by <b>Watch Duty</b> • Aug 4, 2024 at 12:54 PM
            </Trans>
          )}
        </Typography>
      </Grid>

      {isMobile() && <NotificationSwitch geoEvent={geoEvent} />}

      <Grid item xs={12}>
        {canShareOrCopy && (
          <Tooltip title={shareTooltipText}>
            <Button
              fullWidth
              color="inherit"
              variant="outlined"
              onClick={handleShare}
              size="large"
            >
              {t('geoEvent.shareIncident')}
            </Button>
          </Tooltip>
        )}
      </Grid>
    </Grid>
  );
};

export default GeoEventInfo;
