import { Skeleton, Stack, Typography } from '@mui/material';
import { getGeoEventIcon } from 'components/Map/Icons';
import {
  MapSearchURLParams,
  SearchResult,
  SearchResultRow,
  getGoogleMapsSearchSource,
  getLatLngSearchSource,
  getMapboxMapsSearchSource,
  inactiveWildfireGeoEventSearchSource,
  useMapSearch,
  wildfireGeoEventSearchSource,
} from 'hooks/useMapSearch';
import { useTranslation } from 'react-i18next';
import { useMap } from 'shared/map-exports';
import { WildfireListItem } from 'shared/types';
import { getTimePass } from 'shared/dates';
import { useAuthState, useMapState } from 'state';
import { useMapSearchState } from 'state/useMapSearchState';
import useSelectedLocationState from 'state/useSelectedLocationState';
import { useQueryParams } from 'hooks/useQueryParms';
import { useVisibleWildfireGeoEvents } from './useVisibleWildfireGeoEvents';

type SearchResultsProps = {
  onBeforeClickResult?: () => void;
};

const visibleWildfireToSearchResult = (
  geoEvent: WildfireListItem,
): SearchResult => ({
  sourceId: 'wildfire',
  action: 'link',
  id: geoEvent.id,
  primaryText: geoEvent.name,
  secondaryText: geoEvent.address || '',
  tertiaryText: getTimePass(geoEvent.dateModified),
  avatar: <img src={getGeoEventIcon(geoEvent).data} alt="" height={40} />,
  href: `/i/${geoEvent.id}`,
});

export const SearchResults = (
  props: SearchResultsProps,
): JSX.Element | JSX.Element[] | null => {
  const { onBeforeClickResult } = props;
  const { incidentMapState } = useMapState();
  const { lat, lng } = incidentMapState.center;
  const { t } = useTranslation();
  const [{ searchType }] = useQueryParams<MapSearchURLParams>();

  // for focusing a pin for adding a place. In the future we should use an href and history.replaceState() for this
  const { setSelectedValue } = useMapSearchState();
  const { setSelectedLocation } = useSelectedLocationState();
  const { default: map } = useMap();

  const {
    permissions: { isInternalUser },
    showMembershipProFeatures,
  } = useAuthState();

  const isHistoricalSearch =
    searchType === 'inactive' && showMembershipProFeatures;

  const mappingSource = isInternalUser
    ? getGoogleMapsSearchSource({
        map: map?.getMap(),
        setSelectedValue,
        setSelectedLocation,
      })
    : getMapboxMapsSearchSource({
        map: map?.getMap(),
        setSelectedValue,
        setSelectedLocation,
      });

  const sources = isHistoricalSearch
    ? [inactiveWildfireGeoEventSearchSource]
    : [
        wildfireGeoEventSearchSource,
        getLatLngSearchSource({
          map: map?.getMap(),
          setSelectedValue,
          setSelectedLocation,
        }),
        mappingSource,
      ];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { searchQuery, searchResults, isLoading } = useMapSearch<any>({
    proximity: `${lng},${lat}`,
    sources,
  });

  const showDefaultGeoEvents = !searchQuery && !isHistoricalSearch;

  const visibleWildfires = useVisibleWildfireGeoEvents({
    enabled: showDefaultGeoEvents,
  });

  const results = showDefaultGeoEvents
    ? visibleWildfires.map(visibleWildfireToSearchResult)
    : searchResults;

  if (isLoading) {
    return Array.from({ length: 10 }).map((_, index) => (
      <Skeleton
        // Key not important since this is the same content repeated
        // eslint-disable-next-line react/no-array-index-key
        key={index}
        variant="text"
        height={40}
        sx={{ marginLeft: 2, marginRight: 2 }}
      />
    ));
  }

  if (!results.length && searchQuery) {
    return (
      <>
        <Typography sx={{ margin: 2, textAlign: 'center' }}>
          {t('searchBar.noResults')}
        </Typography>
        {isHistoricalSearch && (
          <Typography sx={{ margin: 2, textAlign: 'center' }}>
            {t('searchBar.noResultsHistoricalNote')}
          </Typography>
        )}
      </>
    );
  }

  if (!results.length) {
    return null;
  }

  return (
    <Stack>
      <Stack
        direction="row"
        sx={{
          gap: 1,
          justifyContent: 'space-between',
          alignItems: 'end',
          margin: 2,
        }}
      >
        <Typography variant="h3">
          {showDefaultGeoEvents
            ? t('searchBar.incidents')
            : t('searchBar.results')}
        </Typography>
        <Typography variant="body2" component="h4" color="gray">
          {t('searchDrawer.lastUpdate')}
        </Typography>
      </Stack>
      {results.map((result) => (
        <SearchResultRow
          key={result.id}
          searchTerm={searchQuery}
          searchResult={result}
          onBeforeClick={onBeforeClickResult}
        />
      ))}
    </Stack>
  );
};
