import { API } from 'api';
import { TFunction } from 'i18next';
import { ProcessPaymentState } from 'shared/types';
import { formatToUSDollars } from 'shared/utils';
import Yup from 'shared/yup-extended';
import { RequiredStringSchema } from 'yup/lib/string';

export type FormValues = {
  firstName: string;
  lastName: string;
  email: string;
  optInToUpdates: boolean;
};

export const isEmailAvailable = async (
  email: string,
  cache: Map<string, boolean>,
): Promise<boolean> => {
  if (cache.has(email)) {
    return cache.get(email)!;
  }
  const response = await API.get<{ hasActiveMembership: boolean }>(
    `/recurly_integration/membership_validation/?email=${encodeURIComponent(
      email,
    )}`,
  );
  const isAvailable = !response.data.hasActiveMembership;
  cache.set(email, isAvailable);
  return isAvailable;
};

export const isFieldDisabled = (
  isMembershipTransaction: boolean,
  initialState: ProcessPaymentState,
  initialValue: string,
): boolean => {
  const { existingUser, updatePaymentMethod } = initialState;

  if (isMembershipTransaction) {
    return false;
  }

  if (!existingUser || !initialValue) {
    return false;
  }

  return !updatePaymentMethod;
};

export const getPaymentText = (
  t: TFunction,
  isMembershipTransaction: boolean,
  isMembershipUpgrade: boolean | undefined,
  transactionAmount: number,
): string => {
  if (isMembershipTransaction && !isMembershipUpgrade) {
    return t('recurlyAccountInformation.paymentMethod.purchaseMembership');
  }
  if (isMembershipTransaction && isMembershipUpgrade) {
    return t('recurlyAccountInformation.paymentMethod.upgradeMembership');
  }
  return t('recurlyAccountInformation.paymentMethod.donate', {
    amount: formatToUSDollars(transactionAmount),
  });
};

export const getEmailValidationSchema = (input: {
  cache: Map<string, boolean>;
  skip: boolean;
  requiredErrMsg: string;
  invalidErrMsg: string;
  registeredErrMsg: string;
}): RequiredStringSchema<string | undefined, Record<string, unknown>> => {
  const { cache, skip, requiredErrMsg, invalidErrMsg, registeredErrMsg } =
    input;
  return Yup.string()
    .required(requiredErrMsg)
    .test({
      name: 'is-email-available',
      // eslint-disable-next-line func-names, object-shorthand
      test: async function (value) {
        if (!value || skip) {
          return true;
        }

        try {
          Yup.string()
            .email(invalidErrMsg)
            .validTLD(invalidErrMsg)
            .validateSync(value);
        } catch (error) {
          const err = error as Yup.ValidationError;
          return this.createError({ message: err.message, type: err.type });
        }

        // Check email availability
        const isAvailable = await isEmailAvailable(value, cache);

        if (!isAvailable) {
          return this.createError({
            message: registeredErrMsg,
            type: 'registered',
          });
        }

        return true;
      },
    });
};

export const getValidationSchema = (
  t: TFunction,
  existingUser: boolean,
  emailValidationCache: Map<string, boolean>,
): Yup.SchemaOf<FormValues> =>
  Yup.object({
    firstName: Yup.string().required(
      t('recurlyAccountInformation.inputs.firstName.required'),
    ),
    lastName: Yup.string().required(
      t('recurlyAccountInformation.inputs.lastName.required'),
    ),
    email: getEmailValidationSchema({
      cache: emailValidationCache,
      skip: existingUser,
      requiredErrMsg: t('recurlyAccountInformation.inputs.email.required'),
      invalidErrMsg: t('recurlyAccountInformation.inputs.email.invalid'),
      registeredErrMsg: t('recurlyAccountInformation.registeredEmail'),
    }),
    optInToUpdates: Yup.boolean().required(
      t('recurlyAccountInformation.inputs.optInToUpdates.required'),
    ),
  });
