import { TFunction } from 'i18next';
import { API } from 'api';
import { ProcessMembershipUpgradeData } from 'shared/types';
import Yup from 'shared/yup-extended';
import { IapCheckoutDetails } from 'state/useIapState';
import { getEmailValidationSchema } from 'components/RecurlyAccountInformation/utils';

export type ProcessIapPurchaseData = {
  clientToken: string;
  externalAccountCode: string;
  email: string;
  firstName: string;
  lastName: string;
  optInToUpdates: boolean;
  isRecurring: boolean;
  planCode: string;
};

export type PreProcessIapPurchaseData = {
  externalAccountCode: string;
  email: string;
  firstName: string;
  lastName: string;
  optInToUpdates: boolean;
};

export type FormValues = Omit<
  IapCheckoutDetails,
  'clientToken' | 'externalAccountCode' | 'autoRenew' | 'verificationToken'
>;

export const getValidationSchema = (
  t: TFunction,
  isMembershipUpgrade: boolean,
  cache: Map<string, boolean>,
): Yup.SchemaOf<FormValues> =>
  Yup.object({
    firstName: Yup.string().required(
      t('membershipCheckout.inputs.firstName.required'),
    ),
    lastName: Yup.string().required(
      t('membershipCheckout.inputs.lastName.required'),
    ),
    email: getEmailValidationSchema({
      cache,
      skip: isMembershipUpgrade,
      requiredErrMsg: t('membershipCheckout.inputs.email.required'),
      invalidErrMsg: t('membershipCheckout.inputs.email.invalid'),
      registeredErrMsg: t('membershipCheckout.registeredEmail'),
    }),
    optInToUpdates: Yup.boolean().required(
      t('membershipCheckout.inputs.optInToUpdates.required'),
    ),
  });

export const processIapPurchaseApi = async (
  data: ProcessIapPurchaseData,
): Promise<void> => {
  await API.post('recurly_integration/iap_create/', data);
};

export const preProcessPurchaseIapApi = async (
  data: PreProcessIapPurchaseData,
): Promise<void> => {
  await API.post('recurly_integration/pre_create_iap/', data);
};

export const upgradeMembershipApi = async (
  data: ProcessMembershipUpgradeData,
): Promise<void> => {
  await API.post('recurly_integration/upgrade_membership/', data);
};

export const handleValidatorSuccess = (
  callback: CdvPurchase.Callback<CdvPurchase.Validator.Response.Payload>,
  transaction: CdvPurchase.Validator.Response.NativeTransaction,
  productId: string,
): void => {
  callback({
    ok: true,
    data: {
      id: productId,
      latest_receipt: true,
      transaction,
    },
  });
};
