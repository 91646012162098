import { Box, Grid, Paper, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { API } from 'api';
import HeaderBar from 'components/HeaderBar';
import PageLayout from 'components/PageLayout';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, useParams } from 'react-router-dom';

const useStyles = makeStyles()((theme) => ({
  paper: {
    padding: theme.spacing(2),
    margin: '0 auto',
    overflowY: 'auto',
    width: '100%',
    maxWidth: '1000px',
  },
}));

type PassthroughParams = {
  route: string;
};

const Content = (): JSX.Element => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { route } = useParams<PassthroughParams>();
  const [message, setMessage] = useState('');

  useEffect(() => {
    API.get(`/${route}`)
      .then((response) => setMessage(response.data))
      .catch((error) => {
        setMessage(error.message);
      });
  }, [route]);

  return (
    <>
      <Helmet>
        <title>{t('passthrough.title')}</title>
      </Helmet>

      <Paper elevation={0} className={classes.paper}>
        <Grid
          container
          spacing={2}
          sx={{ height: '100%', justifyContent: 'center' }}
        >
          <Grid item xs={12}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <Typography variant="h1">{t('passthrough.title')}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <Typography>{message || t('passthrough.description')}</Typography>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

const Passthrough = (props: RouteComponentProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <PageLayout data-cname="PassthroughPage">
      {{
        content: <Content />,
        headerBar: <HeaderBar action="/" title={t('passthrough.title')} />,
      }}
    </PageLayout>
  );
};

export default Passthrough;
