import { useEffect, useMemo, useRef, useState } from 'react';
import { Grid, Typography, Button, Container } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { NotificationSetting } from 'shared/types';
import { getActiveSettings, getSortedRegionsSections } from 'shared/utils';
import GrayButton from 'components/GrayButton';
import RegionEditorCard from './RegionEditorCard';

type RegionSubscriptionEditorProps = {
  regionSettings: NotificationSetting[];
  onAddCounty: () => void;
};

const useStyles = makeStyles()((theme) => ({
  container: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    overflowY: 'auto',
    backgroundColor: theme.palette.background.paper,
  },
  marginTop16: {
    marginTop: theme.spacing(2),
  },
  main: {
    paddingBottom: 80,
  },
  footer: {
    width: '100%',
    position: 'fixed',
    bottom: 0,
    left: 0,
    padding: theme.spacing(2),
    paddingBottom: 'max(env(safe-area-inset-bottom), 16px)',
    backgroundColor: theme.palette.background.paper,
    zIndex: 1,
  },
  shadow: {
    boxShadow: theme.shadows[10],
  },
}));

const RegionSubscriptionEditor = (
  props: RegionSubscriptionEditorProps,
): JSX.Element => {
  const { regionSettings, onAddCounty } = props;
  const { classes, cx } = useStyles();
  const activeSettings = getActiveSettings(regionSettings);
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [, setIsMounted] = useState(false);

  useEffect(() => {
    // For component to re-render when navigating back to this page and
    // container element is not null so we know if component's scrollable.
    setIsMounted(true);
  }, []);

  const regionSettingsSections = useMemo(
    () => getSortedRegionsSections(activeSettings, null),
    [activeSettings],
  );

  const containerElement = containerRef.current;

  const isScrollable = containerElement
    ? containerElement.scrollHeight >
      containerElement.getBoundingClientRect().height
    : false;

  return (
    <Container className={classes.container} ref={containerRef}>
      <Grid container spacing={2} className={classes.main}>
        <Grid item xs={12} className={classes.marginTop16}>
          <Typography variant="h2">
            {t('notifications.notifications')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <GrayButton
            fullWidth
            size="large"
            component={Link}
            to={{ pathname: '/notifications/test' }}
          >
            {t('notifications.test')}
          </GrayButton>
        </Grid>

        {regionSettingsSections.map((regionSection) => (
          <Grid item xs={12} key={regionSection.title}>
            <RegionEditorCard
              title={regionSection.title}
              regionSettings={regionSection.regionSettings}
            />
          </Grid>
        ))}
      </Grid>

      <div className={cx(classes.footer, isScrollable && classes.shadow)}>
        <Button fullWidth size="large" onClick={onAddCounty}>
          {t('notifications.addCounty')}
        </Button>
      </div>
    </Container>
  );
};

export default RegionSubscriptionEditor;
