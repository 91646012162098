/* eslint-disable i18next/no-literal-string */
import VerifiedIcon from '@mui/icons-material/VerifiedUser';
import { makeStyles } from 'tss-react/mui';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import { Typography, Grid, Link as MuiLink, Box } from '@mui/material';
import { capitalize } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { getDateFormatted, getTimePass } from 'shared/dates';
import { GeoEvent, Report } from 'shared/types';
import { NotificationType } from '../../constants';
import useGeoEventQuery from '../../hooks/useGeoEventQuery';

type ReportMetaDataProps = {
  report: Report;
};

const useStyles = makeStyles()(() => ({
  icon: {
    height: 14,
    width: 14,
    marginLeft: 4,
  },
}));

const ReportMetaData = (props: ReportMetaDataProps): JSX.Element => {
  const { report } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();

  const { geoEvent } = useGeoEventQuery<GeoEvent>();
  const showRelativeDate = geoEvent?.isActive;

  return (
    <Grid item xs={12} container>
      <Grid item xs={12}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {/* Display name is the only proxy right now for is_bot || is_staff */}
          {report.userCreated?.displayName ? (
            <MuiLink
              to={`/profile/${report.userCreated.id}#allow-back`}
              component={Link}
              underline="none"
            >
              <Typography
                variant="body2"
                sx={{
                  fontWeight: 'bold',
                  color: 'text.primary',
                }}
              >
                {report.userCreated?.displayName}
              </Typography>
            </MuiLink>
          ) : (
            <Typography
              variant="body2"
              sx={{
                fontWeight: 'bold',
                color: 'secondary.main',
              }}
            >
              <span>{t('geoEvent.reports.anonymousUser')}</span>
            </Typography>
          )}
          {report.userCreated?.headline && (
            <Box sx={{ display: 'flex', flex: 1, overflow: 'hidden' }}>
              <Typography
                variant="body2"
                noWrap
                sx={{
                  fontWeight: 'bold',
                  color: 'secondary.main',
                }}
              >
                &nbsp;&bull;&nbsp;{report.userCreated?.headline}
              </Typography>
              {report.userCreated?.isVerified && (
                <VerifiedIcon className={classes.icon} color="primary" />
              )}
            </Box>
          )}
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="subtitle1" color="textSecondary">
            {showRelativeDate && (
              <>{capitalize(getTimePass(report.dateCreated))}&nbsp;·&nbsp;</>
            )}
            <Typography
              component="span"
              variant="subtitle1"
              color="textSecondary"
            >
              {getDateFormatted(report.dateCreated, {
                includeYear: !showRelativeDate,
              })}
            </Typography>
          </Typography>
          {report.notificationType === NotificationType.SILENT && (
            <NotificationsOffIcon className={classes.icon} color="secondary" />
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default ReportMetaData;
