import { Dispatch, SetStateAction } from 'react';
import { DeviceInfo } from '@capacitor/device';
import {
  ControllerFieldState,
  ControllerRenderProps,
  FieldPath,
  FieldValues,
  UseFormStateReturn,
} from 'react-hook-form';
import {
  EvacZoneStatuses,
  EvacZoneStyles,
  ExternalSource,
  GeoEventTypes,
  LinkTypes,
  LocationTypes,
  MEMBERSHIP_PLANS,
  NotificationSettingTypes,
} from '../constants';

// we store this
export type WDDeviceInfoStored = DeviceInfo & { uuid: string };
// and compute a few more things on the way out
export type WDDeviceInfo = WDDeviceInfoStored & {
  isMobile: boolean;
  isWeb: boolean;
  isMobilePlatform: boolean;
};

export type WDAppInfo = {
  appId: string;
  appName: string;
  appBuild: string;
  appVersion: string;
};

export type Region = {
  dateCreated: string;
  dateModified: string;
  displayName: string;
  id: number;
  isActive: boolean;
  isReporterCoveredForFire: boolean;
  name: string;
  slug: string;
  state: string;
  stateDisplayName: string;
  evacZoneStyle: RegionEvacZoneStyle | null;
};

export type NotificationSettingsKeys = keyof typeof NotificationSettingTypes;

export type NotificationOption =
  (typeof NotificationSettingTypes)[NotificationSettingsKeys];

export type NotificationRegion = {
  id: number;
  displayName: string;
  state: string;
  stateDisplayName: string;
  isReporterCoveredForFire: boolean;
};

export type NotificationSetting = {
  region: NotificationRegion;
  setting: NotificationOption;
};

export type NotificationSettingForPut = {
  region: NotificationRegion | GeoEventRegion;
  setting: NotificationOption;
};

export type NotificationSettingAPI = {
  data: NotificationSetting[];
};

export type RegionSection = {
  title: string;
  regionSettings: NotificationSetting[];
  isReporterCoveredForFire: boolean;
};

export type WildfireGeoEventData = {
  geoEventType: 'wildfire';
  isComplexParent: boolean;
  isPrescribed: boolean;
  isFps: boolean;
  containment: number | null;
  acreage: number | null;
  temporarilyDisplayEvacZones: boolean;
  evacuationOrders: string | null;
  evacuationWarnings: string | null;
  evacuationNotes: string | null;
  evacuationAdvisories: string | null;
  prescribedDateStart?: string;
  prescribedDateStartLocal?: string;
  links: Link[];
  reporterOnlyNotes: string | null;
};

export enum NotificationType {
  Normal = 'normal',
  Silent = 'silent',
}

export type GeoEventRegion = {
  id: number;
  displayName: string;
  state: string;
  name: string; // AKA "slug"
  evacZoneStyle: RegionEvacZoneStyle | null;
};

export type EvacZoneSourceType = 'zonehaven' | 'arcgis';

type EvacZoneStyleKeys = keyof typeof EvacZoneStyles;

export type RegionEvacZoneStyle = (typeof EvacZoneStyles)[EvacZoneStyleKeys];

export type EvacZone = {
  id: number;
  uidV2: string;
  displayName: string;
  region: {
    id: number;
    name: string;
    displayName: string;
    evacZoneStyle: RegionEvacZoneStyle;
  };
  sourceType: EvacZoneSourceType;
  sourceIdentifier: string;
};

type EvacZoneStatusKeys = keyof typeof EvacZoneStatuses;

export type EvacZoneStatus = (typeof EvacZoneStatuses)[EvacZoneStatusKeys];

export type GeoEventEvacZone = {
  id: number;
  uidV2: string;
  regionId: number;
  displayName: string;
  sourceType: EvacZoneSourceType;
  regionEvacZoneStyle: RegionEvacZoneStyle;
};

export type GeoEventEvacZoneStatus = {
  geoEventId: number;
  status: EvacZoneStatus;
  evacZone: GeoEventEvacZone;
  dateCreated: string;
  dateModified: string;
};

export type GeoEventType = (typeof GeoEventTypes)[keyof typeof GeoEventTypes];

export type GenericGeoEvent = {
  id: number;
  isActive: boolean;
  isVisible: boolean;
  name: string;
  description: string | null;
  lat: number;
  lng: number;
  address?: string; // geo-events generated by external systems are not guaranteed to have an address
  geoEventType: GeoEventType;
  dateCreated: string;
  dateModified: string;
  notificationType: NotificationType;
  regions: GeoEventRegion[];
  reporterManaged: boolean;
  incidentId: number | null;
  externalId: number | null;
  externalSource: string | null;
  createdByName: {
    displayNameShort: string;
    displayName: string | null;
    abbreviationName: string | null;
  };
  topics: Topic[];
  userCreatedId: number;
  evacZoneStatuses: GeoEventEvacZoneStatus[];
};

export type GeoEvent = GenericGeoEvent & {
  geoEventType: 'wildfire';
  data: WildfireGeoEventData;
  parentGeoEvents: RelatedGeoEvent[];
  childGeoEvents: (RelatedGeoEvent | ChildLocation)[];
};

export type GeoEventCreateUpdateData = {
  id?: number;
  isActive: boolean;
  name: string;
  lat: number;
  lng: number;
  address?: string;
  geoEventType: 'wildfire';
  data: WildfireGeoEventData;
  notificationType: NotificationType;
  regions: GeoEventRegion[];
  reporterManaged: boolean;
  childGeoEvents: { id: number; geoEventType: string }[];
  initialReport?: {
    message?: string;
    embedUrl?: string;
    media?: [ReportMediaForm];
  };
  evacZoneStatuses: EvacZoneStatusItem[];
};

export type GeoEventPatchData = DeepPartial<GeoEventCreateUpdateData>;

export type LocationType = (typeof LocationTypes)[keyof typeof LocationTypes];

export type LinkType = (typeof LinkTypes)[keyof typeof LinkTypes];

export type Link = {
  linkType: LinkType | null;
  label: string;
  value: string;
};

export type LocationData = {
  geoEventType: 'location';
  locationType: LocationType;
  information?: string;
  links: Link[];
};

export type Location = GenericGeoEvent & {
  address: string;
  geoEventType: 'location';
  data: LocationData;
  parentGeoEvents: RelatedGeoEvent[];
  childGeoEvents: ChildLocation[];
};

export type WildfireListItem = {
  id: number;
  isActive: boolean;
  name: string;
  description: string | null;
  lat: number;
  lng: number;
  address?: string; // geo-events generated by external systems are not guaranteed to have an address
  geoEventType: 'wildfire';
  data: WildfireGeoEventData;
  dateCreated: string;
  dateModified: string;
  notificationType: NotificationType;
  reporterManaged: boolean;
  incidentId: number | null;
  externalId: number | null;
  externalSource: ExternalSource | null;
  parentGeoEvents: RelatedGeoEvent[];
  childGeoEvents: (RelatedGeoEvent | ChildLocation)[];
};

export type BaseExternalGeoEventData = {
  externalSource: ExternalSource;
  externalId: string;
  lat: number;
  lng: number;
  name: string;
  isPrescribed: boolean;
  acreage?: number;
  containment?: number;
  address?: string;
  dateStart?: string;
};

type UnitCountsGrouped = {
  [key: string]: number;
};

export type PulsePointData = BaseExternalGeoEventData & {
  externalSource: 'pulsepoint';
  agencyId: string;
  typeCode: string;
  unitCount: number;
  unitCountsGrouped?: UnitCountsGrouped;
  isClosed?: boolean;
};

export type AlertWestAiHitData = BaseExternalGeoEventData & {
  externalSource: 'alert_west';
  hitAz: number;
  hitConfidence: number;
};

export type ExternalGeoEvent = {
  id: number;
  lat: number;
  lng: number;
  dateCreated: string;
  dateModified: string;
  externalSource: ExternalSource;
  externalId: string;
  messageId: string;
  channel: string;
  permalinkUrl: string | null;
  data: BaseExternalGeoEventData;
};

export type PulsePointExternalGeoEvent = ExternalGeoEvent & {
  externalSource: 'pulsepoint';
  data: PulsePointData;
};

export type AlertWestAiHitExternalGeoEvent = ExternalGeoEvent & {
  externalSource: 'alert_west';
  data: AlertWestAiHitData;
};

export type LocationListItem = {
  id: number;
  isActive: boolean;
  name: string;
  description: string | null;
  lat: number;
  lng: number;
  address: string;
  geoEventType: 'location';
  data: LocationData;
  dateCreated: string;
  dateModified: string;
  notificationType: NotificationType;
  reporterManaged: boolean;
  incidentId: null;
  externalId: null;
  externalSource: null;
};

export type ChildLocation = {
  id: number;
  isActive: boolean;
  name: string;
  address: string;
  geoEventType: 'location';
  lat: number;
  lng: number;
  data: LocationData;
};

export type RelatedGeoEvent = {
  id: number;
  isActive: boolean;
  address?: string;
  name: string;
  geoEventType: 'wildfire';
  lat: number;
  lng: number;
  data: WildfireGeoEventData;
};

export type FormEvacZoneStatus = {
  status: EvacZoneStatus;
  evacZone: Omit<GeoEventEvacZone, 'regionId'>;
  prevStatus?: EvacZoneStatus;
};

export type GeoEventFormStatus = 'active' | 'inactive';

export type EvacZoneStatusItem = {
  evacZone: { id: number };
  status: EvacZoneStatus;
};

export type LayerEvacZone = {
  uidV2: string;
  status?: EvacZoneStatus;
  style: RegionEvacZoneStyle;
};

// currently only used on untyped IncidentsMap.jsx - but recording for posterity
export type GeoEventWithoutRegions = Omit<GeoEvent, 'regions'>;

export type IFrameProps = {
  src: string;
};

export type ReportMedia = {
  id: number;
  url: string;
  dateCreated: string;
  dateModified: string;
  lat: number | null;
  lng: number | null;
  az: number | null;
  attribution: string | null;
  thumbnailUrl: string | null;
  sourceMediaUrl?: string;
};

export type ReportMediaForm = {
  url: string | null;
  lat: string | null;
  lng: string | null;
  az: number | null;
  attribution: string | null;
  sourceMediaUrl?: string;
};

export type UserCreated = {
  id: number;
  isVerified: boolean;
  username: string;
  displayName: string;
  headline: string;
};

export type Report = {
  id: number;
  geoEventId: number;
  incidentId: number | null;
  message: string;
  dateCreated: string;
  dateModified: string;
  isActive: boolean;
  isModerated: boolean;
  notificationType: NotificationType;
  userCreated: UserCreated | null;
  media: ReportMedia[];
  embedUrl?: string;
  lat: number | null;
  lng: number | null;
  showInReportsView: boolean;
};

export type Topic = {
  id: number;
  key: string;
  type: string;
  incident: number;
  geoEvent: number;
  name: string;
  isActive: boolean;
  dateCreated: string;
  dateModified: string;
};

export type UserGroups =
  | 'staff_reporters'
  | 'reporters'
  | 'rx_reporters'
  | 'internal_users'
  | 'member_benefits';

export type UserGroup = {
  name: UserGroups;
};

export type Organization = {
  name: string;
};

export type UserPlanType = 'regular' | 'pro';

export type User = {
  id: number;
  displayName: string;
  username: string;
  isVerified: boolean;
  groups?: UserGroup[]; // this was added in May 2022
  email: string;
  userprofile?: {
    bio: string | null;
    avatarUrl: string | null;
    headline: string | null;
  };
  organizations: Organization[]; // comes as an array - for now should always be 1
  firstName?: string;
  lastName?: string;
  supporterStatus?: {
    hasActiveMembership: boolean;
    isDonor: boolean;
    planType: UserPlanType | null;
    dateActiveUntil: string | null;
  };
};

export type AuthState = {
  user: User | null;
  isAuthenticated: boolean;
  accessKey: string | null;
  hasActiveMembership: boolean;
};

// Not entirely clear why it sometimes comes in as _ne and other times _northEast
export type SerializedBounds = {
  _ne?: {
    lat: number;
    lng: number;
  };
  _sw?: {
    lat: number;
    lng: number;
  };
  _northEast?: {
    lat: number;
    lng: number;
  };
  _southWest?: {
    lat: number;
    lng: number;
  };
};

export type MapSearchApi = 'mapbox' | 'google';

/**
 * See more at https://docs.mapbox.com/api/search/geocoding/#geocoding-response-object
 *
 * @deprecated - please use `GeocodeResult` from `useMapSearch()`, which is identical for now
 */
export type MapSearchResult = {
  id: string;
  type: 'Feature' | 'GeoEvent' | 'LatLngLocation';
  address?: string;
  text: string;
  placeName: string;
  center: number[]; // [longitude,latitude]
  bbox?: number[]; // minimum longitude, minimum latitude, maximum longitude, maximum latitude
  original?: GeoEvent;
  placeAddress?: string; // only present in google api
};

export type InAppMessage = {
  id: number;
  campaign: string;
  subCampaign: string;
  title: string;
  messageMarkdown: string;
  minAppVersion: string | null;
  topicConditions: string | null;
  ctaRoute: string | null;
  ctaText: string | null;
  dateCreated: string;
  mapPopupOnly: boolean;
};

export type InboxMessage = InAppMessage & {
  read: boolean;
};

export type UserPosition = {
  error: Error | GeolocationPositionError | null;
  getUserPosition: () => Promise<void>;
  askForPermission: boolean;
  setAskForPermission: Dispatch<SetStateAction<boolean>>;
  locationEnabled: boolean;
  lat?: number;
  lng?: number;
  az?: number;
  accuracy?: number;
};

export type PlaceIcon =
  | 'home'
  | 'store'
  | 'apartment'
  | 'work'
  | 'school'
  | 'barn'
  | 'star'
  | 'triangle'
  | 'square';

export type PlaceAddress = {
  name: string;
  formattedAddress: string;
  coordinates: { latitude: number; longitude: number };
  bbox?: number[]; // minimum longitude, minimum latitude, maximum longitude, maximum latitude
  type?: 'Feature' | 'GeoEvent' | 'LatLngLocation';
};

export type PlaceLocation = {
  id: number;
  name: string;
  address: PlaceAddress;
  icon: PlaceIcon;
};

export type MapLocation = {
  name: string;
  lat: number;
  lng: number;
  bbox?: number[];
  place?: boolean;
  formattedAddress?: string;
  type?: 'Feature' | 'GeoEvent' | 'LatLngLocation';
  geoEventId?: number;
};

export type TileSetType = {
  xmin: number;
  ymin: number;
  xmax: number;
  ymax: number;
};

export type AirCraftType =
  | 'drone'
  | 'fixedWing'
  | 'heloType1Blackhawk'
  | 'heloType1Chinook'
  | 'heloType1Skycrane'
  | 'heloType2Medium'
  | 'heloType3Light'
  | 'jumpShip'
  | 'tankerSeats'
  | 'tankerScooper'
  | 'tankerType1Lat'
  | 'tankerType1Vlat'
  | 'tankerType2'
  | 'tankerType3';

// see more at https://www.adsbexchange.com/version-2-api-wip/
export type AirCraft = {
  aircraftType: AirCraftType;
  hex: string;
  type: string;
  flight: string;
  r: string;
  t: string;
  dbFlags?: number;
  altBaro?: Altitude;
  altGeom?: number;
  gs: number;
  ias?: number;
  tas?: number;
  mach?: number;
  wd?: number;
  ws?: number;
  oat?: number;
  tat?: number;
  track?: number;
  trackRate?: number;
  roll?: number;
  magHeading?: number;
  trueHeading?: number;
  baroRate?: number;
  geomRate?: number;
  squawk?: string;
  emergency?: string;
  category?: string;
  navQnh?: number;
  navAltitudeMcp?: number;
  navHeading?: number;
  lat: number;
  lon: number;
  nic?: number;
  rc?: number;
  seenPos?: number;
  version?: number;
  nicBaro?: number;
  nacP?: number;
  nacV?: number;
  sil?: number;
  silType?: string;
  gva?: number;
  sda?: number;
  alert?: number;
  spi?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mlat?: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tisb?: any[];
  messages?: number;
  seen?: number;
  rssi?: number;
};

export type Altitude = number | 'ground';

export type AircraftDetails = {
  name: string;
  model: string;
  tailNum: string;
  type: AirCraftType;
  hex: string;
  altitude?: Altitude;
  speed?: number;
};

export type EvacZonePoi = {
  id?: number;
  uid: string;
  displayName: string;
  status?: EvacZoneStatus;
  county?: string;
  dateCreated?: string;
  dateModified?: string;
  source: EvacZoneSourceType;
  style: RegionEvacZoneStyle;
};

export type PoiType =
  | 'place'
  | 'aircraft'
  | 'rfw'
  | 'evacZone'
  | 'activeEvacZone'
  | 'mapPin'
  | 'powerOutage'
  | 'electricalLine'
  | 'gasPipeline'
  | 'externalGeoEvent'
  | 'electricRetail'
  | 'responsibilityArea'
  | 'firePerimeter'
  | 'radioTower'
  | 'measureTool';

export type Poi = {
  PoiDialogContent: () => JSX.Element;
  type: PoiType;
  id?: number | string;
  coordinates?: LatLng;
};

export type PrivateLandOwnershipEntity = {
  id: number;
  owner: string;
  address: string;
  taxAddress: string;
  lat: string;
  lng: string;
  acres: number;
  buildings: number;
  zoningType: string;
  zoningSubtype: string;
  femaFloodZone: string | undefined;
  femaFloodZoneSubtype: string | undefined;
  femaFloodZoneDate: string | undefined;
};

export type SelectedEntityType = 'privateLandOwnership';

export type MapDrawerEntity = {
  id?: number | string;
  type: SelectedEntityType;
  Content: () => JSX.Element;
};

export type ProcessMembershipPaymentTokenData = {
  email: string;
  planCode: string;
  optInToUpdates: boolean;
  authorizationToken?: string;
  firstName?: string;
  lastName?: string;
  clientToken?: string;
  verificationToken?: string;
  userId?: number;
};

export type ProcessMembershipUpgradeData = {
  verificationToken: string;
  userId: number;
  upgradePlanCode: string;
  fromIap: boolean;
};

export type ProcessInitialDonationPaymentTokenData = {
  clientToken: string;
  donationAmount: number;
  donationRenews: boolean;
  firstName: string;
  lastName: string;
  email: string;
  optInToUpdates: boolean;
  authorizationToken: string;
};

export type ProcessDonationPaymentTokenData = {
  userId: number;
  verificationToken: string;
  donationAmount: number;
  donationRenews: boolean;
  authorizationToken?: string;
};

export type ProcessPaymentState = {
  email: string;
  firstName: string;
  lastName: string;
  optInToUpdates: boolean;
  transactionAmount: number;
  clientToken?: string;
  existingUser?: boolean;
  donationRenews?: boolean;
  fromMobile?: boolean;
  verificationToken?: string;
  userId?: number;
  updatePaymentMethod?: boolean;
};

export type MembershipConfig = {
  code: string;
  title: string;
  description: string;
  upgrade?: boolean;
};

export type CardType =
  | 'Visa'
  | 'MasterCard'
  | 'Amex'
  | 'Discover'
  | 'PayPal'
  | 'ApplePay';

export type PaymentMethodData =
  | {
      cardType: CardType;
      expMonth: number;
      expYear: number;
      lastFour: string;
      paymentType: 'credit_card';
    }
  | {
      paymentType: 'apple_pay' | 'paypal_billing_agreement';
    };

export type SavePaymentMethodData = {
  userId: number;
  verificationToken: string;
  authorizationToken: string;
};

export type VerifyPaymentData = {
  key: string;
  user: User;
};

export type PaywallFeature =
  | 'flightTracker'
  | 'notifications'
  | 'places'
  | 'geoEventInfo';

export type RedFlagWarning = {
  id: number;
  startTimestamp: number;
  endTimestamp: number;
  updatedTimestamp: number;
  link: string;
};

export type FirePerimeterPoi = {
  id: number;
  source: string;
  irwinId?: string;
  dateCurrent: number;
};

export type PaginatedAPIResponse<T> = {
  count: number;
  next: string | null;
  previous: string | null;
  results: T[];
};

export type UploadImageData = {
  media: File | Blob;
  s3AuthData: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: { url: string; fields: Record<string, any> };
  };
  s3Key: string;
};

export type GPSData = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  bearing: any;
  lat: number;
  lng: number;
};

export type LatLng = {
  lat: number;
  lng: number;
};

export type ReportAsset = 'image' | 'socialEmbed' | 'alertCamera' | null;

export type QueryApiResult<T> = {
  data: T;
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
};

export type MembershipPlan =
  (typeof MEMBERSHIP_PLANS)[keyof typeof MEMBERSHIP_PLANS];

export type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>;
};

export type Membership = 'public' | UserPlanType;

export type MembershipSource = 'ios' | 'recurly';

export type FormFieldProps<TName extends FieldPath<FieldValues>> = {
  field: ControllerRenderProps<FieldValues, TName>;
  fieldState: ControllerFieldState;
  formState: UseFormStateReturn<FieldValues>;
};

export type S3AuthData = {
  url: string;
  fields: {
    key: string;
  };
};
