import { useAuthState } from '../../../../state';

export const useRiverGaugesEnabled = (): boolean => {
  const {
    permissions: { isInternalUser },
  } = useAuthState();

  if (import.meta.env.VITE_ENV === 'local') {
    return true;
  }

  return isInternalUser;
};
