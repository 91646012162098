import { ChangeEvent, MouseEvent, useState } from 'react';
import { Link, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { EvacZone, EvacZoneStatus, FormEvacZoneStatus } from 'shared/types';
import { useSnackbarState } from 'state';
import { Capacitor } from '@capacitor/core';
import GrayButton from 'components/GrayButton';
import { EvacZoneStatuses } from '../../constants';
import MultiSelectField from './MultiSelectField';

type TwoSideMultiSelectProps = {
  evacZoneStatus: EvacZoneStatus;
  availableZones: EvacZone[];
  selectedZones: FormEvacZoneStatus[];
  upgradeable?: boolean;
  downgradeable?: boolean;
  disabled?: boolean;
};

const getZoneUpdatedStatus = (
  action: 'downgrade' | 'upgrade',
  currentStatus: EvacZoneStatus,
): EvacZoneStatus => {
  if (action === 'upgrade') {
    if (currentStatus === EvacZoneStatuses.advisories) {
      return EvacZoneStatuses.warnings;
    }
    if (currentStatus === EvacZoneStatuses.warnings) {
      return EvacZoneStatuses.orders;
    }
  } else if (action === 'downgrade') {
    if (currentStatus === EvacZoneStatuses.orders) {
      return EvacZoneStatuses.warnings;
    }
    if (currentStatus === EvacZoneStatuses.warnings) {
      return EvacZoneStatuses.advisories;
    }
  }
  return currentStatus;
};

const getUpdatedUIds = (prevUIds: string[], uId: string): string[] => {
  if (prevUIds.includes(uId)) {
    return prevUIds.filter((prevUId) => prevUId !== uId);
  }
  return [...prevUIds, uId];
};

const useStyles = makeStyles()((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: 'minmax(0, 1fr) 56px minmax(0, 1fr)',
    gap: 16,
  },
  selectSection: {
    minHeight: 250,
  },
  multiSelect: {
    width: '100%',
    borderRadius: theme.shape.borderRadius * 1.34,
  },
  select: {
    width: '100%',
    height: '100% !important',
    minHeight: 215,
    padding: theme.spacing(2, 0),
    paddingRight: '0px !important',
  },
  available: {
    minHeight: 235,
  },
  option: {
    padding: '6px 12px',
  },
  multiSelectBox: {
    width: '100%',
    minHeight: 250,
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: theme.shape.borderRadius * 1.34,
    padding: theme.spacing(1, 0),
  },
  actions: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  moveButton: {
    fontSize: theme.typography.subtitle1.fontSize,
    width: 56,
    minWidth: 56,
    height: 56,
    padding: 0,
    borderRadius: theme.shape.borderRadius * 1.34,
    margin: theme.spacing(1, 0),
    fontVariant: 'all-small-caps',
    flexDirection: 'column',
    '& > span': {
      marginLeft: 0,
      marginRight: 0,
      '& svg': {
        fontSize: '24px !important',
      },
    },
  },
}));

const TwoSideMultiSelect = (props: TwoSideMultiSelectProps): JSX.Element => {
  const {
    evacZoneStatus,
    availableZones,
    selectedZones,
    upgradeable,
    downgradeable,
    disabled = false,
  } = props;
  const [zoneUIds, setZoneUIds] = useState<string[]>([]);
  const [selectedZoneUIds, setSelectedZonesUIds] = useState<string[]>([]);
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { setSnackbar } = useSnackbarState();
  const { setValue, watch } = useFormContext();

  const evacZoneStatuses = watch('evacZoneStatuses') as FormEvacZoneStatus[];

  const handleZonesChange = (
    event: ChangeEvent<HTMLSelectElement> | { zoneUIdV2: string },
  ): void => {
    if ('zoneUIdV2' in event) {
      setZoneUIds((prev) => getUpdatedUIds(prev, event.zoneUIdV2));
      return;
    }
    const { options } = event.target;
    const value: string[] = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    setZoneUIds(value);
  };

  const handleSelectedZonesChange = (
    event: ChangeEvent<HTMLSelectElement> | { zoneUIdV2: string },
  ): void => {
    if ('zoneUIdV2' in event) {
      setSelectedZonesUIds((prev) => getUpdatedUIds(prev, event.zoneUIdV2));
      return;
    }
    const { options } = event.target;
    const value: string[] = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    setSelectedZonesUIds(value);
  };

  const handleAdd = (): void => {
    const updatedZones = availableZones
      .filter((zone) => zoneUIds.includes(zone.uidV2))
      .map((zone) => ({
        evacZone: { ...zone, regionEvacZoneStyle: zone.region.evacZoneStyle },
        status: evacZoneStatus,
      }));

    setZoneUIds([]);
    setValue('evacZoneStatuses', [...evacZoneStatuses, ...updatedZones]);
  };

  const handleUpdate = (action: 'downgrade' | 'upgrade'): void => {
    if (selectedZoneUIds.length === 0) return;

    const updatedZones = selectedZones
      .filter((zone) => selectedZoneUIds.includes(zone.evacZone.uidV2))
      .map((zone) => {
        const updatedZone = {
          ...zone,
          status: getZoneUpdatedStatus(action, zone.status),
        };
        if (!zone.prevStatus) {
          updatedZone.prevStatus = zone.status;
        }
        return updatedZone;
      });

    setSelectedZonesUIds([]);
    setValue('evacZoneStatuses', [
      ...evacZoneStatuses.filter(
        (zone) => !selectedZoneUIds.includes(zone.evacZone.uidV2),
      ),
      ...updatedZones,
    ]);
  };

  const handleRemove = (): void => {
    const updatedZones = evacZoneStatuses.filter(
      (zone) => !selectedZoneUIds.includes(zone.evacZone.uidV2),
    );
    setSelectedZonesUIds([]);
    setValue('evacZoneStatuses', updatedZones);
  };

  const handleCopyToClipboard = (event: MouseEvent): void => {
    event.preventDefault();

    const text = selectedZones
      .map((zone) => zone.evacZone.displayName)
      .join('\n');
    navigator.clipboard.writeText(text);
    setSnackbar(t('geoEventEvacuations.evacZones.copySuccess'), 'success');
  };

  return (
    <div className={classes.container}>
      <div className={classes.selectSection}>
        <Typography variant="subtitle1" color="textSecondary">
          {t('geoEventEvacuations.evacZones.available')}
        </Typography>
        <MultiSelectField
          id="select-multiple-zones"
          disabled={disabled}
          value={zoneUIds}
          options={availableZones}
          onChange={handleZonesChange}
          selectClassName={classes.available}
        />
      </div>

      <div className={classes.actions}>
        {upgradeable && (
          <GrayButton
            className={classes.moveButton}
            disabled={disabled}
            startIcon={<ArrowUpwardIcon />}
            onClick={() => handleUpdate('upgrade')}
          >
            {t('geoEventEvacuations.actions.up')}
          </GrayButton>
        )}
        <GrayButton
          className={classes.moveButton}
          disabled={disabled}
          startIcon={<ArrowForwardIcon />}
          onClick={handleAdd}
        >
          {t('geoEventEvacuations.actions.add')}
        </GrayButton>
        <GrayButton
          className={classes.moveButton}
          disabled={disabled}
          startIcon={<ArrowBackIcon />}
          onClick={handleRemove}
        >
          {t('geoEventEvacuations.actions.remove')}
        </GrayButton>
        {downgradeable && (
          <GrayButton
            className={classes.moveButton}
            disabled={disabled}
            startIcon={<ArrowDownwardIcon />}
            onClick={() => handleUpdate('downgrade')}
          >
            {t('geoEventEvacuations.actions.down')}
          </GrayButton>
        )}
      </div>

      <div className={classes.selectSection}>
        <Typography variant="subtitle1" color="textSecondary">
          {t('geoEventEvacuations.evacZones.selected', {
            count: selectedZones.length,
          })}
        </Typography>
        <MultiSelectField
          id="select-multiple-selected-zones"
          disabled={disabled}
          value={selectedZoneUIds}
          options={selectedZones}
          onChange={handleSelectedZonesChange}
        />
        {!Capacitor.isNativePlatform() && (
          <Link
            component="button"
            onClick={handleCopyToClipboard}
            disabled={selectedZones.length === 0}
            sx={{
              fontSize: ({ typography }) => typography.subtitle1.fontSize,
              color: '#FF5722',
              textDecoration: 'underline',
              fontWeight: 600,
              marginLeft: 3,
            }}
          >
            {t('geoEventEvacuations.evacZones.copy')}
          </Link>
        )}
      </div>
    </div>
  );
};

export default TwoSideMultiSelect;
