import { useCallback, useEffect, useMemo, useRef } from 'react';
import {
  Box,
  Grid,
  Container,
  useMediaQuery,
  useTheme,
  Typography,
  TextField,
  MenuItem,
  FormControl,
  Button,
  ListItemIcon,
  ListItemText,
  Autocomplete,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  SubmitHandler,
  useForm,
  Controller,
  FormProvider,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useHistory } from 'react-router-dom';
import { useMap, MarkerDragEvent, MapMouseEvent } from 'shared/map-exports';
import Map from 'components/Map';
import { VisuallyBusyMapLayers } from 'components/Map/constants';
import useLocationSearchParams from 'hooks/useLocationSearchParams';
import { Location, MapLocation } from 'shared/types';
import { useCacheState, useSnackbarState } from 'state';
import { LoadingAndErrors } from 'components/LoadingAndErrors';
import { getLocationIcon } from 'components/Map/Icons';
import { getAbsoluteUrl, parsePhoneNumberString } from 'shared/utils';
import { Capacitor } from '@capacitor/core';
import RichTextEditor from 'components/RichTextEditor';
import FormLinks from 'components/FormLinks';
import { getMatchingRegions } from '../../CreateEditGeoEvent/GeoEventForm';
import {
  GeoEventTypes,
  LinkTypes,
  LocationTypes,
  PhoneNumberRegex,
  UrlRegex,
} from '../../../../constants';
import { getDefaultMapCenter, getRedundancyMessage } from './utils';
import { LocationData, FormValues, RegionOption, LocationType } from './types';
import { fetchRegions, postLocation, updateLocation } from './api';
import useStyles from './styles';
import {
  mapLocationTypeToTransKey,
  typeOptions,
  statusOptions,
} from './constants';
import { useWildfireAutocomplete } from '../../../../hooks/useWildfireAutocomplete';

type LocationFormProps = {
  location?: Location;
  initialLatitude?: number;
  initialLongitude?: number;
  parentGeoEventId?: number;
};

type LocationMarkerProps = {
  location: Partial<Location>;
  onChange: (location: { lat: number; lng: number }) => void;
};

const LocationMarker = (props: LocationMarkerProps): JSX.Element => {
  const { location, onChange } = props;
  const { current: map } = useMap();

  const handleClick = useCallback(
    (event: MapMouseEvent) => {
      const { lat, lng } = event.lngLat;
      onChange({ lat, lng });
      map?.panTo({ lat, lng });
    },
    [map, onChange],
  );

  const handleDrag = (event: MarkerDragEvent): void => {
    const { lat, lng } = event.lngLat;
    onChange({ lat, lng });
  };

  useEffect(() => {
    map?.on('click', handleClick);

    return () => {
      map?.off('click', handleClick);
    };
  }, [map, handleClick]);

  return (
    <Map.LocationMarker
      location={location}
      draggable
      onDragEnd={handleDrag}
      scale={location.data?.locationType ? 1.5 : 1}
    />
  );
};

const LocationForm = (props: LocationFormProps): JSX.Element => {
  const { location, initialLatitude, initialLongitude, parentGeoEventId } =
    props;
  const { classes } = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const isLargeMediaQuery = useMediaQuery(theme.breakpoints.up(1024), {
    defaultMatches: !Capacitor.isNativePlatform(),
  });
  const searchParams = useLocationSearchParams();
  const defaultMapCenter = getDefaultMapCenter(searchParams, location);
  const initialLocationChangeRef = useRef(false);
  const { setSnackbar } = useSnackbarState();
  const history = useHistory();
  const { setCacheState } = useCacheState();

  const { data: regionsData, isLoading: regionsLoading } = useQuery({
    queryKey: ['regions'],
    queryFn: fetchRegions,
  });

  const createLocationMutation = useMutation({ mutationFn: postLocation });

  const updateLocationMutation = useMutation({ mutationFn: updateLocation });

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string().required(
          t('createEditLocation.inputs.name.required'),
        ),
        address: Yup.string().required(
          t('createEditLocation.inputs.address.required'),
        ),
        type: Yup.string()
          .required(t('createEditLocation.inputs.type.required'))
          .nullable(),
        parentGeoEvents: Yup.array(Yup.object()),
        status: Yup.string().required(
          t('createEditLocation.inputs.status.required'),
        ),
        information: Yup.string(),
        links: Yup.array(
          Yup.object().shape({
            linkType: Yup.string()
              .required(t('formLinks.inputs.linkType.required'))
              .nullable(),
            label: Yup.string().required(
              t('formLinks.inputs.linkLabel.required'),
            ),
            value: Yup.string()
              .when('linkType', (linkType, schema) => {
                if (linkType === LinkTypes.email) {
                  return schema.email(
                    t('formLinks.inputs.linkValue.invalid.email'),
                  );
                }
                if (linkType === LinkTypes.website) {
                  return schema.matches(
                    UrlRegex,
                    t('formLinks.inputs.linkValue.invalid.url'),
                  );
                }
                if (linkType === LinkTypes.phone) {
                  return schema
                    .transform(parsePhoneNumberString)
                    .matches(
                      PhoneNumberRegex,
                      t('formLinks.inputs.linkValue.invalid.phoneNumber'),
                    );
                }
                return schema;
              })
              .required(t('formLinks.inputs.linkValue.required')),
          }),
        ),
      }),
    [t],
  );

  const initialValues: FormValues = useMemo(
    () => ({
      name: location?.name || '',
      address: location?.address || '',
      type: location?.data.locationType || LocationTypes.evacShelter,
      parentGeoEvents: location?.parentGeoEvents || [],
      // eslint-disable-next-line no-nested-ternary
      status: location ? (location.isActive ? 'active' : 'inactive') : 'active',
      information: location?.data.information || '',
      links: location?.data.links ?? [],
      lat: location?.lat || initialLatitude || 0,
      lng: location?.lng || initialLongitude || 0,
      regions: location?.regions ?? [],
    }),
    [initialLatitude, initialLongitude, location],
  );

  const formMethods = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
    mode: 'onBlur',
  });

  const { control, formState, handleSubmit, setValue, watch, reset } =
    formMethods;

  const regionOptions: RegionOption[] = useMemo(
    () => regionsData?.data || [],
    [regionsData],
  );

  useEffect(() => {
    // Initial values changed, reset the form to reflect these changes.
    reset(initialValues);
  }, [initialValues, reset]);

  const handleLocationChange = useCallback(
    async (
      locationUpdate: MapLocation | { lat: number; lng: number } | null,
    ) => {
      if (!locationUpdate) return;
      setValue('lat', locationUpdate.lat);
      setValue('lng', locationUpdate.lng);
      let address = '';
      if (
        !location &&
        'formattedAddress' in locationUpdate &&
        locationUpdate.type === 'Feature'
      ) {
        address = locationUpdate.name;
        if (locationUpdate.formattedAddress) {
          address = locationUpdate.formattedAddress.trim();
        }
        setValue('address', address);
      }
      const { placeName, matchingRegions } = await getMatchingRegions(
        locationUpdate.lat,
        locationUpdate.lng,
        regionOptions,
      );
      if (!location && !address && placeName && 'type' in locationUpdate) {
        setValue('address', placeName);
      }
      setValue(
        'regions',
        matchingRegions.map((region) => ({
          id: region.id,
          displayName: region.displayName,
          state: region.state,
          name: region.name,
          evacZoneStyle: null,
        })),
      );
    },
    [location, regionOptions, setValue],
  );

  useEffect(() => {
    if (
      initialLocationChangeRef.current ||
      !initialLatitude ||
      !initialLongitude
    ) {
      return;
    }
    handleLocationChange({ lat: initialLatitude, lng: initialLongitude });
    initialLocationChangeRef.current = true;
  }, [handleLocationChange, initialLatitude, initialLongitude]);

  const handleSubmitLocation: SubmitHandler<FormValues> = async (values) => {
    const { status, type, information, links, parentGeoEvents, ...rest } =
      values;
    if (!type) return;
    const data: LocationData = {
      ...rest,
      isActive: status === 'active',
      geoEventType: GeoEventTypes.location,
      parentGeoEvents: parentGeoEvents.map((parentGeoEvent) => ({
        id: parentGeoEvent.id,
      })),
      data: {
        geoEventType: GeoEventTypes.location,
        locationType: type,
        information,
        links: links.map((link) => {
          if (link.linkType === LinkTypes.website) {
            return { ...link, value: getAbsoluteUrl(link.value) };
          }
          return link;
        }),
      },
    };
    try {
      if (location?.id) {
        await updateLocationMutation.mutateAsync({ id: location.id, data });
        setCacheState(null);
        setSnackbar(t('createEditLocation.successMessage.update'), 'success');
        history.push(`/location/${location.id}`);
        return;
      }

      await createLocationMutation.mutateAsync({ data });
      setSnackbar(t('createEditLocation.successMessage.create'), 'success');
      history.push(parentGeoEventId ? `/i/${parentGeoEventId}` : '/');
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      const errorStatus = error?.response?.status;
      if (errorStatus === 409) {
        // We should check for redundant posts (this only affects CIOs)
        // If there are too many posts in the last few minutes, we should warn them
        // `confirm` is super ghetto but its only seen by CIO's
        // eslint-disable-next-line
        const r = confirm(getRedundancyMessage(error.response));

        if (!r) return;

        try {
          await createLocationMutation.mutateAsync({
            data,
            skipDuplicationCheck: true,
          });
          setSnackbar(t('createEditLocation.successMessage'), 'success');
          let route = '/';
          if (parentGeoEventId) route = `/i/${parentGeoEventId}`;
          else if (location) route = `/location/${location.id}`;
          history.push(route);
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (finalError: any) {
          setSnackbar(finalError.message, 'error');
        }
      } else if (errorStatus === 403) {
        setSnackbar(
          'Error 403: You cannot submit or update locations in this county.',
          'error',
        );
      } else if (errorStatus === 400) {
        setSnackbar(
          'Error 400: Cannot process submission. Double check location details and try again.',
          'error',
        );
      } else {
        setSnackbar(error.message, 'error');
      }
    }
  };

  const [type, status, lat, lng, parentGeoEvents] = watch([
    'type',
    'status',
    'lat',
    'lng',
    'parentGeoEvents',
  ]);

  const { muiAutocompleteProps: geoEventAutocompleteProps } =
    useWildfireAutocomplete({
      enabled: true,
      value: parentGeoEvents,
    });

  const currentLocation = useMemo(() => {
    return {
      data: { locationType: type },
      isActive: status === 'active',
      lat,
      lng,
    } as Partial<Location>;
  }, [lat, lng, status, type]);

  if (regionsLoading) {
    return <LoadingAndErrors isLoading />;
  }

  return (
    <Box
      className={classes.root}
      sx={{
        flexDirection: isLargeMediaQuery ? 'row' : 'column',
        overflow: isLargeMediaQuery ? 'hidden' : 'auto',
      }}
    >
      <Box
        sx={{
          flex: 1,
          minHeight: isLargeMediaQuery ? '100%' : 449,
          display: 'flex',
        }}
      >
        <Map
          center={defaultMapCenter}
          disableMapLayers={VisuallyBusyMapLayers}
          withPlaces={false}
          searchEnabled
          searchBarReturnLink="/location/create"
          cursor="crosshair"
          onLocationChange={handleLocationChange}
          searchApi="google"
        >
          <LocationMarker
            location={currentLocation}
            onChange={handleLocationChange}
          />
        </Map>
      </Box>

      <Container
        maxWidth={false}
        className={isLargeMediaQuery ? classes.formContainer : undefined}
      >
        <FormProvider {...formMethods}>
          <form
            noValidate
            className={classes.form}
            onSubmit={handleSubmit(handleSubmitLocation)}
          >
            <Grid container spacing={2} direction="column">
              <Grid item>
                <Typography variant="h3">
                  {t('createEditLocation.sections.location')}
                </Typography>
              </Grid>

              <Grid item>
                <Controller
                  name="name"
                  control={control}
                  render={({ field, fieldState }) => {
                    const { ref, ...muiFieldProps } = field;
                    return (
                      <TextField
                        id="field-control-name"
                        label={t('createEditLocation.inputs.name.label')}
                        fullWidth
                        {...muiFieldProps}
                        inputRef={ref}
                        error={!!fieldState.error}
                        helperText={
                          fieldState.error?.message ||
                          t('createEditLocation.inputs.name.helperText')
                        }
                        required
                        disabled={formState.isSubmitting}
                      />
                    );
                  }}
                />
              </Grid>

              <Grid item>
                <Controller
                  name="address"
                  control={control}
                  render={({ field, fieldState }) => {
                    const { ref, ...muiFieldProps } = field;
                    return (
                      <TextField
                        id="field-control-address"
                        label={t('createEditLocation.inputs.address.label')}
                        fullWidth
                        {...muiFieldProps}
                        inputRef={ref}
                        error={!!fieldState.error}
                        helperText={
                          fieldState.error?.message ||
                          t('createEditLocation.inputs.address.helperText')
                        }
                        required
                        disabled={formState.isSubmitting}
                      />
                    );
                  }}
                />
              </Grid>

              <Grid item>
                <Controller
                  name="type"
                  control={control}
                  render={({ field, fieldState }) => {
                    const { ref, ...muiFieldProps } = field;
                    return (
                      <TextField
                        select
                        id="field-control-type"
                        label={t('createEditLocation.inputs.type.label')}
                        fullWidth
                        {...muiFieldProps}
                        value={muiFieldProps.value || ''}
                        inputRef={ref}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        required
                        disabled={formState.isSubmitting}
                        slotProps={{
                          select: {
                            renderValue: (option) =>
                              t(
                                mapLocationTypeToTransKey[
                                  option as LocationType
                                ],
                              ),
                          },
                        }}
                      >
                        {typeOptions.map(({ transKey, value }) => {
                          const icon = getLocationIcon(value);
                          return (
                            <MenuItem
                              key={value}
                              value={value}
                              className={classes.menuItem}
                            >
                              <ListItemIcon className={classes.itemIcon}>
                                <img
                                  src={icon.data}
                                  alt={icon.name}
                                  width={icon.width}
                                  height={icon.height}
                                />
                              </ListItemIcon>
                              <ListItemText primary={t(transKey)} />
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    );
                  }}
                />
              </Grid>

              <Grid item>
                <Controller
                  name="parentGeoEvents"
                  control={control}
                  render={({ field, fieldState }) => {
                    return (
                      <FormControl fullWidth>
                        <Autocomplete
                          {...geoEventAutocompleteProps}
                          id="geoEvents"
                          value={field.value}
                          onChange={(_, value) => {
                            field.onChange(value);
                          }}
                          onBlur={field.onBlur}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name={field.name}
                              inputRef={field.ref}
                              variant="outlined"
                              label={t(
                                'createEditLocation.inputs.incidents.label',
                              )}
                              error={!!fieldState.error}
                              helperText={fieldState.error?.message}
                              disabled={formState.isSubmitting}
                            />
                          )}
                        />
                      </FormControl>
                    );
                  }}
                />
              </Grid>

              <Grid item>
                <Controller
                  name="status"
                  control={control}
                  render={({ field, fieldState }) => {
                    const { ref, ...muiFieldProps } = field;
                    return (
                      <TextField
                        select
                        id="field-control-status"
                        label={t('createEditLocation.inputs.status.label')}
                        fullWidth
                        {...muiFieldProps}
                        inputRef={ref}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        required
                        disabled={formState.isSubmitting}
                      >
                        {statusOptions.map(({ transKey, value }) => {
                          return (
                            <MenuItem
                              key={value}
                              value={value}
                              className={classes.menuItem}
                            >
                              {t(transKey)}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    );
                  }}
                />
              </Grid>

              <Grid item>
                <Box sx={{ marginTop: 2 }}>
                  <Typography variant="h3">
                    {t('createEditLocation.sections.information')}
                  </Typography>
                </Box>
              </Grid>

              <Grid item>
                <Controller
                  name="information"
                  control={control}
                  render={({ field }) => {
                    return (
                      <RichTextEditor
                        id="field-control-information"
                        key={`${location?.id}-${location?.dateModified}`}
                        initialValue={initialValues.information ?? ''}
                        onChange={field.onChange}
                      />
                    );
                  }}
                />
              </Grid>

              <Grid item>
                <Box sx={{ marginTop: 2 }}>
                  <Typography variant="h3">
                    {t('createEditLocation.sections.links')}
                  </Typography>
                </Box>
              </Grid>

              <Grid item>
                <FormLinks />
              </Grid>

              <Grid item>
                <Box sx={{ marginTop: 2 }}>
                  <Button fullWidth size="large" type="submit">
                    {t(
                      `createEditLocation.buttons.${
                        location ? 'edit' : 'create'
                      }`,
                    )}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </Container>
    </Box>
  );
};

export default LocationForm;
