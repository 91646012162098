import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Button, Stack, Tooltip, Typography } from '@mui/material';
import { useRiverGaugeWithObservations } from 'features/riverGauges/hooks/useRiverGauge';
import { LoadingAndErrors } from 'components/LoadingAndErrors';
import { MapEntityDrawerHeader } from 'components/Map/MapEntityDrawerHeader';
import { StatItem, StatTable } from 'components/StatTable';
import { subDays } from 'date-fns';
import { getTimePass } from 'shared/dates';
import { Capacitor } from '@capacitor/core';
import { useShareOrCopy } from 'hooks/useShareOrCopy';
import { getCurrentLocationAsShareUrl } from 'shared/utils';
import { getFloodCategoryFromLevel } from './RiverGaugeDetails.utils';

const OBSERVATIONS_POLL_INTERVAL = 5 * 60 * 1000;

export const RiverGaugeDetails = (): JSX.Element => {
  const { gaugeId } = useParams<{ gaugeId: string }>();
  const { t } = useTranslation();
  const { canShareOrCopy, shareOrCopyUrl } = useShareOrCopy();
  const observationsHistoryStart = subDays(new Date(), 7);

  const { riverGauge, observations, isLoading, error } =
    useRiverGaugeWithObservations({
      id: gaugeId,
      start: observationsHistoryStart,
      end: new Date(),
      pollInterval: OBSERVATIONS_POLL_INTERVAL,
    });

  if (isLoading || error || !riverGauge || !observations) {
    return <LoadingAndErrors isLoading={isLoading} />;
  }

  const {
    level: latestLevel = undefined,
    flow: latestFlow = undefined,
    timestamp: lastUpdated = undefined,
  } = observations.length ? observations[0] : {};

  const handleShare = (): void => {
    shareOrCopyUrl({
      title: t('home.riverGaugeTitle', { name: riverGauge.name }),
      url: getCurrentLocationAsShareUrl(lastUpdated),
    });
  };

  const latestCategory = latestLevel
    ? getFloodCategoryFromLevel(latestLevel, riverGauge.floodCategories)
    : null;

  const stats: StatItem[] = [
    {
      label: t('riverGauges.height'),
      value: latestLevel ? `${latestLevel} ${riverGauge.units.level}` : null,
    },
    {
      label: t('riverGauges.flow'),
      value: latestFlow ? `${latestFlow} ${riverGauge.units.flow}` : null,
    },
    {
      label: t('riverGauges.floodLevel'),
      valueTypographyProps: { textTransform: 'capitalize' },
      value: latestCategory || null,
    },
  ];

  const shareTooltipText = Capacitor.isNativePlatform()
    ? t('common.share')
    : t('common.copyLinkToClipboard');

  return (
    <>
      <Helmet>
        <title>{t('home.riverGaugeTitle', { name: riverGauge.name })}</title>
      </Helmet>

      <Stack gap={2} margin={2} marginTop={0}>
        <MapEntityDrawerHeader>{riverGauge.name}</MapEntityDrawerHeader>

        <StatTable stats={stats} />

        {lastUpdated && (
          <Typography>
            {t('common.updated')}&nbsp;
            <b>{getTimePass(lastUpdated.toISOString())}</b>
          </Typography>
        )}

        {canShareOrCopy && (
          <Tooltip title={shareTooltipText}>
            <Button
              fullWidth
              color="inherit"
              variant="outlined"
              onClick={handleShare}
              size="large"
            >
              {t('common.share')}
            </Button>
          </Tooltip>
        )}
      </Stack>
    </>
  );
};
