import { useRouteMatch } from 'react-router-dom';
import { ROUTER_ENTITY_TO_ROUTE_PATH_MAP } from '../constants';

type CameraParams = {
  cameraId?: string;
};

export const useSelectedCameraId = (): string | undefined => {
  // Using `useRouteMatch()` instead of `useParams()` since we're not always inside the GeoEvent <Router>
  const match = useRouteMatch<CameraParams>(
    ROUTER_ENTITY_TO_ROUTE_PATH_MAP.camera,
  );
  const cameraIdStr = match?.params.cameraId;

  return cameraIdStr;
};
