import { useMutation, useQuery } from '@tanstack/react-query';
import axios, { CancelTokenSource } from 'axios';
import { useEffect, useRef, useState } from 'react';
import { API } from 'api';
import { S3AuthData, UploadImageData } from 'shared/types';
import { uploadImage, formatFileUploadKey } from 'shared/utils';

export type ImageUploadData = {
  file: File | Blob;
  fileType: string;
};

export type UseImageUploadProps = {
  imgData: ImageUploadData | null;
  /** Memoized callback to handle errors from the query/mutation */
  onError?: (error: Error) => void;
  enabled?: boolean;
};

type UseImageUploadReturn = {
  error: boolean;
  uploadImgPromise?: Promise<void | Error>;
  mediaUrl?: string;
};

export const useImageUpload = (
  props: UseImageUploadProps,
): UseImageUploadReturn => {
  const { imgData, onError, enabled = true } = props;
  const cancelTokenSourceRef = useRef<CancelTokenSource | null>(null);
  const [uploadImgResult, setUploadImgResult] = useState<
    { promise: Promise<void | Error>; mediaUrl?: string } | undefined
  >();

  const { isLoading: isS3AuthLoading, data: s3AuthData } = useQuery({
    queryKey: ['gets3Auth'],
    queryFn: () => API.get<S3AuthData>('media/storage_auth/'),
    enabled,
  });

  const { mutateAsync, isError, reset } = useMutation({
    mutationFn: async (data: UploadImageData) => {
      if (cancelTokenSourceRef.current) {
        cancelTokenSourceRef.current.cancel(
          'Operation cancelled due to new request',
        );
        cancelTokenSourceRef.current = null;
      }

      const cancelTokenSource = axios.CancelToken.source();
      cancelTokenSourceRef.current = cancelTokenSource;

      await uploadImage(data, cancelTokenSource);
    },
    onError: (err) => {
      if (!axios.isCancel(err) && onError) {
        onError(err);
      }
    },
  });

  useEffect(() => {
    if (isS3AuthLoading || !enabled) {
      return () => {};
    }

    if (!imgData) {
      if (cancelTokenSourceRef.current) {
        cancelTokenSourceRef.current.cancel(
          'Operation cancelled due to data update to null',
        );
        cancelTokenSourceRef.current = null;
      }
      reset();
      setUploadImgResult(undefined);
      return () => {};
    }

    if (!s3AuthData) {
      const s3AuthError = new Error('No S3 auth data');
      setUploadImgResult({ promise: Promise.resolve(s3AuthError) });
      if (onError) onError(s3AuthError);
    } else {
      const mediaUrl = formatFileUploadKey({
        fileType: imgData.fileType,
        s3AuthKey: s3AuthData.data.fields.key,
      });

      const promise = mutateAsync({
        media: imgData.file,
        s3AuthData,
        s3Key: mediaUrl,
      });

      const wrappedPromise = new Promise<void>((resolve, reject) => {
        promise.then(resolve).catch((err) => {
          resolve(err); // Resolve with error so we have no unhandled promise rejections
        });
      });

      setUploadImgResult({ promise: wrappedPromise, mediaUrl });
    }

    return () => {
      setUploadImgResult(undefined);
    };
  }, [
    enabled,
    imgData,
    isS3AuthLoading,
    mutateAsync,
    onError,
    reset,
    s3AuthData,
  ]);

  useEffect(() => {
    return () => {
      if (cancelTokenSourceRef.current) {
        cancelTokenSourceRef.current.cancel('Component unmounted');
        cancelTokenSourceRef.current = null;
      }
    };
  }, []);

  return {
    error: isError,
    uploadImgPromise: uploadImgResult?.promise,
    mediaUrl: uploadImgResult?.mediaUrl,
  };
};
