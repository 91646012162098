/* eslint-disable react/destructuring-assignment */
import { Typography, Box, Stack } from '@mui/material';
import { Trans } from 'react-i18next';
import { MembershipPlan } from 'shared/types';
import {
  MEMBERSHIP_PLANS,
  PRO_MEMBERSHIP_PLAN_AMOUNT_2025,
} from '../../../../constants';

type CardContentProps =
  | {
      description: string;
    }
  | {
      plan: MembershipPlan;
      description: string;
      amount: number;
    };

export const CardContent = (props: CardContentProps): JSX.Element => {
  const { description } = props;

  return (
    <>
      <Typography
        sx={{
          fontSize: 16,
        }}
      >
        {description}
      </Typography>
      {'amount' in props && (
        <Box sx={{ marginTop: '16px !important' }}>
          {props.plan !== MEMBERSHIP_PLANS.yearly && (
            <Typography
              color="textSecondary"
              sx={{
                fontSize: 16,
              }}
            >
              <Trans
                i18nKey="supportUs.proMembership.plans.yearly.regularPrice"
                values={{
                  price: PRO_MEMBERSHIP_PLAN_AMOUNT_2025,
                }}
              >
                <Typography
                  variant="subtitle1"
                  component="span"
                  sx={{
                    fontWeight: 500,
                    fontSize: 12,
                    verticalAlign: 'top',
                    textDecoration: 'line-through',
                  }}
                >
                  $
                </Typography>
                <Typography
                  component="span"
                  sx={{
                    fontWeight: 500,
                    fontSize: 16,
                    textDecoration: 'line-through',
                  }}
                >
                  99.99
                </Typography>
              </Trans>
            </Typography>
          )}

          <Stack direction="row">
            <Typography
              sx={{
                fontSize: 16,
              }}
            >
              <Trans
                i18nKey={
                  props.plan === MEMBERSHIP_PLANS.yearly
                    ? 'supportUs.membership.price'
                    : 'supportUs.proMembership.price'
                }
                values={{
                  price: props.amount,
                }}
              >
                <Typography
                  variant="h3"
                  component="span"
                  sx={{
                    fontWeight: 500,
                    fontSize: 20,
                    verticalAlign: 'top',
                  }}
                >
                  $
                </Typography>
                <Typography
                  variant="h1"
                  component="span"
                  sx={{
                    fontSize: 36,
                  }}
                >
                  24.99
                </Typography>
                per year
              </Trans>
            </Typography>
          </Stack>
        </Box>
      )}
    </>
  );
};
