import { red, orange, grey } from '@mui/material/colors';
import { Components, Interpolation, Theme, createTheme } from '@mui/material';
import { getResponsiveFontSize } from 'shared/utils';
import typography from './typography';

const CHIP_COMMON_STYLES: Interpolation<Theme> = {
  borderRadius: 4,
  fontWeight: typography.fontWeightBold,
  fontSize: typography.subtitle1!.fontSize,
  fontVariant: 'small-caps',
  textTransform: 'lowercase',
  marginBottom: 4,
  lineHeight: 1,
};

const BORDER_RADIUS = 6;

const components: Components<Omit<Theme, 'components'>> = {
  MuiFormControl: {
    defaultProps: {
      variant: 'outlined',
    },
  },
  MuiCheckbox: {
    defaultProps: {
      color: 'primary',
    },
  },
  MuiDialog: {
    defaultProps: {
      // Without disableEnforceFocus, having the modal open and then clicking
      // any of the Bottomsheet drawers from react-spring-bottom-sheet will cause
      // an infinite recursion loop: https://stackoverflow.com/questions/54133326/material-ui-uncaught-rangeerror-maximum-call-stack-size-exceeded/54133377#54133377
      // We should find another way to address this, as disableEnforceFocus is
      // important for keyboard accessibility. Perhaps we can remove this if/when
      // we migrate off react-spring-bottom-sheet: (https://trello.com/c/eUp6DMKx/)
      disableEnforceFocus: true,
    },
  },
  MuiSwitch: {
    defaultProps: {
      color: 'primary',
    },
  },
  MuiButton: {
    defaultProps: {
      variant: 'contained',
      color: 'primary',
      disableElevation: true,
    },
    styleOverrides: {
      root: ({ ownerState }) => ({
        ...(ownerState?.size === 'large' && {
          borderRadius: BORDER_RADIUS * 2,
          height: 48,
          fontSize: getResponsiveFontSize('1rem'),
        }),
        ...(ownerState?.variant === 'watchduty-text-uppercase' && {
          textTransform: 'uppercase',
          fontWeight: typography.fontWeightBold,
        }),
      }),
    },
  },
  MuiToggleButton: {
    styleOverrides: {
      root: {
        backgroundColor: `${grey[300]} !important`,
        borderColor: `${grey[300]} !important`,
        fontWeight: typography.fontWeightRegular,
        color: grey[900],
        '&.MuiToggleButton-root.Mui-selected': {
          backgroundColor: '#FBC02D !important',
          borderColor: '#FBC02D !important',
          color: grey[900],
          fontWeight: typography.fontWeightBold,
        },
      },
    },
  },
  MuiToggleButtonGroup: {
    styleOverrides: {
      root: {
        variants: [
          {
            props: { variant: 'watchduty-gap' },
            style: {
              gap: 16,
              '& .MuiToggleButton-root': {
                borderRadius: '8px !important',
              },
            },
          },
        ],
      },
    },
  },
  MuiTextField: {
    defaultProps: {
      variant: 'outlined',
    },
    styleOverrides: {
      root: {
        variants: [
          {
            props: { variant: 'filled' },
            style: {
              // MUI doesn't allow custom variants for TextFields, so we use the
              // className as a variant: https://medium.com/@victorgonz96/create-material-ui-textfield-variants-2616b673cce0
              '&&&.watchduty-filled-nolabel': {
                '.MuiInputBase-root': {
                  borderRadius: 8,
                },
                input: {
                  paddingTop: '4px',
                },
                '.MuiInputAdornment-root': {
                  marginTop: '0',
                },
                '& :before, & :after, &:hover :before, &:hover :after': {
                  borderBottom: 'none',
                },
              },
            },
          },
        ],
      },
    },
  },
  MuiSelect: {
    defaultProps: {
      variant: 'outlined',
    },
  },
  MuiAlert: {
    defaultProps: {
      style: {
        borderRadius: 12,
      },
    },
  },
  MuiAvatar: {
    styleOverrides: {
      colorDefault: { backgroundColor: grey[100] },
    },
  },
  MuiSvgIcon: {
    styleOverrides: {
      root: {
        fontSize: getResponsiveFontSize('1.5rem'),
      },
      fontSizeSmall: { fontSize: getResponsiveFontSize('1.25rem') },
      fontSizeLarge: { fontSize: getResponsiveFontSize('2.1875rem') },
      fontSizeInherit: { fontSize: getResponsiveFontSize('1rem') },
    },
  },
  MuiChip: {
    styleOverrides: {
      root: {
        variants: [
          {
            props: { variant: 'watchduty' },
            style: {
              ...CHIP_COMMON_STYLES,
              height: 16,
              '& span': {
                height: 16,
                padding: '2px 6px 2px',
              },
            },
          },
          {
            props: { variant: 'watchduty-multiline' },
            style: {
              ...CHIP_COMMON_STYLES,
              height: 'auto',
              '& span': {
                height: 'fit-content',
                padding: '2px 6px 2px',
                display: 'block',
                whiteSpace: 'normal',
              },
            },
          },
        ],
      },
    },
  },
};

// We extend: https://material-ui.com/customization/default-theme/
const watchdutyTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      laptop: 1024,
      tablet: 768,
      phone: 480,
    },
  },
  palette: {
    primary: {
      main: '#FBC02D',
    },
    secondary: {
      main: grey[500],
    },
    error: red,
    warning: {
      light: orange[500],
      main: orange[700],
      dark: red[700],
    },
    // lowEmphasis: 'rgba(229, 229, 229, 1)',
    // mediumEmphasis: 'rgba(153, 153, 153, 1)',
    // highEmphasis: 'rgba(31, 31, 31, 1)',
    accent: {
      main: '#6393F2',
    },
    background: {
      membership: '#FAF7F2',
    },
    watchdutyComplex: {
      main: '#F19437',
      contrastText: '#FFF',
    },
  },
  components,
  shape: {
    borderRadius: BORDER_RADIUS,
  },
  typography,
});

export default watchdutyTheme;
