import {
  Button,
  List,
  ListItem,
  Stack,
  Typography,
  ListItemText,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import { MembershipPlan } from 'shared/types';
import { formatToUSDollars } from 'shared/utils';
import {
  MEMBERSHIP_PLANS,
  MEMBERSHIP_PLANS_AMOUNTS,
  PRO_MEMBERSHIP_PLAN_AMOUNT_2025,
} from '../../../../constants';
import { useMembershipPlan } from '../MembershipPlanProvider';
import { FeaturesList } from './FeatureList';

type ExpandedContentProps = {
  plan?: MembershipPlan;
  upgrade?: boolean;
};

type PlanButtonProps = {
  plan: MembershipPlan;
};

const useStyles = makeStyles()((theme) => ({
  membershipButton: {
    flex: 1,
    borderRadius: 12,
    padding: theme.spacing(1.5),
    justifyContent: 'flex-start',
    borderWidth: 4,
    borderColor: theme.palette.grey[500],
  },
  selectedMembershipButton: {
    borderColor: theme.palette.primary.main,
  },
  list: {
    '& li': {
      padding: 0,
    },
    '& li::before': {
      content: '"•"',
      display: 'inline-block',
      width: theme.spacing(2),
    },
  },
}));

const PlanButton = (props: PlanButtonProps): JSX.Element => {
  const { plan } = props;
  const { classes, cx } = useStyles();
  const { t } = useTranslation();
  const { selectedPlan, updatePlan } = useMembershipPlan();

  return (
    <Button
      variant="outlined"
      color="inherit"
      className={cx(
        classes.membershipButton,
        selectedPlan === plan && classes.selectedMembershipButton,
      )}
      onClick={() => updatePlan(plan)}
    >
      <Stack
        spacing={1}
        sx={{
          height: 1,
          width: 1,
        }}
      >
        <Stack
          spacing={1}
          sx={{
            flex: 1,
          }}
        >
          <Typography
            variant="h3"
            component="p"
            sx={{
              fontWeight: 500,
              fontSize: 20,
              fontVariant: 'all-small-caps',
              lineHeight: 0.75,
            }}
          >
            {t(
              plan === MEMBERSHIP_PLANS.proYearly
                ? 'supportUs.proMembership.plans.yearly.title'
                : 'supportUs.proMembership.plans.monthly.title',
            )}
          </Typography>

          {plan === MEMBERSHIP_PLANS.proYearly && (
            <Typography
              color="textSecondary"
              sx={{
                fontSize: 16,
              }}
            >
              <Trans
                i18nKey="supportUs.proMembership.plans.yearly.regularPrice"
                values={{
                  price: PRO_MEMBERSHIP_PLAN_AMOUNT_2025,
                }}
              >
                <Typography
                  variant="subtitle1"
                  component="span"
                  sx={{
                    fontWeight: 500,
                    fontSize: 12,
                    verticalAlign: 'top',
                    textDecoration: 'line-through',
                  }}
                >
                  $
                </Typography>
                <Typography
                  component="span"
                  sx={{
                    fontWeight: 500,
                    fontSize: 16,
                    textDecoration: 'line-through',
                  }}
                >
                  99.99
                </Typography>
              </Trans>
            </Typography>
          )}
        </Stack>

        <Typography
          variant="h1"
          component="p"
          sx={{
            fontSize: 36,
          }}
        >
          <Trans
            i18nKey={
              plan === MEMBERSHIP_PLANS.proYearly
                ? 'supportUs.proMembership.plans.yearly.price'
                : 'supportUs.proMembership.plans.monthly.price'
            }
            values={{
              price: MEMBERSHIP_PLANS_AMOUNTS[plan],
            }}
          >
            <Typography
              variant="h3"
              component="span"
              sx={{
                fontWeight: 500,
                fontSize: 20,
                verticalAlign: 'top',
              }}
            >
              $
            </Typography>
            79.99
            <Typography
              variant="h3"
              component="span"
              sx={{
                fontWeight: 500,
                fontSize: 20,
                verticalAlign: 'top',
              }}
            >
              *
            </Typography>
          </Trans>
        </Typography>

        <Stack
          sx={{
            alignItems: 'center',
          }}
        >
          <List dense disablePadding className={classes.list}>
            <ListItem disableGutters>
              <ListItemText
                primary={
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: 14,
                    }}
                  >
                    {t(
                      plan === MEMBERSHIP_PLANS.proYearly
                        ? 'supportUs.proMembership.plans.billedAnnually'
                        : 'supportUs.proMembership.plans.billedMonthly',
                    )}
                  </Typography>
                }
                sx={{ margin: 0 }}
              />
            </ListItem>
            <ListItem disableGutters>
              <ListItemText
                primary={
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: 14,
                    }}
                  >
                    {t('supportUs.proMembership.plans.cancelAnytime')}
                  </Typography>
                }
                sx={{ margin: 0 }}
              />
            </ListItem>
          </List>
        </Stack>
      </Stack>
    </Button>
  );
};

export const ExpandedContent = (props: ExpandedContentProps): JSX.Element => {
  const { plan, upgrade = false } = props;
  const { t } = useTranslation();
  const {
    selectedPlan,
    continueOnWeb,
    verificationError,
    getNextLink,
    handleNext,
  } = useMembershipPlan();

  if (
    plan === MEMBERSHIP_PLANS.proYearly ||
    plan === MEMBERSHIP_PLANS.proMonthly
  ) {
    const membershipProLink = getNextLink(selectedPlan);

    return (
      <>
        <FeaturesList pro />

        <Stack direction="row" spacing={1.5}>
          <PlanButton plan={MEMBERSHIP_PLANS.proYearly} />
          <PlanButton plan={MEMBERSHIP_PLANS.proMonthly} />
        </Stack>
        <Typography
          variant="subtitle1"
          sx={{
            fontSize: 12,
          }}
        >
          {t('supportUs.proMembership.footer', {
            price: formatToUSDollars(PRO_MEMBERSHIP_PLAN_AMOUNT_2025),
          })}
        </Typography>

        <Button
          fullWidth
          color="primary"
          size="large"
          component={Link}
          to={
            continueOnWeb ? { pathname: membershipProLink } : membershipProLink
          }
          target={continueOnWeb ? '_blank' : undefined}
          disabled={!!verificationError}
          onClick={() => handleNext()}
        >
          {upgrade
            ? t('supportUs.manageMembership.upgrade')
            : t('supportUs.proMembership.button')}
        </Button>
      </>
    );
  }

  if (plan === MEMBERSHIP_PLANS.yearly) {
    const membershipLink = getNextLink(MEMBERSHIP_PLANS.yearly);
    return (
      <>
        <FeaturesList />

        <Button
          fullWidth
          color="primary"
          size="large"
          component={Link}
          to={continueOnWeb ? { pathname: membershipLink } : membershipLink}
          target={continueOnWeb ? '_blank' : undefined}
          disabled={!!verificationError}
          onClick={() => handleNext()}
        >
          {t('supportUs.membership.button')}
        </Button>
      </>
    );
  }

  return (
    <>
      <Typography
        sx={{
          fontWeight: 'bold',
          fontSize: 16,
          marginTop: '4px !important',
        }}
      >
        {t('supportUs.donate.info')}
      </Typography>

      <Button
        fullWidth
        color="primary"
        size="large"
        component={Link}
        to="/donate"
      >
        {t('supportUs.donate.button')}
      </Button>
    </>
  );
};
