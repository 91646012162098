import {
  Box,
  List,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Link as ReactRouterLink } from 'react-router-dom';
import { useAuthState } from 'state';
import { GeoEvent, Link } from 'shared/types';
import LinkItem from 'components/LinkItem';
import { parseCoordinate } from 'shared/utils';
import UserStatusChip from 'components/Map/UserStatusChip';
import { useShareOrCopy } from 'hooks/useShareOrCopy';
import { MEMBERSHIP_PLANS } from '../../../../constants';

type GeoEventStructuredInfoProps = {
  geoEvent: GeoEvent;
};

type LocationCoordsProps = {
  coordinates: string;
};

type LinksListProps = {
  links: Link[];
};

const useStyles = makeStyles()((theme) => ({
  listSubheader: {
    padding: theme.spacing(3, 2, 2),
    lineHeight: 1,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  blurredLocationImg: {
    height: 20,
    marginLeft: -4,
  },
}));

const LocationCoords = (props: LocationCoordsProps): JSX.Element => {
  const { coordinates } = props;
  const { classes } = useStyles();
  const { showMembershipFeatures } = useAuthState();

  if (!showMembershipFeatures) {
    return (
      <img
        src="/images/location-coords-blur.png"
        alt="blurred location"
        className={classes.blurredLocationImg}
      />
    );
  }

  return <>{coordinates}</>;
};

const LinksList = (props: LinksListProps): JSX.Element => {
  const { links } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <List
      subheader={
        <ListSubheader
          color="inherit"
          className={classes.listSubheader}
          disableSticky
        >
          <Typography variant="h3">
            {t('geoEvent.structuredInfo.links')}
          </Typography>
        </ListSubheader>
      }
    >
      {links.map((link) => (
        <LinkItem key={`${link.label}-${link.value}`} link={link} />
      ))}
    </List>
  );
};

const GeoEventStructuredInfo = (
  props: GeoEventStructuredInfoProps,
): JSX.Element => {
  const { geoEvent } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { showMembershipFeatures } = useAuthState();
  const { shareOrCopyText } = useShareOrCopy();

  const initialLocationCoords = `${parseCoordinate(
    geoEvent.lat,
  )}, ${parseCoordinate(geoEvent.lng)}`;

  const links = geoEvent.data?.links ?? [];

  const handleCopyCoordinates = (): void => {
    shareOrCopyText({
      title: geoEvent.name,
      text: initialLocationCoords,
      androidDialogTitle: t('geoEvent.structuredInfo.share'),
    });
  };

  const coordinatesButtonProps = showMembershipFeatures
    ? {
        onClick: handleCopyCoordinates,
      }
    : {
        component: ReactRouterLink,
        to: `/support_us/${MEMBERSHIP_PLANS.yearly}`,
      };

  return (
    <List
      subheader={
        <ListSubheader
          color="inherit"
          className={classes.listSubheader}
          disableSticky
        >
          <Typography variant="h3">
            {t('geoEvent.structuredInfo.information')}
          </Typography>
        </ListSubheader>
      }
    >
      <ListItemButton divider {...coordinatesButtonProps}>
        <ListItemText
          primary={
            <Box
              sx={{ display: 'flex', alignItems: 'center' }}
              component="span"
            >
              <Typography
                color="secondary"
                component="span"
                sx={{
                  fontWeight: 'medium',
                  fontVariant: 'all-small-caps',
                }}
              >
                {t('geoEvent.structuredInfo.initialLocation')}
              </Typography>
              <UserStatusChip type="members" sx={{ marginLeft: 1 }} />
            </Box>
          }
          secondary={<LocationCoords coordinates={initialLocationCoords} />}
          secondaryTypographyProps={{
            variant: 'body1',
            color: 'textPrimary',
            fontWeight: 'medium',
          }}
        />
        <LocationOnIcon sx={{ color: 'text.primary' }} />
      </ListItemButton>

      {links.length > 0 && <LinksList links={links} />}
    </List>
  );
};

export default GeoEventStructuredInfo;
