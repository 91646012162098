import { useEffect, useRef } from 'react';
import { Capacitor } from '@capacitor/core';
import { Grid, Typography, Button, Container, Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'shared/utils';
import { getDateFormattedInboxMessage } from 'shared/dates';
import useAppInfo from 'hooks/useAppInfo';
import { InboxMessage } from 'shared/types';
import GrayButton from 'components/GrayButton';
import { ANALYTICS_EVENTS, logEvent } from 'shared/analytics';
import { AppStoreLinks } from '../../constants';

type InboxMessageContentProps = {
  message: InboxMessage;
  onClose?: () => void;
};

type ActionButtonProps = {
  text: string;
  route: string;
  requiresUpgrade: boolean;
  popup: boolean;
  onClose?: () => void;
};

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
    maxHeight: '100%',
    overflow: 'auto',
    position: 'relative',
    overscrollBehavior: 'none',
  },
  container: {
    width: '100%',
    paddingTop: 24,
    paddingBottom: 24,
    maxWidth: 1000,
  },
  contentContainer: {
    paddingTop: '0px !important',
    paddingBottom: '0px !important',
  },
  content: {
    fontSize: theme.typography.body1.fontSize,
    '& a': {
      color: 'rgba(255, 87, 34, 1)',
    },
    '& p': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    maxWidth: '100%',
  },
  buttonMaxWidth: {
    [theme.breakpoints.up('sm')]: {
      maxWidth: 340,
    },
  },
  stickyActions: {
    position: 'sticky',
    left: 0,
    bottom: 0,
    paddingTop: 24,
    paddingBottom: 24,
    backgroundColor: theme.palette.background.paper,
  },
  shadow: {
    boxShadow: theme.shadows[10],
  },
}));

const ActionButton = (props: ActionButtonProps): JSX.Element => {
  const { text, route, requiresUpgrade, popup, onClose } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();

  const absRoute = /http/.test(route) || requiresUpgrade;

  const title = requiresUpgrade ? t('messageDetail.button') : text;

  const getLink = (): string => {
    if (!requiresUpgrade) return route;

    if (Capacitor.getPlatform() === 'ios') {
      return AppStoreLinks.ios;
    }

    return AppStoreLinks.android;
  };

  if (absRoute) {
    return (
      <Button
        fullWidth
        className={(!popup && classes.buttonMaxWidth) || undefined}
        size="large"
        to={{ pathname: getLink() }}
        target="_blank"
        component={Link}
        onClick={onClose}
      >
        {title}
      </Button>
    );
  }

  return (
    <Button
      fullWidth
      className={(!popup && classes.buttonMaxWidth) || undefined}
      size="large"
      to={route}
      component={Link}
      onClick={onClose}
    >
      {text}
    </Button>
  );
};

const InboxMessageContent = (props: InboxMessageContentProps): JSX.Element => {
  const { message, onClose } = props;
  const { classes, cx } = useStyles();
  const { appVersion } = useAppInfo();
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    logEvent({
      name: ANALYTICS_EVENTS.VIEWED.INBOX_MESSAGE,
      params: { campaign: message.campaign, subcampaign: message.subCampaign },
    });
    logEvent({
      name: `${ANALYTICS_EVENTS.VIEWED.INBOX_MESSAGE}_${message.campaign}`,
      params: {},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const containerElement = containerRef.current;

  const isScrollable = containerElement
    ? containerElement.scrollHeight >
      containerElement.getBoundingClientRect().height
    : false;

  const requiresUpgrade =
    isMobile() && !!message.minAppVersion && appVersion < message.minAppVersion;

  const renderActions = (): JSX.Element => {
    return (
      <>
        {!!message.ctaText && !!message.ctaRoute && (
          <>
            {requiresUpgrade && (
              <Grid item xs={12}>
                <Typography>
                  <b>{t('messageDetail.requiresUpdate')}</b>
                </Typography>
              </Grid>
            )}

            <Grid item xs={12}>
              <ActionButton
                text={message.ctaText}
                route={message.ctaRoute}
                requiresUpgrade={requiresUpgrade}
                popup={message.mapPopupOnly}
                onClose={onClose}
              />
            </Grid>
          </>
        )}

        {message.mapPopupOnly && onClose && (
          <Grid item xs={12}>
            <GrayButton fullWidth size="large" onClick={onClose}>
              {t('common.close')}
            </GrayButton>
          </Grid>
        )}
      </>
    );
  };

  return (
    <div className={classes.root} ref={containerRef}>
      <Box className={classes.container}>
        <Container maxWidth={false}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h2">{message.title}</Typography>
              {!message.mapPopupOnly && (
                <Typography color="textSecondary">
                  {getDateFormattedInboxMessage(message.dateCreated)}
                </Typography>
              )}
            </Grid>

            <Grid item xs={12} className={classes.contentContainer}>
              <ReactMarkdown
                className={classes.content}
                components={{
                  img: ({ node, ...restProps }) => (
                    // eslint-disable-next-line jsx-a11y/alt-text
                    <img
                      {...restProps}
                      style={{ maxWidth: '100%', margin: '16px 0px' }}
                    />
                  ),
                }}
              >
                {message.messageMarkdown}
              </ReactMarkdown>
            </Grid>

            {!message.mapPopupOnly && renderActions()}
          </Grid>
        </Container>
      </Box>
      {message.mapPopupOnly && (
        <div
          className={cx(classes.stickyActions, isScrollable && classes.shadow)}
        >
          <Container maxWidth={false}>
            <Grid container spacing={2}>
              {renderActions()}
            </Grid>
          </Container>
        </div>
      )}
    </div>
  );
};

export default InboxMessageContent;
