import { MouseEvent, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Container,
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
  TextField,
  Button,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Alert,
  Link as MuiLink,
  CircularProgress,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { UseMutationResult } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import * as Sentry from '@sentry/capacitor';
import PaypalLogo from 'assets/paypal_logo.svg?react';
import { LoadingAndErrors } from 'components/LoadingAndErrors';
import {
  PaymentMethodData,
  ProcessDonationPaymentTokenData,
  ProcessInitialDonationPaymentTokenData,
  ProcessPaymentState,
  ProcessMembershipPaymentTokenData,
  MembershipConfig,
  ProcessMembershipUpgradeData,
} from 'shared/types';
import { useSnackbarState } from 'state';
import GrayButton from 'components/GrayButton';
import { formatToUSDollars } from 'shared/utils';
import { useEmailValidation } from 'hooks/useEmailValidation';
import { recurlyOnErrorHandler } from '../../shared/recurlyUtils';
import ApplePayButton from './ApplePayButton';
import usePayments from './usePayments';
import PaymentMethod from './PaymentMethod';
import {
  FormValues,
  getValidationSchema,
  getPaymentText,
  isFieldDisabled,
} from './utils';

type RecurlyAccountInformationProps<T> = {
  transactionType: 'membership' | 'donation';
  initialState: ProcessPaymentState;
  updateState: (newState: Partial<ProcessPaymentState>) => void;
  processPaymentMutation: UseMutationResult<void, Error, T, unknown>;
  paymentMethod?: PaymentMethodData;
  membershipConfig?: MembershipConfig;
};

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
    overflowY: 'auto',
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(3),
    paddingBottom: 'max(env(safe-area-inset-bottom), 16px)',
  },
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardIcon: {
    width: 24,
    height: 24,
  },
  alert: {
    backgroundColor: '#FDECEA',
    color: '#E25345',
  },
  loadingContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const RecurlyAccountInformation = <
  T extends
    | ProcessMembershipPaymentTokenData
    | ProcessInitialDonationPaymentTokenData
    | ProcessDonationPaymentTokenData
    | ProcessMembershipUpgradeData,
>(
  props: RecurlyAccountInformationProps<T>,
): JSX.Element => {
  const {
    transactionType,
    initialState,
    updateState,
    processPaymentMutation,
    paymentMethod,
    membershipConfig,
  } = props;
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { setSnackbar } = useSnackbarState();

  const isMembershipTransaction = transactionType === 'membership';
  const {
    clientToken,
    transactionAmount,
    donationRenews,
    existingUser,
    verificationToken,
    userId,
  } = initialState;

  const initialValues: FormValues = useMemo(() => {
    return {
      firstName: initialState.firstName,
      lastName: initialState.lastName,
      email: initialState.email,
      optInToUpdates: initialState.optInToUpdates,
    };
  }, [
    initialState.email,
    initialState.firstName,
    initialState.lastName,
    initialState.optInToUpdates,
  ]);

  const emailValidationCache = useRef<Map<string, boolean>>(new Map());

  const {
    watch,
    control,
    formState: { isValid, errors, isValidating },
    trigger,
  } = useForm<FormValues>({
    resolver: yupResolver(
      getValidationSchema(t, !!existingUser, emailValidationCache.current),
    ),
    defaultValues: initialValues,
    mode: 'onBlur',
  });

  const firstName = watch('firstName');
  const lastName = watch('lastName');
  const email = watch('email');
  const optInToUpdates = watch('optInToUpdates');

  const { mutate, isPending } = processPaymentMutation;

  const processMembershipPayment = (token?: string): void => {
    if (token && !clientToken) return;

    if (!token && (!userId || !verificationToken)) return;

    if (!membershipConfig?.code) return;

    const data: ProcessMembershipPaymentTokenData = {
      email,
      planCode: membershipConfig.code,
      optInToUpdates,
    };

    if (userId && verificationToken) {
      data.userId = userId;
      data.verificationToken = verificationToken;
    } else {
      data.authorizationToken = token;
      data.clientToken = clientToken;
      data.firstName = firstName;
      data.lastName = lastName;
    }

    mutate(data as T, {
      onError: (err: unknown) => {
        recurlyOnErrorHandler(err, setSnackbar, t);
      },
    });
  };

  const processInitialDonationPayment = (token: string): void => {
    if (!clientToken) return;

    const data: ProcessInitialDonationPaymentTokenData = {
      clientToken,
      donationAmount: transactionAmount,
      donationRenews: !!donationRenews,
      firstName,
      lastName,
      email,
      optInToUpdates,
      authorizationToken: token,
    };

    mutate(data as T, {
      onError: (err: unknown) => {
        recurlyOnErrorHandler(err, setSnackbar, t);
      },
    });
  };

  const processReturningDonationPayment = (token?: string): void => {
    if (!userId || !verificationToken) return;

    const data: ProcessDonationPaymentTokenData = {
      userId,
      verificationToken,
      donationAmount: transactionAmount,
      donationRenews: !!donationRenews,
    };

    if (token) {
      data.authorizationToken = token;
    }

    mutate(data as T, {
      onError: (err: unknown) => {
        recurlyOnErrorHandler(err, setSnackbar, t);
      },
    });
  };

  const submitToken = (token: string): void => {
    if (isMembershipTransaction) {
      processMembershipPayment(token);
      return;
    }

    if (clientToken) {
      processInitialDonationPayment(token);
      return;
    }

    if (userId && verificationToken) {
      processReturningDonationPayment(token);
    }
  };

  const { applePaySupported, applePay, payPal } = usePayments({
    isMembershipTransaction: transactionType === 'membership',
    transactionAmount,
    // "submitToken" is not used as a dependency of any other hook/custom hooks in "usePayments", so no need to memoize
    submitToken,
  });

  const handleUpdatePaymentMethod = (
    event: MouseEvent<HTMLAnchorElement>,
  ): void => {
    if (!isValid) {
      event.preventDefault();
      trigger();
      return;
    }

    updateState({
      firstName,
      lastName,
      email,
      optInToUpdates,
      updatePaymentMethod: true,
    });
  };

  const processPaymentWithSavedMethod = (): void => {
    if (isMembershipTransaction) {
      if (membershipConfig?.upgrade) {
        if (!verificationToken || !userId) {
          Sentry.captureMessage(
            'Missing parameters, membership upgrade aborted',
            {
              level: 'error',
              extra: {
                verificationToken: verificationToken || '',
                userId: userId || '',
                upgradePlanCode: membershipConfig.code,
                fromIap: false,
              },
            },
          );
          return;
        }

        const data: ProcessMembershipUpgradeData = {
          verificationToken,
          userId,
          upgradePlanCode: membershipConfig.code,
          fromIap: false,
        };

        mutate(data as T, {
          onError: (err: unknown) => {
            recurlyOnErrorHandler(err, setSnackbar, t);
          },
        });
        return;
      }

      processMembershipPayment();
      return;
    }

    processReturningDonationPayment();
  };

  const handleGoToCC = (): void => {
    updateState({
      firstName,
      lastName,
      email,
      optInToUpdates,
      updatePaymentMethod: false,
    });
  };

  const { emailWarningText, emailFieldSxProps } = useEmailValidation({
    fieldKey: 'email',
    errors,
    watch,
  });

  if (isPending) {
    return <LoadingAndErrors isLoading />;
  }

  const displayFirstNameInput =
    !paymentMethod || (paymentMethod && !initialValues.firstName);
  const displayLastNameInput =
    !paymentMethod || (paymentMethod && !initialValues.lastName);
  const paymentMethodText = getPaymentText(
    t,
    isMembershipTransaction,
    membershipConfig?.upgrade,
    transactionAmount,
  );

  return (
    <div className={classes.root}>
      <Container
        maxWidth="sm"
        className={classes.container}
        component="form"
        noValidate
      >
        <Grid
          container
          spacing={2}
          direction="column"
          sx={{ flex: 1, marginBottom: 3 }}
        >
          <Grid item>
            <Typography variant="h2" sx={{ marginBottom: 1 }}>
              <b>{t('recurlyAccountInformation.title')}</b>
            </Typography>
          </Grid>

          {errors.email?.type === 'registered' && (
            <Grid item>
              <Alert icon={false} severity="error" className={classes.alert}>
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: 'medium',
                    marginBottom: '16px',
                  }}
                >
                  {errors.email.message}
                </Typography>

                <MuiLink
                  to="/login"
                  component={Link}
                  color="inherit"
                  underline="always"
                  variant="body2"
                  sx={{
                    fontWeight: 'bold',
                  }}
                >
                  {t('recurlyAccountInformation.login')}
                </MuiLink>
              </Alert>
            </Grid>
          )}

          {(displayFirstNameInput || displayLastNameInput) && (
            <Grid item>
              <Grid container spacing={2}>
                {displayFirstNameInput && (
                  <Grid item xs={6}>
                    <Controller
                      name="firstName"
                      control={control}
                      render={({ field, fieldState }): JSX.Element => {
                        const { ref, ...muiFieldProps } = field;
                        return (
                          <TextField
                            id="field-control-firstName"
                            label={t(
                              'recurlyAccountInformation.inputs.firstName.label',
                            )}
                            fullWidth
                            {...muiFieldProps}
                            inputRef={ref}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            required
                            disabled={isFieldDisabled(
                              isMembershipTransaction,
                              initialState,
                              initialValues.firstName,
                            )}
                          />
                        );
                      }}
                    />
                  </Grid>
                )}

                {displayLastNameInput && (
                  <Grid item xs={6}>
                    <Controller
                      name="lastName"
                      control={control}
                      render={({ field, fieldState }): JSX.Element => {
                        const { ref, ...muiFieldProps } = field;
                        return (
                          <TextField
                            id="field-control-lastName"
                            label={t(
                              'recurlyAccountInformation.inputs.lastName.label',
                            )}
                            fullWidth
                            {...muiFieldProps}
                            inputRef={ref}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            required
                            disabled={isFieldDisabled(
                              isMembershipTransaction,
                              initialState,
                              initialValues.lastName,
                            )}
                          />
                        );
                      }}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}

          <Grid item>
            <Controller
              name="email"
              control={control}
              render={({ field, fieldState }): JSX.Element => {
                const { ref, ...muiFieldProps } = field;
                return (
                  <TextField
                    id="field-control-email"
                    label={t('recurlyAccountInformation.inputs.email.label')}
                    fullWidth
                    type="email"
                    {...muiFieldProps}
                    inputRef={ref}
                    error={!!fieldState.error}
                    helperText={
                      (fieldState.error?.type !== 'registered'
                        ? fieldState.error?.message
                        : undefined) || emailWarningText
                    }
                    required
                    disabled={existingUser}
                    color={emailWarningText ? 'warning' : undefined}
                    focused={!!emailWarningText}
                    sx={emailFieldSxProps}
                  />
                );
              }}
            />
          </Grid>

          {!userId && (
            <Grid item>
              <Controller
                name="optInToUpdates"
                control={control}
                render={({ field }): JSX.Element => {
                  return (
                    <FormControlLabel
                      control={
                        <Checkbox
                          name={field.name}
                          checked={field.value}
                          onChange={(event) =>
                            field.onChange(event.target.checked)
                          }
                        />
                      }
                      label={t(
                        'recurlyAccountInformation.inputs.optInToUpdates.label',
                      )}
                    />
                  );
                }}
              />
            </Grid>
          )}

          {isMembershipTransaction && !userId && (
            <>
              {membershipConfig?.title && (
                <Grid item>
                  <Box sx={{ marginTop: 1 }}>
                    <Typography variant="h3">
                      <b>{membershipConfig.title}</b>
                    </Typography>
                  </Box>
                </Grid>
              )}

              {membershipConfig?.description && (
                <Grid item>
                  <Typography
                    sx={{
                      fontWeight: 'medium',
                    }}
                  >
                    {membershipConfig.description}
                  </Typography>
                </Grid>
              )}
            </>
          )}

          {!!paymentMethod && (
            <Grid item data-testid="saved-payment-method">
              <Typography variant="h3" sx={{ marginTop: 2, marginBottom: 2 }}>
                {t('recurlyAccountInformation.paymentMethod.title')}
              </Typography>

              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                {paymentMethod.paymentType === 'paypal_billing_agreement' && (
                  <PaymentMethod type="PayPal" />
                )}

                {paymentMethod.paymentType === 'apple_pay' && (
                  <PaymentMethod type="ApplePay" />
                )}

                {paymentMethod.paymentType === 'credit_card' && (
                  <>
                    {paymentMethod.cardType && (
                      <PaymentMethod type={paymentMethod.cardType} />
                    )}

                    <Box sx={{ textAlign: 'right' }}>
                      <Typography variant="body1">
                        {t(
                          'recurlyAccountInformation.paymentMethod.cardNumber',
                          {
                            number: paymentMethod.lastFour,
                          },
                        )}
                      </Typography>

                      <Typography variant="body2" color="secondary">
                        {t(
                          'recurlyAccountInformation.paymentMethod.cardExpires',
                          {
                            month: paymentMethod.expMonth,
                            year: paymentMethod.expYear.toString().substring(2),
                          },
                        )}
                      </Typography>
                    </Box>
                  </>
                )}
              </Box>
            </Grid>
          )}

          {!paymentMethod && !!userId && (
            <Grid item>
              <Typography variant="h3" sx={{ marginTop: 2 }}>
                {t('recurlyAccountInformation.paymentInformation')}
              </Typography>
            </Grid>
          )}

          <Grid item>
            <Typography
              data-testid="total-donation-amount"
              sx={{
                fontWeight: 'bold',
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: isMembershipTransaction ? 0 : 2,
              }}
            >
              <span>{t('recurlyAccountInformation.total')}</span>
              <span>
                {formatToUSDollars(
                  transactionAmount,
                  isMembershipTransaction ? 2 : 0,
                )}
                {!isMembershipTransaction && donationRenews && (
                  <Typography component="span" color="secondary">
                    &nbsp;{t('donate.perMonth')}
                  </Typography>
                )}
              </span>
            </Typography>
          </Grid>

          {!!membershipConfig?.upgrade && (
            <Grid item>
              <Typography variant="body2" sx={{ marginTop: 1 }}>
                {t('recurlyAccountInformation.upgrade')}
              </Typography>
            </Grid>
          )}

          {!!paymentMethod && (
            <>
              <Grid item>
                <Typography variant="h3" sx={{ marginTop: 4 }}>
                  {t('recurlyAccountInformation.updatePaymentMethod')}
                </Typography>
              </Grid>

              <Grid item>
                <List>
                  <ListItem disablePadding>
                    <ListItemButton
                      component={Link}
                      to={
                        isMembershipTransaction
                          ? '/membership/billing_info'
                          : '/donate/billing_info'
                      }
                      onClick={handleUpdatePaymentMethod}
                    >
                      <ListItemText
                        primary={t('recurlyAccountInformation.button')}
                      />
                      <ListItemIcon sx={{ minWidth: 0 }}>
                        <ArrowForwardIosIcon color="inherit" />
                      </ListItemIcon>
                    </ListItemButton>
                  </ListItem>
                  <Divider />
                </List>
              </Grid>
            </>
          )}
        </Grid>

        {paymentMethod ? (
          <Button
            fullWidth
            size="large"
            onClick={processPaymentWithSavedMethod}
          >
            {paymentMethodText}
          </Button>
        ) : (
          <Grid
            container
            spacing={2}
            direction="column"
            sx={{ position: 'relative' }}
          >
            {!!applePay && applePaySupported && (
              <Grid item>
                <ApplePayButton
                  variant={isMembershipTransaction ? 'default' : 'donation'}
                  onClick={() => applePay?.begin()}
                  disabled={!isValid}
                />
              </Grid>
            )}

            <Grid item>
              <GrayButton
                fullWidth
                size="large"
                onClick={() => payPal.start()}
                disabled={!isValid}
                aria-label={t('recurlyAccountInformation.payPal.button')}
                sx={!isValid ? { opacity: 0.7 } : {}}
              >
                <PaypalLogo />
              </GrayButton>
            </Grid>

            <Grid item>
              <GrayButton
                fullWidth
                size="large"
                startIcon={<CreditCardIcon className={classes.cardIcon} />}
                disabled={!isValid}
                component={Link}
                to={
                  isMembershipTransaction
                    ? '/membership/billing_info'
                    : '/donate/billing_info'
                }
                onClick={handleGoToCC}
                data-testid="credit-card-button"
              >
                {t('recurlyAccountInformation.button')}
              </GrayButton>

              {isValidating && (
                <div className={classes.loadingContainer}>
                  <CircularProgress size={48} sx={{ ml: 2 }} />
                </div>
              )}
            </Grid>
          </Grid>
        )}
      </Container>
    </div>
  );
};

export default RecurlyAccountInformation;
