import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import { bindTrigger } from 'material-ui-popup-state/hooks';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { parseReplayTime } from './CameraDetails.utils';
import { TimelapseSlider } from './TimelapseSlider';
import { CameraPlayerControlCommonProps } from './CameraPlayerControl.types';

const useStyles = makeStyles()((theme) => ({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    zIndex: 2,
    paddingTop: 'max(env(safe-area-inset-top), 16px)',
    paddingBottom: 'max(env(safe-area-inset-bottom), 16px)',
    paddingLeft: 'max(env(safe-area-inset-left), 16px)',
    paddingRight: 'max(env(safe-area-inset-right), 16px)',
    pointerEvents: 'none',
  },
  container: {
    flex: 1,
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  statusContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    borderRadius: theme.shape.borderRadius * 2,
    backgroundColor: theme.palette.grey[300],
    pointerEvents: 'auto',
    '&:hover': {
      backgroundColor: theme.palette.grey[500],
    },
    '&:disabled': {
      backgroundColor: theme.palette.grey[700],
    },
  },
  replayTimeButton: {
    fontWeight: 'bold',
    color: theme.palette.background.paper,
    pointerEvents: 'auto',
    '.MuiButton-endIcon': {
      marginLeft: '2px',
    },
    '&:disabled': {
      color: theme.palette.background.paper,
    },
  },
  loaderContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export const CameraPlayerFullScreenControls = (
  props: CameraPlayerControlCommonProps,
): JSX.Element => {
  const {
    camera,
    imageTimestamp,
    isLoading,
    playerStatus,
    popupState,
    timelapseFramesLength,
    timelapseFrameNumber,
    timelapseReplayTime,
    handleTogglePlay,
    handleSliderChange,
  } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Typography
          variant="h3"
          sx={{
            fontWeight: 'bold',
            color: 'background.paper',
          }}
        >
          {camera.name}
        </Typography>

        <div className={classes.statusContainer}>
          <IconButton
            className={classes.button}
            onClick={handleTogglePlay}
            disabled={isLoading}
            sx={{ width: 96, height: 96, borderRadius: '28px !important' }}
            aria-label={
              playerStatus === 'playingTimelapse'
                ? t('alertCameras.pauseTimelapse')
                : t('alertCameras.playTimelapse')
            }
          >
            {playerStatus === 'playingTimelapse' ? (
              <PauseIcon
                fontSize="large"
                sx={{ color: 'text.primary', fontSize: 54 }}
              />
            ) : (
              <PlayArrowIcon
                fontSize="large"
                sx={{ color: 'text.primary', fontSize: 54 }}
              />
            )}
          </IconButton>

          {isLoading && (
            <div className={classes.loaderContainer}>
              <CircularProgress size={48} />
            </div>
          )}
        </div>

        <Stack
          direction="row"
          sx={{
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            gap: 2,
          }}
        >
          <Box
            sx={{
              flex: 1,
              minWidth: { xs: '100%', sm: 'unset' },
            }}
          >
            <TimelapseSlider
              imageTimestamp={imageTimestamp}
              isLoading={isLoading}
              playerStatus={playerStatus}
              timelapseFrameNumber={timelapseFrameNumber}
              timelapseFramesLength={timelapseFramesLength}
              handleSliderChange={handleSliderChange}
              spacing={1}
              dark
            />
          </Box>

          <Stack
            direction="row"
            spacing={1}
            sx={{
              justifyContent: 'flex-end',
              flex: { xs: 1, sm: 'unset' },
            }}
          >
            <Button
              {...bindTrigger(popupState)}
              disabled={isLoading}
              variant="text"
              size="small"
              endIcon={
                <ExpandMoreIcon
                  fontSize="medium"
                  sx={{ color: 'background.paper' }}
                />
              }
              className={classes.replayTimeButton}
            >
              {parseReplayTime(timelapseReplayTime, t)}
            </Button>

            <IconButton
              component={Link}
              to={`/camera/${camera.id}`}
              replace
              className={classes.button}
              sx={{ width: 42, height: 42, borderRadius: '12px !important' }}
              aria-label={t('alertCameras.exitFullScreen')}
            >
              <FullscreenExitIcon
                fontSize="medium"
                sx={{ color: 'text.primary' }}
              />
            </IconButton>
          </Stack>
        </Stack>
      </div>
    </div>
  );
};
