import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { RiverGaugeObservation } from '../../riverGauges.types';
import { API } from '../../../../api';

type UseRiverGaugeObservationsProps = {
  id: string;
  start: Date;
  end: Date;
  pollInterval?: number;
};

type UseRiverGaugeObservations = Omit<
  UseQueryResult<RiverGaugeObservation[], Error>,
  'data'
> & {
  observations?: RiverGaugeObservation[];
};

type ServerRiverGaugeObservationResponse = {
  history: ServerRiverGaugeObservation[];
};

type ServerRiverGaugeObservation = {
  timestamp: string;
  values: {
    flow: number;
    level: number;
  };
};

type FetchValues = {
  id: string;
  start: Date;
  end: Date;
};

const fetchRiverGaugeObservations = async (
  values: FetchValues,
): Promise<RiverGaugeObservation[]> => {
  const { id, start, end } = values;
  const response = await API.get<ServerRiverGaugeObservationResponse>(
    `weather/observations/${id}/`,
    {
      params: {
        start: start.toISOString(),
        end: end.toISOString(),
      },
    },
  );

  const ret = response.data.history.map((observation) => ({
    timestamp: new Date(observation.timestamp),
    ...observation.values,
  }));

  return ret;
};

export const useRiverGaugeObservations = (
  props: UseRiverGaugeObservationsProps,
): UseRiverGaugeObservations => {
  const { id, start, end, pollInterval } = props;
  const { data, ...results } = useQuery({
    queryKey: ['riverGaugueObservations', id],
    queryFn: () => fetchRiverGaugeObservations({ id, start, end }),
    refetchInterval: pollInterval ?? false,
  });

  return {
    ...results,
    observations: data,
  };
};
