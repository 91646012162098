import { Grid2, Stack, Typography, TypographyProps } from '@mui/material';

export type StatItem = {
  label: string;
  value: number | string | null;
  testId?: string;
  valueTypographyProps?: TypographyProps;
};

type StatTableProps = {
  stats: StatItem[];
};

export const StatTable = (props: StatTableProps): JSX.Element => {
  const { stats } = props;

  return (
    <Grid2 container rowGap={1} component="dl">
      {stats.map(
        ({ label, value, testId, valueTypographyProps = {} }, index) => (
          <Grid2
            key={label}
            size={4}
            textAlign="center"
            sx={{
              // Show right-border if this is not the last item on the row (assuming 3 per row)
              borderRight: (index + 1) % 3 !== 0 ? 1 : 0,
              borderColor: 'divider',
            }}
          >
            <Stack margin={1} gap={0.5} data-testid={testId}>
              <Typography
                variant="subtitle1"
                component="dt"
                color="textSecondary"
                textTransform="uppercase"
              >
                {label}
              </Typography>
              <Typography component="dd" {...valueTypographyProps}>
                {value ?? '-'}
              </Typography>
            </Stack>
          </Grid2>
        ),
      )}
    </Grid2>
  );
};
