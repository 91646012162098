import { useCallback, useEffect, useRef } from 'react';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  Link as MuiLink,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory, Link } from 'react-router-dom';
import 'cordova-plugin-purchase';
import { useMutation } from '@tanstack/react-query';
import { LoadingAndErrors } from 'components/LoadingAndErrors';
import useIapState, {
  MEMBERSHIP_DESCRIPTION_TRANS_KEYS,
  MEMBERSHIP_TITLE_TRANS_KEYS,
} from 'state/useIapState';
import { useAuthState, useSnackbarState } from 'state';
import { recurlyOnErrorHandler } from 'shared/recurlyUtils';
import { useEmailValidation } from 'hooks/useEmailValidation';
import {
  FormValues,
  getValidationSchema,
  processIapPurchaseApi,
  upgradeMembershipApi,
  handleValidatorSuccess,
  preProcessPurchaseIapApi,
} from './utils';

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
    overflowY: 'auto',
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(3),
    paddingBottom: 'max(env(safe-area-inset-bottom), 16px)',
  },
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  checkbox: {
    paddingTop: 0,
    paddingBottom: 0,
    borderRadius: 42,
  },
  alert: {
    backgroundColor: '#FDECEA',
    color: '#E25345',
  },
}));

const MembershipIAPCheckoutContent = (): JSX.Element => {
  const {
    checkoutDetails,
    products,
    selectedProductId,
    plan,
    loading,
    setCheckoutDetails,
    setLoading,
  } = useIapState();
  const { classes } = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { setSnackbar } = useSnackbarState();
  const { user } = useAuthState();

  const isMembershipUpgrade = user?.supporterStatus?.planType === 'regular';

  const emailValidationCache = useRef<Map<string, boolean>>(new Map());

  const {
    control,
    formState: { errors },
    handleSubmit,
    getValues,
    watch,
    reset,
  } = useForm<FormValues>({
    resolver: yupResolver(
      getValidationSchema(t, isMembershipUpgrade, emailValidationCache.current),
    ),
    defaultValues: {
      firstName: user?.firstName || checkoutDetails.firstName || '',
      lastName: user?.lastName || checkoutDetails.lastName || '',
      email: user?.email || checkoutDetails.email || '',
      optInToUpdates: checkoutDetails.optInToUpdates || false,
    },
    mode: 'onBlur',
  });

  const { mutate: processIapPurchase } = useMutation({
    mutationFn: processIapPurchaseApi,
  });

  const { mutate: preProcessIapPurchase } = useMutation({
    mutationFn: preProcessPurchaseIapApi,
  });

  const { mutate: upgradeMembership } = useMutation({
    mutationFn: upgradeMembershipApi,
  });

  const onTransactionApproved = useCallback(
    (transaction: CdvPurchase.Transaction) => {
      if (
        // 'finished' transactions also fire this event up
        transaction.state !== CdvPurchase.TransactionState.APPROVED ||
        // On every app start on iOS, you receive a transaction with the transactionId 'appstore.application'
        // The app itself is considered a transaction. https://github.com/j3k0/cordova-plugin-purchase/issues/1428#issuecomment-1730879536
        transaction.transactionId ===
          CdvPurchase.AppleAppStore.APPLICATION_VIRTUAL_TRANSACTION_ID
      ) {
        return;
      }

      transaction.verify();
    },
    [],
  );

  const onTransactionVerified = useCallback(
    (receipt: CdvPurchase.VerifiedReceipt) => {
      receipt.finish();
    },
    [],
  );

  const onTransactionFinished = useCallback(
    (transaction: CdvPurchase.Transaction) => {
      // Reset the form after processing the transaction to prevent duplicate submissions.
      // This addresses an issue where multiple calls to "processIapPurchase" can occur if
      // the same transaction is approved more than once. The duplicate calls likely stem
      // from the asynchronous behavior of the "createIapPurchase" validator function that
      // lead to potential race conditions.
      reset();

      let url = '/membership/iap_success';
      if (isMembershipUpgrade) {
        url += '?upgrade=true';
      } else if (!user) {
        url += `?clientToken=${checkoutDetails.clientToken}`;
      }
      history.push(url);
    },
    [checkoutDetails.clientToken, history, isMembershipUpgrade, user, reset],
  );

  const onStoreError = useCallback(
    (err: CdvPurchase.IError) => {
      setLoading(false);
      if (!err.message.includes('cancel')) {
        setSnackbar(err.message, 'error');
      }
    },
    [setLoading, setSnackbar],
  );

  const handleValidatorError = useCallback(
    (
      callback: CdvPurchase.Callback<CdvPurchase.Validator.Response.Payload>,
      err: Error & { response?: { status: number } },
    ) => {
      setLoading(false);
      recurlyOnErrorHandler(err, setSnackbar, t);
      callback({ ok: false, status: err?.response?.status || 400 });
    },
    [setLoading, setSnackbar, t],
  );

  const preCreateIAP = (): void => {
    // We pre process the iap purchase before the order sends off to apple on the button click
    //   to ensure we don't get a race condition where recurly creates an account prior to our iap_process endpoint
    const formValues = getValues();
    // we are explicitly not handling the await/failure states on this POST because it is not critical to the flow
    //   that it succeeds. This covers a <5% race case condition and if its failing first, we shouldn't block the
    //   rest of checkout.
    preProcessIapPurchase({
      externalAccountCode: checkoutDetails.externalAccountCode,
      email: formValues.email,
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      optInToUpdates: formValues.optInToUpdates,
    });
  };

  const createIapPurchase = useCallback(
    (
      receipt: CdvPurchase.Validator.Request.Body,
      callback: CdvPurchase.Callback<CdvPurchase.Validator.Response.Payload>,
    ) => {
      const formValues = getValues();

      if (!formValues.email || !formValues.firstName || !formValues.lastName) {
        // Handle edge cases: Unprocessed or duplicate transactions with reset form/IAP state
        //
        // Scenarios:
        // 1. An "approved" transaction hasn't been processed
        // 2. A transaction is approved multiple times after form reset
        // 3. The IAP (In-App Purchase) state has been reset
        //
        // Potential causes:
        // - Asynchronous validator causing duplicate approvals due to race conditions
        // - Legacy implementation not finalizing transactions correctly
        // - App uninstalls during incomplete transactions
        // - Network issues or app crashes interrupting the purchase flow
        //
        // Purpose:
        // - Prevent duplicate transaction processing
        // - Ensure consistent IAP state across error scenarios
        // - Handle rare edge cases in the purchase flow
        //
        // Note: Scenario 2 likely occurs due to the async behavior of the
        // createIapPurchase validator function.
        callback({ ok: false, status: 400 });
        return;
      }

      const transaction =
        receipt.transaction as CdvPurchase.Validator.Response.NativeTransaction;

      if (isMembershipUpgrade) {
        upgradeMembership(
          {
            verificationToken: checkoutDetails.verificationToken,
            userId: user?.id,
            upgradePlanCode: selectedProductId,
            fromIap: true,
          },
          {
            onSuccess: () =>
              handleValidatorSuccess(callback, transaction, selectedProductId),
            onError: (err) => handleValidatorError(callback, err),
          },
        );

        return;
      }

      processIapPurchase(
        {
          clientToken: checkoutDetails.clientToken,
          externalAccountCode: checkoutDetails.externalAccountCode,
          email: formValues.email,
          firstName: formValues.firstName,
          lastName: formValues.lastName,
          optInToUpdates: formValues.optInToUpdates,
          isRecurring: true,
          planCode: selectedProductId,
        },
        {
          onSuccess: () =>
            handleValidatorSuccess(callback, transaction, selectedProductId),
          onError: (err) => handleValidatorError(callback, err),
        },
      );
    },
    [
      checkoutDetails.clientToken,
      checkoutDetails.externalAccountCode,
      checkoutDetails.verificationToken,
      getValues,
      handleValidatorError,
      isMembershipUpgrade,
      processIapPurchase,
      selectedProductId,
      upgradeMembership,
      user?.id,
    ],
  );

  useEffect(() => {
    CdvPurchase.store
      .when()
      .approved(onTransactionApproved)
      .verified(onTransactionVerified)
      .finished(onTransactionFinished);

    CdvPurchase.store.error(onStoreError);

    // Set ios app account token
    CdvPurchase.store.applicationUsername = checkoutDetails.externalAccountCode;

    // Create iap purchase on api before finishing transaction
    CdvPurchase.store.validator = createIapPurchase;
  }, [
    checkoutDetails.externalAccountCode,
    createIapPurchase,
    onStoreError,
    onTransactionApproved,
    onTransactionFinished,
    onTransactionVerified,
  ]);

  const handlePurchase = async (values: FormValues): Promise<void> => {
    const product = CdvPurchase.store.get(
      selectedProductId,
      CdvPurchase.Platform.APPLE_APPSTORE,
    );
    const offer = product?.getOffer();

    if (offer) {
      setCheckoutDetails(values);
      if (!isMembershipUpgrade) {
        preCreateIAP();
      }
      offer.order();
      setLoading(true);
    }
  };

  const { emailWarningText, emailFieldSxProps } = useEmailValidation({
    fieldKey: 'email',
    errors,
    watch,
  });

  if (loading) {
    return <LoadingAndErrors isLoading />;
  }

  return (
    <div className={classes.root}>
      <Container
        maxWidth="sm"
        className={classes.container}
        component="form"
        noValidate
        onSubmit={handleSubmit(handlePurchase)}
      >
        <Grid
          container
          spacing={2}
          direction="column"
          sx={{ flex: 1, marginBottom: 3 }}
        >
          <Grid item>
            <Typography variant="h2" sx={{ marginBottom: 1 }}>
              <b>{t('membershipCheckout.sections.account')}</b>
            </Typography>
          </Grid>

          {errors.email && errors.email.type === 'registered' && (
            <Grid item>
              <Alert icon={false} severity="error" className={classes.alert}>
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: 'medium',
                    marginBottom: '16px',
                  }}
                >
                  {errors.email.message}
                </Typography>

                <MuiLink
                  to="/login"
                  component={Link}
                  color="inherit"
                  underline="always"
                  variant="body2"
                  sx={{
                    fontWeight: 'bold',
                  }}
                >
                  {t('membershipCheckout.login')}
                </MuiLink>
              </Alert>
            </Grid>
          )}

          {(!user?.firstName || !user?.lastName) && (
            <Grid item>
              <Grid container spacing={2}>
                {!user?.firstName && (
                  <Grid item xs={6}>
                    <Controller
                      name="firstName"
                      control={control}
                      render={({ field, fieldState }): JSX.Element => {
                        const { ref, ...muiFieldProps } = field;
                        return (
                          <TextField
                            id="field-control-firstName"
                            label={t(
                              'membershipCheckout.inputs.firstName.label',
                            )}
                            fullWidth
                            {...muiFieldProps}
                            inputRef={ref}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            required
                          />
                        );
                      }}
                    />
                  </Grid>
                )}

                {!user?.lastName && (
                  <Grid item xs={6}>
                    <Controller
                      name="lastName"
                      control={control}
                      render={({ field, fieldState }): JSX.Element => {
                        const { ref, ...muiFieldProps } = field;
                        return (
                          <TextField
                            id="field-control-lastName"
                            label={t(
                              'membershipCheckout.inputs.lastName.label',
                            )}
                            fullWidth
                            {...muiFieldProps}
                            inputRef={ref}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            required
                          />
                        );
                      }}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}

          <Grid item>
            <Controller
              name="email"
              control={control}
              render={({ field, fieldState }): JSX.Element => {
                const { ref, ...muiFieldProps } = field;
                return (
                  <TextField
                    id="field-control-email"
                    label={t('membershipCheckout.inputs.email.label')}
                    fullWidth
                    type="email"
                    {...muiFieldProps}
                    inputRef={ref}
                    error={!!fieldState.error}
                    helperText={
                      (fieldState.error?.type !== 'registered'
                        ? fieldState.error?.message
                        : undefined) || emailWarningText
                    }
                    required
                    sx={emailFieldSxProps}
                    disabled={!!user?.email}
                    color={emailWarningText ? 'warning' : undefined}
                    focused={!!emailWarningText}
                  />
                );
              }}
            />
          </Grid>

          {!user && (
            <Grid item>
              <Controller
                name="optInToUpdates"
                control={control}
                render={({ field }): JSX.Element => {
                  return (
                    <FormControlLabel
                      control={
                        <Checkbox
                          name={field.name}
                          checked={field.value}
                          onChange={(event) =>
                            field.onChange(event.target.checked)
                          }
                          className={classes.checkbox}
                        />
                      }
                      label={t(
                        'membershipCheckout.inputs.optInToUpdates.label',
                      )}
                    />
                  );
                }}
              />
            </Grid>
          )}

          <Grid item>
            <Box sx={{ marginTop: 1 }}>
              <Typography variant="h3">
                <b>{t(MEMBERSHIP_TITLE_TRANS_KEYS[plan])}</b>
              </Typography>
            </Box>
          </Grid>

          <Grid item>
            <Typography
              sx={{
                fontWeight: 'medium',
              }}
            >
              {t(MEMBERSHIP_DESCRIPTION_TRANS_KEYS[plan])}
            </Typography>
          </Grid>

          <Grid item>
            <Typography
              sx={{
                fontWeight: 'medium',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <span>{t('membershipIntroduction.total')}</span>
              <span>
                {/* Recommended to display prices directly from the product in the appstore */}
                {products[selectedProductId]?.price}
              </span>
            </Typography>
          </Grid>

          {isMembershipUpgrade && (
            <Grid item>
              <Typography variant="body2" sx={{ marginTop: 1 }}>
                {t('membershipCheckout.upgrade')}
              </Typography>
            </Grid>
          )}
        </Grid>

        <Button size="large" fullWidth type="submit">
          {t('membershipCheckout.button')}
        </Button>
      </Container>
    </div>
  );
};

export default MembershipIAPCheckoutContent;
