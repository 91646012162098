import { Theme, Button } from '@mui/material';

import { withStyles } from 'tss-react/mui';

const GrayButton = withStyles(Button, (theme: Theme) => ({
  root: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.grey[300],
    '&:hover': {
      backgroundColor: theme.palette.grey[500],
    },
  },
})) as typeof Button;

export default GrayButton;
