import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from 'react';
import { Capacitor } from '@capacitor/core';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { defer } from 'lodash-es';
import { OperatingSystem } from '@capacitor/device';
import { generateClientToken } from 'shared/utils';
import { useAuthState, useSnackbarState } from 'state';
import { API } from 'api';
import { MembershipPlan, User } from 'shared/types';
import useMembershipState from 'state/useMembershipState';
import useIapState from 'state/useIapState';
import { ANALYTICS_EVENTS, logEvent } from 'shared/analytics';
import { useMembershipManagement } from 'hooks/useMembershipManagement';
import { getDeviceInfo } from 'state/localStorageTyped';
import {
  AppStoreLinks,
  MEMBERSHIP_PLANS,
  WATCHDUTY_SCHEME,
} from '../../../constants';

type MembershipPlanContextType = {
  continueOnWeb: boolean;
  verifyingToken: boolean;
  verificationError: Error | null;
  getNextLink: (plan: MembershipPlan) => string;
  handleNext: () => void;
  updatePlan: (plan: MembershipPlan) => void;
  selectedPlan: MembershipPlan;
};

type MembershipPlanProviderProps = {
  children: ReactNode;
};

const { VITE_BASE_WEB_URL } = import.meta.env;

const MEMBERSHIP_PLAN_TO_ANALYTIC_EVENT = {
  [MEMBERSHIP_PLANS.yearly]: ANALYTICS_EVENTS.CLICKED.MEMBERSHIP_REGULAR,
  [MEMBERSHIP_PLANS.proYearly]: ANALYTICS_EVENTS.CLICKED.MEMBERSHIP_PRO_YEARLY,
  [MEMBERSHIP_PLANS.proMonthly]:
    ANALYTICS_EVENTS.CLICKED.MEMBERSHIP_PRO_MONTHLY,
} as const;

const buildLinkParams = (input: {
  plan: MembershipPlan;
  user: User | null;
  isSupporter: boolean;
  clientToken: string;
  isMembershipUpgrade: boolean;
}): string => {
  const { plan, user, isSupporter, clientToken, isMembershipUpgrade } = input;
  const params = new URLSearchParams({ plan });
  if (user) {
    params.append('email', encodeURIComponent(user.email));
    user.firstName && params.append('firstName', user.firstName);
    user.lastName && params.append('lastName', user.lastName);
  }
  if (user && isSupporter) {
    params.append('userId', user.id.toString());
    params.append('verificationToken', clientToken);
  } else {
    params.append('clientToken', clientToken);
  }
  if (isMembershipUpgrade) {
    params.append('upgrade', 'true');
  }
  return params.toString();
};

const getSupportUsDeepLink = (
  plan: MembershipPlan,
  os?: OperatingSystem,
): string => {
  if (os === 'ios') {
    // Web on iPhone
    return `${WATCHDUTY_SCHEME}app.watchduty.org/support_us/${plan}`;
  }

  if (os === 'mac') {
    // Web on Mac or iPad
    return 'https://apps.apple.com/account/subscriptions';
  }

  // We don't need to deep-link on Android since we do payments on the web there, so always default to iOS link
  return AppStoreLinks.ios;
};

const getBecomeAMemberLink = (input: {
  clientToken: string;
  isSupporter: boolean;
  user: User | null;
  plan: MembershipPlan;
  isMembershipUpgrade: boolean;
  isIosSubscription: boolean;
  osPlatform: string;
  osSystem?: OperatingSystem;
}): string => {
  const {
    clientToken,
    isSupporter,
    user,
    plan,
    isMembershipUpgrade,
    isIosSubscription,
    osPlatform,
    osSystem,
  } = input;
  const params = buildLinkParams({
    plan,
    user,
    isSupporter,
    clientToken,
    isMembershipUpgrade,
  });
  if (osPlatform === 'ios') {
    if (isMembershipUpgrade && !isIosSubscription) {
      return `${VITE_BASE_WEB_URL}membership/account_info?${params}`;
    }
    return '/membership/checkout';
  }
  if (isMembershipUpgrade && isIosSubscription) {
    return getSupportUsDeepLink(plan, osSystem);
  }
  if (osPlatform !== 'android') {
    // Web
    return '/membership/account_info';
  }
  // Android
  return `${VITE_BASE_WEB_URL}membership/account_info?${params}`;
};

const MembershipPlanContext = createContext<
  MembershipPlanContextType | undefined
>(undefined);

export const MembershipPlanProvider = (
  props: MembershipPlanProviderProps,
): JSX.Element => {
  const { children } = props;
  const { t } = useTranslation();
  const { setSnackbar } = useSnackbarState();
  const { user, isSupporter } = useAuthState();
  const { plan: membershipPlan, updateMembershipState } = useMembershipState();
  const {
    plan: iapPlan,
    setSelectedProductId,
    setCheckoutDetails,
  } = useIapState();
  const { isIosSubscription } = useMembershipManagement();
  const history = useHistory();
  const deviceInfo = getDeviceInfo();

  // this is used as either a verificationToken or a clientToken depending if they are already a supporter
  const tokenRef = useRef<string>(generateClientToken());
  if (!tokenRef.current) {
    tokenRef.current = generateClientToken();
  }
  const osPlatform = Capacitor.getPlatform();

  const isIOS = osPlatform === 'ios';
  const isAndroid = osPlatform === 'android';

  const isIosButNotIosSubscription = isIOS && !isIosSubscription;
  const isIosSubscriptionButNotIos = isIosSubscription && !isIOS;

  const isMembershipUpgrade = user?.supporterStatus?.planType === 'regular';

  const continueOnWeb =
    isAndroid ||
    (isMembershipUpgrade &&
      (isIosButNotIosSubscription || isIosSubscriptionButNotIos));

  const {
    mutate: saveVerificationToken,
    isPending: verifyingToken,
    error: verificationError,
  } = useMutation({
    mutationFn: async (userId: number) => {
      await API.post('recurly_integration/one_time_donation_token/', {
        userId,
        verificationToken: tokenRef.current,
      });
    },
    onError: () => {
      setSnackbar(t('common.unknownErrorTryAgain'), 'error');
    },
  });

  const userId = user?.id;

  useEffect(() => {
    if (isSupporter && userId) {
      saveVerificationToken(userId);
    }
  }, [saveVerificationToken, isSupporter, userId]);

  const osSystem = deviceInfo?.operatingSystem;

  const handleNext = useCallback((): void => {
    if (continueOnWeb) {
      if (!isMembershipUpgrade || !isIosSubscription || osSystem !== 'ios') {
        // Go back to home map page when continue on web, but not for ios subscription upgrade on an ios os system
        defer(() => history.push('/'));
      }
      return;
    }

    if (isIOS) {
      if (!isMembershipUpgrade) return;
      setCheckoutDetails({ verificationToken: tokenRef.current });
      return;
    }

    if (user && isSupporter) {
      updateMembershipState({
        email: user.email,
        firstName: user.firstName || '',
        lastName: user.lastName || '',
        existingUser: true,
        verificationToken: tokenRef.current,
        userId: user.id,
      });
    } else {
      updateMembershipState({
        clientToken: tokenRef.current,
        existingUser: false,
      });
    }
  }, [
    continueOnWeb,
    isIOS,
    user,
    isSupporter,
    isMembershipUpgrade,
    isIosSubscription,
    osSystem,
    history,
    setCheckoutDetails,
    updateMembershipState,
  ]);

  const getNextLink = useCallback(
    (plan: MembershipPlan): string =>
      getBecomeAMemberLink({
        clientToken: tokenRef.current,
        isSupporter,
        user,
        plan,
        isMembershipUpgrade,
        isIosSubscription,
        osPlatform,
        osSystem,
      }),
    [
      isIosSubscription,
      isMembershipUpgrade,
      isSupporter,
      osPlatform,
      osSystem,
      user,
    ],
  );

  const updatePlan = useCallback(
    (plan: MembershipPlan) => {
      logEvent({ name: MEMBERSHIP_PLAN_TO_ANALYTIC_EVENT[plan] });
      if (isIOS) {
        setSelectedProductId(plan);
        return;
      }
      updateMembershipState({ plan });
    },
    [isIOS, setSelectedProductId, updateMembershipState],
  );

  const selectedPlan = isIOS ? iapPlan : membershipPlan;

  const value = {
    continueOnWeb,
    verifyingToken,
    verificationError,
    getNextLink,
    handleNext,
    updatePlan,
    selectedPlan,
  };

  return (
    <MembershipPlanContext.Provider value={value}>
      {children}
    </MembershipPlanContext.Provider>
  );
};

export const useMembershipPlan = (): MembershipPlanContextType => {
  const context = useContext(MembershipPlanContext);
  if (context === undefined) {
    throw new Error(
      'useMembershipPlan must be used within a MembershipPlanProvider',
    );
  }
  return context;
};
