import Helmet from 'react-helmet';
import { CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import PageLayout from 'components/PageLayout';
import HeaderBar from 'components/HeaderBar';
import { useAuthState } from 'state';
import Unauthorized from 'components/Unauthorized';
import { GeoEvent } from 'shared/types';
import { GeoEventForm } from './GeoEventForm/GeoEventForm';
import { useGeoEvent } from '../../../hooks/useGeoEvent';

type ContentProps = {
  geoEvent?: GeoEvent;
};

export const Content = (props: ContentProps): JSX.Element => {
  const { geoEvent } = props;
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{geoEvent?.name || t('createEditGeoEvent.title.add')}</title>
      </Helmet>
      <GeoEventForm geoEvent={geoEvent} />
    </>
  );
};

const CreateEditIncident = (
  props: RouteComponentProps<{ geoEventId: string }>,
): JSX.Element => {
  const {
    match: {
      params: { geoEventId },
    },
  } = props;
  const {
    permissions: { canReport },
  } = useAuthState();
  const { t } = useTranslation();

  const {
    geoEvent,
    isGeoEventLoading,
    isGeoEventFetchError,
    geoEventFetchError,
  } = useGeoEvent({
    id: Number(geoEventId),
  });

  if (!canReport) {
    return (
      <PageLayout data-cname="CreateEditIncidentPage">
        {{
          content: <Unauthorized />,
          headerBar: (
            <HeaderBar
              title={t('unauthorized.title')}
              action="back"
              hideAction
            />
          ),
        }}
      </PageLayout>
    );
  }

  if (isGeoEventLoading) {
    return (
      <PageLayout data-cname="CreateEditIncidentPage">
        {{
          content: <CircularProgress />,
          headerBar: (
            <HeaderBar title={t('common.loading')} action="back" hideAction />
          ),
        }}
      </PageLayout>
    );
  }

  if (isGeoEventFetchError) {
    return (
      <PageLayout data-cname="CreateEditIncidentPage">
        {{
          content: <div> {JSON.stringify(geoEventFetchError)} </div>,
          headerBar: (
            <HeaderBar
              title={t(
                `createEditGeoEvent.title.${geoEventId ? 'edit' : 'add'}`,
              )}
              action="back"
              hideAction
            />
          ),
        }}
      </PageLayout>
    );
  }

  return (
    <PageLayout data-cname="CreateEditIncidentPage">
      {{
        content: <Content geoEvent={geoEvent} />,
        headerBar: (
          <HeaderBar
            title={geoEvent?.name || t('createEditGeoEvent.title.add')}
            action="back"
            hideAction
          />
        ),
      }}
    </PageLayout>
  );
};

export default CreateEditIncident;
