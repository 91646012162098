import { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Container,
  Grid,
  Typography,
  List,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Button,
  ListItem,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Link, useHistory } from 'react-router-dom';
import { PlaceLocation } from 'shared/types';
import { removeAddressCountry, placeLocationToMapLocation } from 'shared/utils';
import { PlaceLocationPinIcon, getPlaceIcon } from 'components/Map/Icons';
import useMapPlaces from 'hooks/useMapPlaces';
import { useAuthState } from 'state';
import { MEMBERSHIP_PLANS } from '../../../../constants';

const useStyles = makeStyles()((theme) => ({
  itemIconContainer: {
    minWidth: theme.spacing(4),
  },
  itemIcon: {
    width: 24,
    height: 24,
    color: 'rgb(99, 147, 242)',
  },
  link: {
    color: theme.palette.accent.main,
    fontWeight: theme.typography.fontWeightBold,
    fontFamily: theme.typography.fontFamily,
    padding: '6px 8px',
    textDecoration: 'none',
    textTransform: 'uppercase',
    minWidth: 64,
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const SavedPlaces = (): JSX.Element => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { places } = useMapPlaces();
  const { showMembershipFeatures } = useAuthState();

  const handlePlaceClick = (location: PlaceLocation): void => {
    const mapLocation = placeLocationToMapLocation(location);
    history.replace('/', mapLocation);
  };

  const handleAddPlace: MouseEventHandler<HTMLAnchorElement> = (event) => {
    if (places.length >= 1 && !showMembershipFeatures) {
      event.preventDefault();
      history.push(`/support_us/${MEMBERSHIP_PLANS.yearly}`);
    }
  };

  return (
    <Box sx={{ width: '100%', overflow: 'auto' }}>
      <Container maxWidth="xs">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <List dense>
              {places.map((place) => {
                const icon = getPlaceIcon(place.icon);
                return (
                  <ListItem
                    key={place.id}
                    disablePadding
                    secondaryAction={
                      <Link
                        to={`/edit_place/${place.id}#allow-back`}
                        className={classes.link}
                        onClick={(event) => event.stopPropagation()}
                      >
                        {t('common.edit')}
                      </Link>
                    }
                    sx={{ '.MuiListItemSecondaryAction-root': { right: 0 } }}
                  >
                    <ListItemButton
                      onClick={() => handlePlaceClick(place)}
                      disableGutters
                      sx={{ '&.MuiListItemButton-root': { paddingRight: 8 } }}
                    >
                      <ListItemIcon
                        sx={(theme) => ({ minWidth: theme.spacing(4) })}
                      >
                        <Box sx={{ position: 'relative' }}>
                          <img
                            src={PlaceLocationPinIcon.data}
                            alt="Place"
                            width={PlaceLocationPinIcon.width}
                            height={PlaceLocationPinIcon.height}
                          />
                          <img
                            src={icon.data}
                            alt={place.name}
                            width={icon.width}
                            height={icon.height}
                            style={{
                              position: 'absolute',
                              left: `calc(50% - ${icon.width / 2}px)`,
                              top: `calc(50% - ${icon.height}px)`,
                            }}
                          />
                        </Box>
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography>
                            <b>{place.name}</b>
                          </Typography>
                        }
                        secondary={removeAddressCountry(
                          place.address.formattedAddress || place.address.name,
                        )}
                      />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </Grid>

          <Grid item xs={12}>
            <Button
              fullWidth
              size="large"
              to="/add_place#allow-back"
              component={Link}
              onClick={handleAddPlace}
            >
              {t('places.button')}
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default SavedPlaces;
