import { MenuItem, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { parseCoordinate } from 'shared/utils';
import { useShareOrCopy } from '../../../hooks/useShareOrCopy';
import { MapToolsMenuItemProps } from './types';

const CopyPositionMenuItem = (props: MapToolsMenuItemProps): JSX.Element => {
  const { position, onClick, padding } = props;
  const { t } = useTranslation();
  const { copied, shareOrCopyText, reset } = useShareOrCopy();
  const latLng = `${parseCoordinate(position.lat)}, ${parseCoordinate(
    position.lng,
  )}`;

  return (
    <Tooltip
      title={t('common.copied')}
      open={copied}
      placement="top"
      onClose={reset}
      leaveDelay={200}
    >
      <MenuItem
        onClick={() => {
          shareOrCopyText({ text: latLng, showClipboardSuccessMessage: false });
          // Give the tooltip a little time to show up before closing the context menu.
          setTimeout(onClick, 500);
        }}
        sx={{ padding }}
      >
        {latLng}
      </MenuItem>
    </Tooltip>
  );
};

export default CopyPositionMenuItem;
