import {
  ListItemButton,
  ListItemText,
  Switch,
  Typography,
  Box,
  ListItem,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Membership } from 'shared/types';
import UserStatusChip from '../UserStatusChip';

type LayerControlItemProps = {
  titleKey: string;
  subtitleKey: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
  mapLayer: string;
  disabled?: boolean;
  internalOnly?: boolean;
  minMembership?: Membership;
};

const LayerControlItem = ({
  titleKey,
  subtitleKey,
  checked,
  onChange,
  mapLayer,
  disabled = false,
  internalOnly,
  minMembership = 'public',
}: LayerControlItemProps): JSX.Element => {
  const { t } = useTranslation();

  const handleChange = (): void => {
    if (disabled) return;
    onChange(!checked);
  };

  return (
    <ListItem
      disablePadding
      secondaryAction={
        <Switch
          disabled={disabled}
          edge="end"
          checked={checked}
          inputProps={{
            'aria-labelledby': t('map.accessibility.switchLayer'),
            'aria-label': `Toggle ${mapLayer}`,
          }}
          onClick={handleChange}
        />
      }
    >
      <ListItemButton
        divider
        disabled={disabled}
        onClick={handleChange}
        sx={{ '&.MuiListItemButton-root': { paddingRight: 8 } }}
      >
        <ListItemText
          primary={
            <Box
              sx={{ display: 'flex', alignItems: 'center' }}
              component="span"
            >
              <Typography component="span">{t(titleKey)}</Typography>
              {minMembership !== 'public' && (
                <UserStatusChip
                  sx={{ marginLeft: 1 }}
                  type={minMembership === 'pro' ? 'pro' : 'members'}
                />
              )}
              {internalOnly && (
                <UserStatusChip sx={{ marginLeft: 1 }} type="internal" />
              )}
            </Box>
          }
          primaryTypographyProps={{ variant: 'body1' }}
          secondary={disabled ? t('map.layers.disabled') : t(subtitleKey)}
          secondaryTypographyProps={{ variant: 'subtitle1' }}
        />
      </ListItemButton>
    </ListItem>
  );
};

export default LayerControlItem;
