import {
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Chip,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { TFunction } from 'i18next';

type FeaturesListProps = {
  pro?: boolean;
};

const getFeatureList = (t: TFunction): string[] => [
  t('supportUs.features.one'),
  t('supportUs.features.two'),
  t('supportUs.features.three'),
];

const getProFeatureList = (
  t: TFunction,
): { transKey: string; ready: boolean }[] => [
  { transKey: t('supportUs.features.pro.one'), ready: true },
  { transKey: t('supportUs.features.pro.two'), ready: true },
  { transKey: t('supportUs.features.pro.three'), ready: true },
  { transKey: t('supportUs.features.pro.four'), ready: true },
  { transKey: t('supportUs.features.pro.five'), ready: true },
  { transKey: t('supportUs.features.pro.six'), ready: false },
  { transKey: t('supportUs.features.pro.seven'), ready: false },
  { transKey: t('supportUs.features.pro.eight'), ready: true },
];

export const FeaturesList = (props: FeaturesListProps): JSX.Element => {
  const { pro } = props;
  const { t } = useTranslation();

  return (
    <List dense disablePadding>
      {getFeatureList(t).map((feature) => (
        <ListItem disableGutters key={feature}>
          <ListItemIcon sx={{ minWidth: 28, fontSize: 16 }}>
            <TaskAltIcon color="primary" fontSize="inherit" />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography
                variant="body2"
                sx={{
                  fontSize: 14,
                }}
              >
                {feature}
              </Typography>
            }
            sx={{ margin: 0 }}
          />
        </ListItem>
      ))}
      {pro &&
        getProFeatureList(t).map(({ transKey, ready }) => (
          <ListItem disableGutters key={transKey}>
            <ListItemIcon sx={{ minWidth: 28, fontSize: 16 }}>
              <TaskAltIcon color="primary" fontSize="inherit" />
            </ListItemIcon>
            <ListItemText
              primary={
                <Stack
                  direction="row"
                  sx={{
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: 14,
                    }}
                  >
                    {transKey}
                  </Typography>
                  {!ready && (
                    <Chip
                      label={t('supportUs.comingSoon')}
                      color="primary"
                      component="span"
                      variant="watchduty"
                      sx={{ '& span': { fontSize: 13 } }}
                    />
                  )}
                </Stack>
              }
              sx={{ margin: 0 }}
            />
          </ListItem>
        ))}
    </List>
  );
};
