import { useMemo, useEffect } from 'react';
import {
  Box,
  Container,
  Grid,
  useTheme,
  useMediaQuery,
  Typography,
  TextField,
  MenuItem,
  Button,
  CircularProgress,
  FormHelperText,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import {
  useForm,
  Controller,
  SubmitHandler,
  FormProvider,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useHistory } from 'react-router-dom';
import GeoEventReportsMap from 'components/GeoEventReportsMap';
import {
  GeoEvent,
  NotificationType,
  Report,
  ReportAsset,
  ReportMedia,
} from 'shared/types';
import { API } from 'api';
import { useSnackbarState } from 'state';
import { Capacitor } from '@capacitor/core';
import { parseNumericString, removeHTMLTags } from 'shared/utils';
import { isSupportedEmbedUrl } from 'shared/embedHelpers';
import RichTextEditor from 'components/RichTextEditor';
import { validateReport } from '../../AddIncidentReport/ReportForm/utils';
import { MediaForm } from '../../../../components/MediaForm';

type EditReportFormProps = {
  geoEvent: GeoEvent;
  report: Report;
};

type FormValues = {
  geoEventId: number;
  message: string;
  asset: ReportAsset;
  mediaUrl: string;
  lat: string;
  lng: string;
  az: number;
  embedUrl: string;
};

type ReportUpdateData = {
  message: string;
  embedUrl?: string;
  media?: Omit<ReportMedia, 'dateCreated' | 'dateModified'>[];
};

// todo: react-query function type returns can be addressed after react-query 5 upgrade
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const updateReport = (reportId: number, data: ReportUpdateData) =>
  API.patch(`reports/${reportId}`, data);

// todo: react-query function type returns can be addressed after react-query 5 upgrade
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const deleteMedia = (mediaId: number) => API.delete(`media/${mediaId}`);

const getAssetInitialValue = (
  media?: ReportMedia,
  embedUrl?: string,
): ReportAsset => {
  if (media) {
    if (media.attribution) {
      return 'alertCamera';
    }
    return 'image';
  }
  if (embedUrl) {
    return 'socialEmbed';
  }
  return null;
};

const useStyles = makeStyles()((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    width: '100%',
    height: '100%',
    display: 'flex',
  },
  formContainer: {
    overflowY: 'auto',
    height: '100%',
    width: '100%',
    maxWidth: 720,
  },
  form: {
    paddingTop: theme.spacing(2),
    paddingBottom: 'max(env(safe-area-inset-bottom), 16px)',
  },
  itemLabel: {
    textTransform: 'capitalize',
  },
}));

const EditReportForm = (props: EditReportFormProps): JSX.Element => {
  const { geoEvent, report } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const isLargeMediaQuery = useMediaQuery(theme.breakpoints.up(768), {
    defaultMatches: !Capacitor.isNativePlatform(),
  });
  const { setSnackbar } = useSnackbarState();
  const history = useHistory();
  const queryClient = useQueryClient();

  const validationSchema = useMemo(
    () =>
      Yup.object().shape(
        {
          geoEventId: Yup.number().required(
            t('editReport.inputs.incident.required'),
          ),
          message: Yup.string()
            .required(t('editReport.inputs.message.required'))
            .min(3, t('editReport.inputs.message.minError')),
          mediaUrl: Yup.string(),
          lat: Yup.number()
            .typeError(t('addIncidentReport.inputs.latitude.typeError'))
            .min(-90, t('addIncidentReport.inputs.latitude.minError'))
            .max(90, t('addIncidentReport.inputs.latitude.maxError'))
            .when('lng', {
              is: (lng?: string) => !!lng,
              then: Yup.number()
                .transform(parseNumericString)
                .nullable()
                .required(t('addIncidentReport.inputs.latitude.required')),
              otherwise: Yup.number().transform(parseNumericString).nullable(),
            }),
          lng: Yup.number()
            .typeError(t('addIncidentReport.inputs.longitude.typeError'))
            .min(-180, t('addIncidentReport.inputs.longitude.minError'))
            .max(180, t('addIncidentReport.inputs.longitude.maxError'))
            .when('lat', {
              is: (lat?: string) => !!lat,
              then: Yup.number()
                .transform(parseNumericString)
                .nullable()
                .required(t('addIncidentReport.inputs.longitude.required')),
              otherwise: Yup.number().transform(parseNumericString).nullable(),
            }),
          az: Yup.number()
            .typeError(t('addIncidentReport.inputs.azimuth.typeError'))
            .min(0, t('addIncidentReport.inputs.azimuth.minError'))
            .max(360, t('addIncidentReport.inputs.azimuth.maxError')),
          embedUrl: Yup.string()
            .url(t('addIncidentReport.inputs.embedUrl.invalidError'))
            .test(
              'is-supported-embed-url',
              t('addIncidentReport.inputs.embedUrl.unsupportedError'),
              (value) => (value ? isSupportedEmbedUrl(value) : true),
            ),
        },
        [['lat', 'lng']],
      ),
    [t],
  );

  const initialValues: FormValues = useMemo(
    () => ({
      geoEventId: geoEvent.id,
      message: report.message,
      asset: getAssetInitialValue(report.media?.[0], report.embedUrl),
      mediaUrl: report.media?.[0]?.url || '',
      lat: report.media?.[0]?.lat?.toString() || '',
      lng: report.media?.[0]?.lng?.toString() || '',
      az: report.media?.[0]?.az || 0,
      embedUrl: report.embedUrl || '',
    }),
    [geoEvent.id, report.embedUrl, report.media, report.message],
  );

  const formMethods = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
    mode: 'onBlur',
  });

  const { control, handleSubmit, reset, formState, watch } = formMethods;

  useEffect(() => {
    // Initial values changed, reset the form to reflect these changes.
    reset(initialValues);
  }, [reset, initialValues]);

  const goBack = (): void => {
    history.push(`/i/${geoEvent.id}`);
  };

  const patchReportMutation = useMutation({
    mutationFn: (data: ReportUpdateData) => updateReport(report.id, data),
  });

  const deleteMediaMutation = useMutation({
    mutationFn: () => deleteMedia(report.media[0].id),
  });

  const handleSubmitReport: SubmitHandler<FormValues> = async (values) => {
    try {
      const updateData: ReportUpdateData = { message: values.message };

      if (report.embedUrl) {
        updateData.embedUrl = values.embedUrl;
      }

      if (report.media?.[0]?.id) {
        if (values.mediaUrl) {
          updateData.media = [
            {
              ...report.media[0],
              lat: parseFloat(values.lat),
              lng: parseFloat(values.lng),
              az: values.az,
            },
          ];
        } else if (!values.mediaUrl) {
          updateData.media = [];
          await deleteMediaMutation.mutateAsync();
        }
      }

      await patchReportMutation.mutateAsync(updateData);
      queryClient.invalidateQueries({
        queryKey: ['report', report.id],
      });
      setSnackbar(t('editReport.successMessage'), 'success');
      goBack();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      const errorStatus = error?.response?.status;
      if (errorStatus === 403) {
        setSnackbar(
          'Error 403: You cannot submit or update reports for this incident.',
          'error',
        );
      } else
        setSnackbar(error.message || t('common.unknownErrorTryAgain'), 'error');
    }
  };

  const mediaUrl = watch('mediaUrl');
  const disabled = patchReportMutation.isPending;

  return (
    <Box
      className={classes.root}
      sx={{
        flexDirection: isLargeMediaQuery ? 'row' : 'column',
        overflow: isLargeMediaQuery ? 'hidden' : 'auto',
      }}
    >
      <Box
        sx={{
          flex: 1,
          minHeight: isLargeMediaQuery ? '100%' : 314,
          display: 'flex',
        }}
      >
        <GeoEventReportsMap
          geoEvent={geoEvent}
          hiddenMapPixels={null}
          noControls
        />
      </Box>
      <Container
        maxWidth={false}
        className={isLargeMediaQuery ? classes.formContainer : undefined}
      >
        <FormProvider {...formMethods}>
          <form
            onSubmit={handleSubmit(handleSubmitReport)}
            noValidate
            className={classes.form}
          >
            <Grid container spacing={2} direction="column">
              <Grid item>
                <Box sx={{ paddingTop: 1 }}>
                  <Typography variant="h3">
                    {t('editReport.sections.incident')}
                  </Typography>
                </Box>
              </Grid>

              <Grid item>
                <Controller
                  name="geoEventId"
                  control={control}
                  render={({ field, fieldState }): JSX.Element => {
                    const { ref, ...muiFieldProps } = field;
                    return (
                      <TextField
                        select
                        id="field-control-incident"
                        label={t('editReport.inputs.incident.label')}
                        fullWidth
                        {...muiFieldProps}
                        inputRef={ref}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        required
                        className={classes.itemLabel}
                        disabled
                      >
                        <MenuItem
                          value={geoEvent.id}
                          className={classes.itemLabel}
                        >
                          {geoEvent.name}
                        </MenuItem>
                      </TextField>
                    );
                  }}
                />
              </Grid>

              <Grid item>
                <Box sx={{ paddingTop: 1 }}>
                  <Typography variant="h3">
                    {t('editReport.sections.update')}
                  </Typography>
                </Box>
              </Grid>

              <Grid item>
                <Controller
                  name="message"
                  control={control}
                  render={({ field, fieldState }): JSX.Element => {
                    const reportMessageWarning = validateReport({
                      t,
                      report: removeHTMLTags(field.value),
                      fpsChecked: geoEvent.data.isFps,
                      isSilent:
                        geoEvent.notificationType === NotificationType.Silent,
                    });
                    return (
                      <>
                        <RichTextEditor
                          id="field-control-message"
                          key={initialValues.message}
                          label={t('editReport.inputs.message.label')}
                          initialValue={initialValues.message}
                          onChange={field.onChange}
                          editable={!formState.isSubmitting}
                          error={!!fieldState.error}
                          required
                          warning={!!reportMessageWarning}
                        />
                        {!!(fieldState.error || reportMessageWarning) && (
                          <FormHelperText
                            error={!!fieldState.error}
                            sx={{ marginLeft: 1.75, marginRight: 1.75 }}
                          >
                            {fieldState.error?.message || reportMessageWarning}
                          </FormHelperText>
                        )}
                      </>
                    );
                  }}
                />
              </Grid>

              {mediaUrl || report.embedUrl ? (
                <MediaForm
                  geoEvent={geoEvent}
                  report={report}
                  latFieldName="lat"
                  lngFieldName="lng"
                  azFieldName="az"
                />
              ) : null}

              <Grid item>
                <Box sx={{ paddingTop: 3 }}>
                  <Typography variant="h3">
                    {t('editReport.sections.preview')}
                  </Typography>
                </Box>
              </Grid>

              <Grid item>
                <Typography align="center">
                  {t('editReport.silentNotification')}
                </Typography>
              </Grid>

              <Grid item>
                <Button
                  type="submit"
                  fullWidth
                  size="large"
                  disabled={disabled}
                >
                  {patchReportMutation.isPending ? (
                    <CircularProgress size={24} />
                  ) : (
                    t('editReport.buttons.save')
                  )}
                </Button>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </Container>
    </Box>
  );
};

export default EditReportForm;
