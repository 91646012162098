import { useEffect, useState } from 'react';
import { Button, Grid, Box, Paper, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { getDeviceInfo } from 'state/localStorageTyped';
import WarningIcon from '@mui/icons-material/WarningTwoTone';
import {
  AppUpdate,
  AppUpdateAvailability,
} from '@capawesome/capacitor-app-update';
import { AppStoreLinks } from '../../../constants';
import HeaderBar from '../../../components/HeaderBar';
import PageLayout from '../../../components/PageLayout';

const isUpgradeAvailable = async (): Promise<boolean> => {
  if (import.meta.env.VITE_SHOW_UPGRADE_MESSAGE === 'true') {
    return true;
  }

  const deviceInfo = getDeviceInfo();
  if (!deviceInfo || deviceInfo.isWeb) {
    return false;
  }

  const updateInfo = await AppUpdate.getAppUpdateInfo();
  return (
    updateInfo.updateAvailability === AppUpdateAvailability.UPDATE_AVAILABLE
  );
};

const useStyles = makeStyles()((theme) => ({
  paper: {
    padding: theme.spacing(2),
    margin: '0 auto',
    overflowY: 'auto',
    width: '100%',
    maxWidth: '1000px',
  },
  centerContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
}));

export const Content = (): JSX.Element => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const [hasUpgrade, setHasUpgrade] = useState(false);
  useEffect(() => {
    const getUpgradeInternal = async (): Promise<void> => {
      const result = await isUpgradeAvailable();
      setHasUpgrade(result);
    };
    getUpgradeInternal();
  }, []);
  const deviceInfo = getDeviceInfo();
  const storeLink =
    deviceInfo?.operatingSystem === 'android'
      ? AppStoreLinks.android
      : AppStoreLinks.ios;
  const description = hasUpgrade
    ? `${t('pageNotFound.description')} ${t('pageNotFound.updateAppMessage')}`
    : t('pageNotFound.description');
  return (
    <>
      <Helmet>
        <title>{t('pageNotFound.title')}</title>
      </Helmet>

      <Paper elevation={0} className={classes.paper}>
        <Grid
          container
          spacing={2}
          sx={{
            justifyContent: 'center',
            alignContent: 'center',
            height: '100%',
          }}
        >
          <Grid item xs={12}>
            <Box className={classes.centerContainer}>
              <WarningIcon
                color="error"
                sx={{ fontSize: 'min(10rem, 40vw)' }}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box className={classes.centerContainer}>
              <Typography variant="h1">{t('pageNotFound.title')}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box className={classes.centerContainer}>
              <Typography>{description}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <Button
              fullWidth
              size="large"
              href={hasUpgrade ? storeLink : '/'}
              target={hasUpgrade ? '_blank' : '_self'}
              sx={{ maxWidth: '700px' }}
            >
              {hasUpgrade
                ? t('pageNotFound.buttons.update')
                : t('pageNotFound.buttons.home')}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

const PageNotFound = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <PageLayout data-cname="NotFoundPage">
      {{
        content: <Content />,
        headerBar: <HeaderBar action="/" title={t('pageNotFound.title')} />,
      }}
    </PageLayout>
  );
};

export default PageNotFound;
