import { useQuery } from '@tanstack/react-query';
import { useHistory } from 'react-router-dom';
import { Report } from 'shared/types';
import Map from 'components/Map';
import { API } from 'api';
import GeoEventsLayer from 'components/Map/layers/GeoEventsLayer';
import { VisuallyBusyMapLayers } from 'components/Map/constants';
import { useMapState } from 'state';
import { AxiosResponse } from 'axios';
import { useGeoEvents } from 'hooks/useGeoEvents';

const fetchReports = (): Promise<AxiosResponse<Report[]>> =>
  API.get<Report[]>('reports/', {
    params: {
      isModerated: false,
      isActive: true,
      hasLatLng: true,
    },
  });

const ModerationMap = (): JSX.Element => {
  const { incidentMapState } = useMapState();
  const history = useHistory();

  const reportsQuery = useQuery({
    queryKey: ['reports'],
    queryFn: fetchReports,
  });

  const { wildfireEvents: geoEvents } = useGeoEvents();

  const reports = reportsQuery.data?.data || [];

  const handleClickReport = (reportId: number): void => {
    history.push(`/reports/${reportId}/moderate`);
  };

  return (
    <Map
      center={incidentMapState.center}
      zoom={incidentMapState.zoom}
      disableMapLayers={VisuallyBusyMapLayers}
    >
      {geoEvents?.length && <GeoEventsLayer geoEvents={geoEvents} isFadable />}

      {reports?.map((report: Report) => (
        <Map.ReportMarker
          key={report.id}
          media={report.media}
          onClick={() => handleClickReport(report.id)}
          isFadable={false}
          lat={report.lat}
          lng={report.lng}
        />
      ))}
    </Map>
  );
};

export default ModerationMap;
