import { SliderOwnProps, Stack } from '@mui/material';
import { usePopupState } from 'material-ui-popup-state/hooks';
import { isPhone } from 'shared/utils';
import { useAlertCameraPlayerState } from 'hooks/useAlertCameraPlayer';
import { AlertCamera } from 'hooks/useAlertCameras';
import { CameraImage } from './CameraImage';
import { CameraPreview } from './CameraPreview';
import { CameraPlayerControls } from './CameraPlayerControls';
import { CameraReplayTimeMenu } from './CameraReplayTimeMenu';
import { CameraPlayerFullScreenControls } from './CameraPlayerFullScreenControls';
import { CameraPlayerControlCommonProps } from './CameraPlayerControl.types';

type CameraPlayerProps = {
  camera: AlertCamera;
  fullScreen?: boolean;
  showControls?: boolean;
};

const CameraPlayer = (props: CameraPlayerProps): JSX.Element => {
  const { camera, fullScreen = false, showControls = true } = props;
  const {
    playerStatus,
    timelapseFrameNumber,
    timelapseFramesLength,
    timelapseReplayTime,
    imageUrl,
    imageTimestamp,
    isLoading,
    isLoadingImage,
    setTimelapseFrameNumber,
    playTimelapse,
    pauseTimelapse,
    stopTimelapse,
    setTimelapseReplayTime,
  } = useAlertCameraPlayerState(camera);
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'playTimeMenu',
  });

  const handleTogglePlay = async (): Promise<void> => {
    if (playerStatus === 'playingTimelapse') {
      pauseTimelapse();
      return;
    }
    playTimelapse();
  };

  const handleStopTimelapse = async (): Promise<void> => {
    stopTimelapse();
  };

  const handleSliderChange: SliderOwnProps['onChange'] = async (
    _,
    value,
  ): Promise<void> => {
    pauseTimelapse();
    setTimelapseFrameNumber(value as number);
  };

  const mobileFullScreen = isPhone() && fullScreen;

  const cameraControlCommonProps: CameraPlayerControlCommonProps = {
    camera,
    imageTimestamp,
    isLoading,
    playerStatus,
    popupState,
    timelapseFramesLength,
    timelapseFrameNumber,
    timelapseReplayTime,
    handleSliderChange,
    handleTogglePlay,
  };

  return (
    <>
      <Stack
        spacing={0}
        sx={{
          width: mobileFullScreen ? '100%' : undefined,
          height: mobileFullScreen ? '100%' : undefined,
        }}
      >
        {playerStatus === 'streamingLive' ? (
          <CameraPreview
            url={imageUrl}
            loading={isLoading || isLoadingImage}
            mobileFullScreen={mobileFullScreen}
          />
        ) : (
          <CameraImage
            url={imageUrl}
            loading={isLoading || isLoadingImage}
            mobileFullScreen={mobileFullScreen}
          />
        )}

        {mobileFullScreen ? (
          showControls && (
            <CameraPlayerFullScreenControls {...cameraControlCommonProps} />
          )
        ) : (
          <CameraPlayerControls
            {...cameraControlCommonProps}
            fullScreen={fullScreen}
            handleStopTimelapse={handleStopTimelapse}
          />
        )}
      </Stack>

      <CameraReplayTimeMenu
        camera={camera}
        isLoading={isLoading}
        fullScreen={fullScreen}
        popupState={popupState}
        setTimelapseReplayTime={setTimelapseReplayTime}
      />
    </>
  );
};

export default CameraPlayer;
