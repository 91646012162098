import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Container,
  Grid,
  Typography,
  TextField,
  Button,
  Box,
  Alert,
  List,
  ListItem,
  ListItemText,
  Link as MuiLink,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useRecurly } from '@recurly/react-recurly';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { UseMutationResult } from '@tanstack/react-query';
import * as Sentry from '@sentry/capacitor';
import { TFunction } from 'i18next';
import { LoadingAndErrors } from 'components/LoadingAndErrors';
import {
  ProcessDonationPaymentTokenData,
  ProcessInitialDonationPaymentTokenData,
  ProcessPaymentState,
  ProcessMembershipPaymentTokenData,
  SavePaymentMethodData,
  MembershipConfig,
} from 'shared/types';
import { formatToUSDollars } from 'shared/utils';
import RecurlyCardElement from './RecurlyCardElement';
import { getButtonTitle, getCreditCardValidationErrors } from './utils';
import { parseRecurlyErrorToTransKey } from '../../shared/recurlyUtils';

type RecurlyBillingInformationProps<T> = {
  transactionType: 'membership' | 'donation';
  initialState: ProcessPaymentState;
  processPaymentMutation: UseMutationResult<void, Error, T, unknown>;
  savePaymentMethodMutation?: UseMutationResult<
    void,
    Error,
    SavePaymentMethodData,
    unknown
  >;
  membershipConfig?: MembershipConfig;
};

type FormValues = {
  postalCode: string;
};

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
    overflowY: 'auto',
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(3),
    paddingBottom: 'max(env(safe-area-inset-bottom), 16px)',
  },
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  alert: {
    backgroundColor: '#FDECEA',
    color: '#E25345',
  },
}));

// regex to validate us postal codes
const postalCodeRegex = /^[0-9]{5}$/;

const getValidationSchema = (t: TFunction): Yup.SchemaOf<FormValues> =>
  Yup.object({
    postalCode: Yup.string()
      .required(t('recurlyBillingInformation.inputs.postalCode.required'))
      .matches(
        postalCodeRegex,
        t('recurlyBillingInformation.inputs.postalCode.invalid'),
      ),
  });

const initialValues = {
  postalCode: '',
};

const RecurlyBillingInformation = <
  T extends
    | ProcessMembershipPaymentTokenData
    | ProcessInitialDonationPaymentTokenData
    | ProcessDonationPaymentTokenData,
>(
  props: RecurlyBillingInformationProps<T>,
): JSX.Element => {
  const {
    transactionType,
    initialState,
    processPaymentMutation,
    savePaymentMethodMutation,
    membershipConfig,
  } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();
  const recurly = useRecurly();
  const { control, handleSubmit } = useForm<FormValues>({
    resolver: yupResolver(getValidationSchema(t)),
    defaultValues: initialValues,
    mode: 'onBlur',
  });
  const [errors, setErrors] = useState<string[]>([]);
  const [alreadyRegistered, setAlreadyRegistered] = useState(false);

  const isMembershipTransaction = transactionType === 'membership';
  const {
    email,
    firstName,
    lastName,
    clientToken,
    optInToUpdates,
    transactionAmount,
    donationRenews,
    verificationToken,
    userId,
    updatePaymentMethod,
  } = initialState;

  const { mutate, isPending } = processPaymentMutation;

  const handleSubmitError = (
    err: Error | { response: { data: (string | { email: string[] })[] } },
  ): void => {
    const transKey = parseRecurlyErrorToTransKey(err);
    if (transKey === 'common.alreadyAMember') {
      setAlreadyRegistered(true);
    } else {
      setErrors([t(transKey)]);
    }
  };

  const processMembershipPayment = (token: string): void => {
    if (!clientToken || !membershipConfig?.code) return;

    const data: ProcessMembershipPaymentTokenData = {
      authorizationToken: token,
      email,
      firstName,
      lastName,
      planCode: membershipConfig.code,
      clientToken,
      optInToUpdates,
    };

    mutate(data as T, {
      onError: (err) => {
        handleSubmitError(err);
      },
    });
  };

  const processInitialDonationPayment = (token: string): void => {
    if (!clientToken) return;

    const data: ProcessInitialDonationPaymentTokenData = {
      clientToken,
      donationAmount: transactionAmount,
      donationRenews: !!donationRenews,
      firstName,
      lastName,
      email,
      optInToUpdates,
      authorizationToken: token,
    };

    mutate(data as T, {
      onError: (err) => {
        handleSubmitError(err);
      },
    });
  };

  const processReturningDonationPayment = (token: string): void => {
    if (!userId || !verificationToken) return;

    const paymentData: ProcessDonationPaymentTokenData = {
      userId,
      verificationToken,
      donationAmount: transactionAmount,
      donationRenews: !!donationRenews,
      authorizationToken: token,
    };

    mutate(paymentData as T, {
      onError: (err) => {
        handleSubmitError(err);
      },
    });
  };

  const savePaymentMethod = (token: string): void => {
    if (!savePaymentMethodMutation || !userId || !verificationToken) {
      return;
    }

    const data: SavePaymentMethodData = {
      userId,
      verificationToken,
      authorizationToken: token,
    };

    savePaymentMethodMutation.mutate(data, {
      onError: (err) => {
        handleSubmitError(err);
      },
    });
  };

  const submitToken = (token: string): void => {
    if (updatePaymentMethod) {
      savePaymentMethod(token);
      return;
    }

    if (isMembershipTransaction) {
      processMembershipPayment(token);
      return;
    }

    if (clientToken) {
      processInitialDonationPayment(token);
      return;
    }

    if (userId && verificationToken) {
      processReturningDonationPayment(token);
    }
  };

  const handleFormSubmit = (values: FormValues): void => {
    recurly.token(
      {
        first_name: firstName,
        last_name: lastName,
        postal_code: values.postalCode,
      },
      (err, token) => {
        if (err) {
          const validationErrors = getCreditCardValidationErrors(err, t);
          setErrors(validationErrors);
          Sentry.captureException(err);
          return;
        }

        submitToken(token.id);
      },
    );
  };

  const hasErrors = errors.length > 0;

  if (isPending || savePaymentMethodMutation?.isPending) {
    return <LoadingAndErrors isLoading />;
  }

  return (
    <div className={classes.root}>
      <Container
        maxWidth="sm"
        className={classes.container}
        component="form"
        noValidate
        onSubmit={handleSubmit(handleFormSubmit)}
      >
        <Grid
          container
          spacing={2}
          direction="column"
          sx={{ flex: 1, marginBottom: 3 }}
        >
          <Grid item>
            <Typography variant="h3">
              <b>{t('recurlyBillingInformation.title')}</b>
            </Typography>
          </Grid>

          {alreadyRegistered && (
            <Grid item>
              <Alert icon={false} severity="error" className={classes.alert}>
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: 'medium',
                    marginBottom: '16px',
                  }}
                >
                  {t('recurlyBillingInformation.registeredEmail')}
                </Typography>

                <MuiLink
                  to="/login"
                  component={Link}
                  color="inherit"
                  underline="always"
                  variant="body2"
                  sx={{
                    fontWeight: 'bold',
                  }}
                >
                  {t('recurlyBillingInformation.login')}
                </MuiLink>
              </Alert>
            </Grid>
          )}

          {hasErrors && (
            <Grid item>
              <Alert icon={false} severity="error" className={classes.alert}>
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: 'medium',
                  }}
                >
                  {t('recurlyBillingInformation.error')}
                </Typography>
                <List
                  dense
                  disablePadding
                  sx={{ listStyleType: 'disc', pl: 3 }}
                >
                  {errors.map((error) => (
                    <ListItem
                      key={error}
                      dense
                      disablePadding
                      sx={{ display: 'list-item' }}
                    >
                      <ListItemText
                        primary={error}
                        primaryTypographyProps={{
                          variant: 'body2',
                          fontWeight: 'bold',
                        }}
                        sx={{ margin: 0 }}
                      />
                    </ListItem>
                  ))}
                </List>
              </Alert>
            </Grid>
          )}

          <Grid item>
            <RecurlyCardElement error={hasErrors} />
          </Grid>

          <Grid item>
            <Grid item container>
              <Grid item xs={6}>
                <Controller
                  name="postalCode"
                  control={control}
                  render={({ field, fieldState }): JSX.Element => {
                    const { ref, ...muiFieldProps } = field;
                    return (
                      <TextField
                        id="field-control-postalCode"
                        label={t(
                          'recurlyBillingInformation.inputs.postalCode.label',
                        )}
                        fullWidth
                        type="number"
                        {...muiFieldProps}
                        inputRef={ref}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        required
                        slotProps={{
                          htmlInput: { pattern: '\\d*' },
                        }}
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>
          </Grid>

          {isMembershipTransaction && !updatePaymentMethod && (
            <>
              {membershipConfig?.title && (
                <Grid item>
                  <Box sx={{ marginTop: 1 }}>
                    <Typography variant="h3">
                      <b>{membershipConfig.title}</b>
                    </Typography>
                  </Box>
                </Grid>
              )}

              {membershipConfig?.description && (
                <Grid item>
                  <Typography
                    sx={{
                      fontWeight: 'medium',
                    }}
                  >
                    {membershipConfig.description}
                  </Typography>
                </Grid>
              )}
            </>
          )}

          {!updatePaymentMethod && (
            <Grid item>
              <Typography
                sx={{
                  fontWeight: 'bold',
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: isMembershipTransaction ? 0 : 2,
                }}
              >
                <span>{t('recurlyBillingInformation.total')}</span>
                <span>
                  {formatToUSDollars(
                    transactionAmount,
                    isMembershipTransaction ? 2 : 0,
                  )}
                  {!isMembershipTransaction && donationRenews && (
                    <Typography component="span" color="secondary">
                      &nbsp;{t('donate.perMonth')}
                    </Typography>
                  )}
                </span>
              </Typography>
            </Grid>
          )}
        </Grid>

        <Button fullWidth size="large" type="submit">
          {getButtonTitle(
            isMembershipTransaction,
            !!updatePaymentMethod,
            transactionAmount,
            !!donationRenews,
            t,
          )}
        </Button>
      </Container>
    </div>
  );
};

export default RecurlyBillingInformation;
