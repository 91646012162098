import {
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

type ElectricRetailDialogContentProps = {
  customers: number;
  name: string;
};

const useStyles = makeStyles()((theme) => ({
  cell: {
    border: 0,
    padding: `0 16px 4px 0`,
    width: '50%',
  },
}));

const ElectricRetailDialogContent = (
  props: ElectricRetailDialogContentProps,
): JSX.Element => {
  const { customers, name } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <DialogTitle id="alert-dialog-title">
        <Typography
          component="span"
          variant="body2"
          sx={{
            fontWeight: 'bold',
            textTransform: 'capitalize',
          }}
        >
          {name.toLowerCase()}
        </Typography>
        <Table>
          <TableBody>
            <TableRow key={name}>
              <TableCell component="th" scope="row" className={classes.cell}>
                <Typography
                  variant="body2"
                  color="secondary"
                  sx={{
                    textTransform: 'uppercase',
                  }}
                >
                  {t('common.customers')}
                </Typography>
              </TableCell>
              <TableCell className={classes.cell}>
                <Typography variant="body2">
                  {customers > 0 ? customers.toLocaleString('en-US') : '-'}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </DialogTitle>
    </>
  );
};

export default ElectricRetailDialogContent;
