import {
  Box,
  Grid,
  Theme,
  Alert,
  AlertTitle,
  Typography,
  Stack,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import { GeoEvent } from 'shared/types';
import RichTextContent from 'components/RichTextContent';
import {
  EVAC_ZONE_LABEL_TRANS_KEYS,
  EVAC_ZONE_LAYER_COLORS,
} from '../../../../constants';
import { getEvacZoneStyleFromGeoEvent } from '../../../../shared/utils';

type GeoEventEvacInfoProps = {
  geoEvent: GeoEvent;
};

export const EVAC_ZONE_BACKGROUND_COLORS = {
  order_warning_advisory: {
    orders: '#FDECEA',
    warnings: '#FFF2BB',
    advisories: '#E8F2FD',
  },
  ready_set_go: {
    orders: '#FDECEA',
    warnings: '#FFF2BB',
    advisories: '#D2F4CD',
  },
  ready_set_go_with_levels: {
    orders: '#FDECEA',
    warnings: '#FFF2BB',
    advisories: '#D2F4CD',
  },
  ready_set_go_reverse_colors: {
    orders: '#D2F4CD',
    warnings: '#FFF2BB',
    advisories: '#FDECEA',
  },
} as const;

const useStyles = makeStyles()((theme: Theme) => ({
  alert: {
    paddingBottom: 0,
    color: theme.palette.text.primary,
    '& .MuiAlert-message': {
      paddingBottom: 0,
    },
    '& .MuiAlert-icon': {
      paddingTop: 10,
    },
  },
  icon: {
    width: 16,
    height: 16,
    borderRadius: 8,
    border: '1px solid #ffffff',
  },
}));

const AlertIcon = (props: { color: string }): JSX.Element => {
  const { color } = props;
  const { classes } = useStyles();
  return <div className={classes.icon} style={{ backgroundColor: color }} />;
};

const GeoEventEvacInfo = (props: GeoEventEvacInfoProps): JSX.Element | null => {
  const { geoEvent } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();
  const {
    data: {
      evacuationOrders,
      evacuationWarnings,
      evacuationAdvisories,
      evacuationNotes,
    },
  } = geoEvent;

  const evacZoneStyle = getEvacZoneStyleFromGeoEvent(geoEvent);

  if (
    !evacuationOrders &&
    !evacuationWarnings &&
    !evacuationNotes &&
    !evacuationAdvisories
  ) {
    return null;
  }

  return (
    <Grid container spacing={2} data-testid="evacInfo">
      {evacuationOrders && (
        <Grid item xs={12}>
          <Alert
            severity="error"
            className={classes.alert}
            icon={false}
            sx={{
              backgroundColor:
                EVAC_ZONE_BACKGROUND_COLORS[evacZoneStyle].orders,
            }}
          >
            <AlertTitle>
              <Stack
                direction="row"
                spacing={1}
                sx={{
                  alignItems: 'center',
                }}
              >
                <AlertIcon
                  color={EVAC_ZONE_LAYER_COLORS[evacZoneStyle].orders}
                />
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: 'bold',
                  }}
                >
                  {t(EVAC_ZONE_LABEL_TRANS_KEYS[evacZoneStyle].orders)}
                </Typography>
              </Stack>
            </AlertTitle>
            <Box sx={{ marginTop: -1 }}>
              <RichTextContent content={evacuationOrders} />
            </Box>
          </Alert>
        </Grid>
      )}

      {evacuationWarnings && (
        <Grid item xs={12}>
          <Alert
            severity="warning"
            className={classes.alert}
            icon={false}
            sx={{
              backgroundColor:
                EVAC_ZONE_BACKGROUND_COLORS[evacZoneStyle].warnings,
            }}
          >
            <AlertTitle>
              <Stack
                direction="row"
                spacing={1}
                sx={{
                  alignItems: 'center',
                }}
              >
                <AlertIcon
                  color={EVAC_ZONE_LAYER_COLORS[evacZoneStyle].warnings}
                />
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: 'bold',
                  }}
                >
                  {t(EVAC_ZONE_LABEL_TRANS_KEYS[evacZoneStyle].warnings)}
                </Typography>
              </Stack>
            </AlertTitle>
            <Box sx={{ marginTop: -1 }}>
              <RichTextContent content={evacuationWarnings} />
            </Box>
          </Alert>
        </Grid>
      )}

      {evacuationAdvisories && (
        <Grid item xs={12}>
          <Alert
            severity="success"
            className={classes.alert}
            icon={false}
            sx={{
              backgroundColor:
                EVAC_ZONE_BACKGROUND_COLORS[evacZoneStyle].advisories,
            }}
          >
            <AlertTitle>
              <Stack
                direction="row"
                spacing={1}
                sx={{
                  alignItems: 'center',
                }}
              >
                <AlertIcon
                  color={EVAC_ZONE_LAYER_COLORS[evacZoneStyle].advisories}
                />
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: 'bold',
                  }}
                >
                  {t(EVAC_ZONE_LABEL_TRANS_KEYS[evacZoneStyle].advisories)}
                </Typography>
              </Stack>
            </AlertTitle>
            <Box sx={{ marginTop: -1 }}>
              <RichTextContent content={evacuationAdvisories} />
            </Box>
          </Alert>
        </Grid>
      )}

      {evacuationNotes && (
        <Grid item xs={12}>
          <Alert
            className={classes.alert}
            icon={false}
            sx={{ backgroundColor: '#FFF4E5E8' }}
          >
            <AlertTitle>
              <Typography
                variant="body2"
                sx={{
                  fontWeight: 'bold',
                }}
              >
                {t('evacZones.notes')}
              </Typography>
            </AlertTitle>
            <Box sx={{ marginTop: -1 }}>
              <RichTextContent content={evacuationNotes} />
            </Box>
          </Alert>
        </Grid>
      )}
    </Grid>
  );
};

export default GeoEventEvacInfo;
