import { Capacitor } from '@capacitor/core';
import { Share } from '@capacitor/share';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbarState } from 'state';

type ShareValues = {
  androidDialogTitle?: string;
  title?: string;
  showClipboardSuccessMessage?: boolean;
};

type ShareTextValues = ShareValues & {
  text: string;
};

type ShareUrlValues = ShareValues & {
  text?: string;
  ts?: Date;
  url: string;
};

type UseClipboard = {
  canShareOrCopy: boolean;
  copied: boolean;
  reset: () => void;
  shareOrCopyText: (values: ShareTextValues) => Promise<void>;
  shareOrCopyUrl: (values: ShareUrlValues) => Promise<void>;
};

export const ABORT_SHARE_ERROR_STRING = 'Share canceled';

/**
 * A utility hook to share text or a URL, either by copying it to the clipboard
 * on web or using the share dialog on native.
 */
export const useShareOrCopy = (): UseClipboard => {
  const { t } = useTranslation();
  const [copied, setCopied] = useState(false);
  const { setSnackbar } = useSnackbarState();

  const shareOrCopyText = async (values: ShareTextValues): Promise<void> => {
    const {
      androidDialogTitle,
      showClipboardSuccessMessage = true,
      text,
      title,
    } = values;

    if (!Capacitor.isNativePlatform()) {
      navigator.clipboard.writeText(text);
      setCopied(true);
      if (showClipboardSuccessMessage) {
        setSnackbar(t('common.copiedToClipboard'), 'success');
      }
      return;
    }

    try {
      await Share.share({
        text,
        title,
        dialogTitle: androidDialogTitle,
      });
    } catch (error) {
      // iOS will throw an error if aborted
      if ((error as Error)?.message === ABORT_SHARE_ERROR_STRING) {
        return;
      }

      setSnackbar(t('common.unknownErrorTryAgain'), 'error');
    }
  };

  const shareOrCopyUrl = async (values: ShareUrlValues): Promise<void> => {
    const {
      androidDialogTitle,
      title,
      showClipboardSuccessMessage = true,
      url,
    } = values;

    if (!Capacitor.isNativePlatform()) {
      navigator.clipboard.writeText(url);
      setCopied(true);
      if (showClipboardSuccessMessage) {
        setSnackbar(t('common.copiedLinkToClipboard'), 'success');
      }
      return;
    }

    try {
      await Share.share({
        title,
        url,
        dialogTitle: androidDialogTitle,
      });
    } catch (error) {
      // iOS will throw an error if aborted
      if ((error as Error)?.message === ABORT_SHARE_ERROR_STRING) {
        return;
      }

      setSnackbar(t('common.errorMessage'), 'error');
    }
  };

  return {
    canShareOrCopy:
      Capacitor.isNativePlatform() || Boolean(navigator.clipboard),
    copied,
    reset: () => {
      setCopied(false);
    },
    shareOrCopyText,
    shareOrCopyUrl,
  };
};
