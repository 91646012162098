import { Backdrop, Box, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useTranslation, Trans } from 'react-i18next';
import WhiteButton from 'components/WhiteButton';
import useOnboardingState from 'state/useOnboardingState';
import useMapLayersState from 'state/useMapLayersState';
import useDrawerNavState from 'state/useDrawerNavState';
import VectorSettings from './VectorSettings';
import VectorLayers from './VectorLayers';
import VectorLegends from './VectorLegends';

const useStyles = makeStyles()((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer,
    backgroundColor: 'rgba(0, 0, 0, 0.25)',
  },
  container: {
    width: '100%',
    height: '100%',
    padding: theme.spacing(2),
    paddingBottom: 'max(env(safe-area-inset-bottom), 16px)',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    position: 'relative',
  },
  button: {
    [theme.breakpoints.up(480)]: {
      maxWidth: 350,
    },
  },
  settingsContainer: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'flex-end',
    top: 'calc(40px + env(safe-area-inset-top))',
    left: 'calc(4px + env(safe-area-inset-left))',
  },
  settingsTitle: {
    color: 'black',
    fontWeight: 500,
    lineHeight: '22px',
    marginBottom: -12,
    marginLeft: 8,
    textShadow:
      '2px 0 #fff, -2px 0 #fff, 0 2px #fff, 0 -2px #fff, 1px 1px #fff, -1px -1px #fff, 1px -1px #fff, -1px 1px #fff',
  },
  settingsButton: {
    width: 48,
    height: 48,
    position: 'absolute',
    top: -36,
    left: 4,
    pointerEvents: 'auto',
  },
  layersContainer: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    top: 'calc(110px + env(safe-area-inset-top))',
    right: 80,
  },
  layersTitle: {
    color: 'black',
    fontWeight: 500,
    lineHeight: '22px',
    marginRight: 24,
    textShadow:
      '2px 0 #fff, -2px 0 #fff, 0 2px #fff, 0 -2px #fff, 1px 1px #fff, -1px -1px #fff, 1px -1px #fff, -1px 1px #fff',
  },
  layersButton: {
    width: 56,
    height: 56,
    position: 'absolute',
    top: -36,
    right: -64,
    pointerEvents: 'auto',
  },
  legendsContainer: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'flex-end',
    top: 'calc(284px + env(safe-area-inset-top))',
    right: 24,
  },
  legendsTitle: {
    color: 'black',
    fontWeight: 500,
    lineHeight: '22px',
    marginBottom: -8,
    textShadow:
      '2px 0 #fff, -2px 0 #fff, 0 2px #fff, 0 -2px #fff, 1px 1px #fff, -1px -1px #fff, 1px -1px #fff, -1px 1px #fff',
  },
  legendsButton: {
    width: 56,
    height: 56,
    position: 'absolute',
    top: -62,
    right: -10,
    pointerEvents: 'auto',
  },
}));

const OnboardingWalkMe = (): JSX.Element | null => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { showTutorialSteps, setShowTutorialSteps } = useOnboardingState();
  const { setDrawerOpen } = useMapLayersState();
  const { toggleOpen } = useDrawerNavState();

  if (!showTutorialSteps) {
    return null;
  }

  const handleSettingsClick = (): void => {
    setShowTutorialSteps(false);
    toggleOpen();
  };

  const handleLayersClick = (): void => {
    setShowTutorialSteps(false);
    setDrawerOpen(true, 'layers');
  };

  const handleLegendsClick = (): void => {
    setShowTutorialSteps(false);
    setDrawerOpen(true, 'legends');
  };

  return (
    <Backdrop open className={classes.backdrop} data-testid="walk-me-backdrop">
      <div className={classes.container}>
        <Box data-testid="settings-step" className={classes.settingsContainer}>
          <VectorSettings />
          <Typography variant="body1" className={classes.settingsTitle}>
            <Trans i18nKey="onboardingTooltip.settings">
              Settings &
              <br />
              Notifications
            </Trans>
          </Typography>
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label, jsx-a11y/click-events-have-key-events */}
          <div
            className={classes.settingsButton}
            role="button"
            tabIndex={0}
            onClick={handleSettingsClick}
          />
        </Box>

        <Box data-testid="layers-step" className={classes.layersContainer}>
          <VectorLayers />
          <Typography variant="body1" className={classes.layersTitle}>
            <Trans i18nKey="onboardingTooltip.layers">
              Map Layers:
              <br />
              flight tracker,
              <br />
              weather & more
            </Trans>
          </Typography>
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label, jsx-a11y/click-events-have-key-events */}
          <div
            className={classes.layersButton}
            role="button"
            tabIndex={0}
            onClick={handleLayersClick}
          />
        </Box>

        <Box data-testid="legends-step" className={classes.legendsContainer}>
          <Typography variant="body1" className={classes.legendsTitle}>
            <Trans i18nKey="onboardingTooltip.legend">
              Learn what map
              <br />
              symbols mean
            </Trans>
          </Typography>
          <VectorLegends />
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label, jsx-a11y/click-events-have-key-events */}
          <div
            className={classes.legendsButton}
            role="button"
            tabIndex={0}
            onClick={handleLegendsClick}
          />
        </Box>

        <WhiteButton
          fullWidth
          size="large"
          onClick={() => setShowTutorialSteps(false)}
          className={classes.button}
        >
          {t('onboardingTooltip.button')}
        </WhiteButton>
      </div>
    </Backdrop>
  );
};

export default OnboardingWalkMe;
