import { DialogTitle, Typography } from '@mui/material';

type ElectricLineDialogContentProps = {
  title: string;
};

const ResponsibilityDialogContent = (
  props: ElectricLineDialogContentProps,
): JSX.Element => {
  const { title } = props;

  return (
    <DialogTitle id="alert-dialog-title">
      <Typography
        component="span"
        variant="body2"
        sx={{
          fontWeight: 'bold',
        }}
      >
        {title}
      </Typography>
    </DialogTitle>
  );
};

export default ResponsibilityDialogContent;
