import { useTranslation } from 'react-i18next';
import { DialogContentText, Grid, Typography } from '@mui/material';
import { PulsePointExternalGeoEvent } from '../../../shared/types';

type PulsepointContentProps = {
  externalGeoEvent: PulsePointExternalGeoEvent;
};

export const PulsepointContent = (
  props: PulsepointContentProps,
): JSX.Element => {
  // This is the base dialog content for externalSource types that we don't have a specific implementation for
  const {
    externalGeoEvent: { data },
  } = props;
  const { name, unitCount } = data;
  const { t } = useTranslation();
  const attributeNameSx = { fontVariant: 'all-small-caps' };

  return (
    <DialogContentText color="textPrimary" sx={{ marginLeft: -1 }}>
      <Grid
        container
        sx={{
          rowGap: 0.5,
          marginTop: 1,
        }}
      >
        <Grid item xs={6}>
          <Typography variant="body2" color="grayText" sx={attributeNameSx}>
            {t('externalGeoEvents.defaultSource.name')}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">{name}</Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography variant="body2" color="grayText" sx={attributeNameSx}>
            {t('externalGeoEvents.pulsepoint.unitCount')}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">{unitCount}</Typography>
        </Grid>
      </Grid>
    </DialogContentText>
  );
};
