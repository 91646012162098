import { Box, IconButton, Stack, Typography } from '@mui/material';
import { ReactNode, useContext } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { MapEntityDrawerContext } from '../MapEntityDrawer/ScrollContextProviders';

export type MapEntityDrawerHeaderProps = {
  children: ReactNode;
};

export const MapEntityDrawerHeader = (
  props: MapEntityDrawerHeaderProps,
): JSX.Element => {
  const { children } = props;
  const { closeDrawer } = useContext(MapEntityDrawerContext);
  const { t } = useTranslation();

  return (
    <Stack
      direction="row"
      sx={{
        justifyContent: 'space-between',
        marginBottom: -1,
      }}
    >
      <Typography
        variant="h3"
        sx={{
          fontWeight: 'bold',
          paddingBottom: 1,
        }}
      >
        {children}
      </Typography>
      <Box>
        <IconButton
          aria-label={t('common.close')}
          onClick={closeDrawer}
          color="inherit"
          sx={{
            // keep button padding but don't let it push the whole button down
            marginTop: -1,
            marginRight: -1,
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
    </Stack>
  );
};
