import {
  LayerProps,
  DataDrivenPropertyValueSpecification,
  FilterSpecification,
} from 'shared/map-exports';
import { BEFORE_LAYER_ID, FONT_NAMES } from 'components/Map/styles/constants';
import { FILL_PATTERN_LAYER_MIN_ZOOM } from 'components/Map/constants';
import {
  EVAC_ZONES_EDIT_FILL_LAYER_ID,
  ZONES_SOURCE_LAYER,
  INACTIVE_ZONES_LAYER_MIN_ZOOM,
  LABEL_LAYER_MIN_ZOOM,
  FEATURE_UID_V2_FIELD_NAME,
  LABELS_SOURCE_LAYER,
} from './constants';
import { EVAC_ZONE_STROKE_COLOR, ORDERS_PATTERN } from '../../../../constants';
import { getSelectedPatternStyle, getSelectedStrokeStyle } from '../../utils';

export const getFillStyleAll = (): LayerProps => {
  return {
    type: 'fill',
    'source-layer': ZONES_SOURCE_LAYER,
    paint: {
      'fill-opacity': 0,
    },
    beforeId: BEFORE_LAYER_ID,
  };
};

export const getUidFillStyle = (
  color: string,
  zoneIds: string[],
  opacity: number = 0.25,
): LayerProps => {
  return {
    type: 'fill',
    'source-layer': ZONES_SOURCE_LAYER,
    paint: {
      'fill-color': color,
      'fill-opacity': opacity,
    },
    filter: getLayerFilter(zoneIds),
    beforeId: BEFORE_LAYER_ID,
  };
};

export const getUidPatternStyle = (
  pattern: string,
  zoneIds: string[],
): LayerProps => {
  const id = `layer_pattern_${pattern}`;
  // overriden for higher contrast with the red exclamation marks
  const opacity = pattern === ORDERS_PATTERN ? 1 : 0.75;

  return {
    id,
    type: 'fill',
    minzoom: FILL_PATTERN_LAYER_MIN_ZOOM,
    'source-layer': ZONES_SOURCE_LAYER,
    paint: {
      'fill-pattern': pattern,
      'fill-opacity': opacity,
    },
    filter: getLayerFilter(zoneIds),
    beforeId: BEFORE_LAYER_ID,
  };
};

export const lineWidthLightBaseLayer: DataDrivenPropertyValueSpecification<number> =
  ['interpolate', ['linear'], ['zoom'], 6, 0.2, 8, 0.7, 18, 2.0];

export const lineWidthDarkBaseLayer: DataDrivenPropertyValueSpecification<number> =
  ['interpolate', ['linear'], ['zoom'], 6, 1, 9, 2.0, 18, 4.0];

export const getUidStrokeStyle = (
  isDarkBaseLayer: boolean,
  zoneIds: string[],
  minZoom: number,
): LayerProps => {
  return {
    type: 'line',
    'source-layer': ZONES_SOURCE_LAYER,
    paint: {
      'line-color': EVAC_ZONE_STROKE_COLOR,
      'line-opacity': getMinZoomOpacity(minZoom),
      'line-width': isDarkBaseLayer
        ? lineWidthDarkBaseLayer
        : lineWidthLightBaseLayer,
    },
    filter: getLayerFilter(zoneIds),
  };
};

export const getStrokeStyleAll = (isDarkBaseLayer: boolean): LayerProps => {
  return {
    type: 'line',
    'source-layer': ZONES_SOURCE_LAYER,
    paint: {
      'line-color': EVAC_ZONE_STROKE_COLOR,
      'line-opacity': getMinZoomOpacity(INACTIVE_ZONES_LAYER_MIN_ZOOM),
      'line-width': isDarkBaseLayer
        ? lineWidthDarkBaseLayer
        : lineWidthLightBaseLayer,
    },
  };
};

export const getSelectedZonePatternStyle = (
  selectedZoneId: string,
): LayerProps => {
  return getSelectedPatternStyle({
    idKey: FEATURE_UID_V2_FIELD_NAME,
    otherLayerProps: {
      'source-layer': ZONES_SOURCE_LAYER,
      minzoom: INACTIVE_ZONES_LAYER_MIN_ZOOM,
    },
    selectedId: selectedZoneId,
  });
};

export const getSelectedZoneStrokeStyle = (
  selectedZoneId: string,
): LayerProps => {
  return getSelectedStrokeStyle({
    idKey: FEATURE_UID_V2_FIELD_NAME,
    otherLayerProps: {
      'source-layer': ZONES_SOURCE_LAYER,
      minzoom: INACTIVE_ZONES_LAYER_MIN_ZOOM,
    },
    selectedId: selectedZoneId,
  });
};

export const getLayerFilter = (
  zoneUIds: string[],
  isAllEvacZoneLayerOn?: boolean,
): FilterSpecification => {
  if (isAllEvacZoneLayerOn) {
    return ['!=', ['get', FEATURE_UID_V2_FIELD_NAME], ['literal', null]];
  }
  return ['in', ['get', FEATURE_UID_V2_FIELD_NAME], ['literal', zoneUIds]];
};

export const getFillLayerStyle = (
  evacZoneUIds: string[],
  isAllEvacZoneLayerOn?: boolean,
): LayerProps => ({
  id: EVAC_ZONES_EDIT_FILL_LAYER_ID,
  type: 'fill',
  'source-layer': ZONES_SOURCE_LAYER,
  paint: {
    'fill-color': ['coalesce', ['feature-state', 'color'], 'transparent'],
    'fill-opacity': 0.25,
  },
  filter: getLayerFilter(evacZoneUIds, isAllEvacZoneLayerOn),
});

export const getMinZoomOpacity = (
  minzoom: number,
): DataDrivenPropertyValueSpecification<number> => [
  'step',
  ['zoom'],
  // At zoom levels above minzoom:
  0,
  // At this zoom level and below
  minzoom,
  1,
];

export const getPerimeterLayerStyle = (
  isDarkBaseLayer: boolean,
  evacZoneUIds: string[],
): LayerProps => ({
  id: 'zones-perimeter',
  type: 'line',
  'source-layer': ZONES_SOURCE_LAYER,
  paint: {
    'line-color': EVAC_ZONE_STROKE_COLOR,
    'line-opacity': getMinZoomOpacity(INACTIVE_ZONES_LAYER_MIN_ZOOM),
    'line-width': isDarkBaseLayer
      ? lineWidthDarkBaseLayer
      : lineWidthLightBaseLayer,
  },
  filter: getLayerFilter(evacZoneUIds),
});

export const getLabelLayerStyleAll = (): LayerProps => ({
  type: 'symbol',
  'source-layer': LABELS_SOURCE_LAYER,
  minzoom: LABEL_LAYER_MIN_ZOOM,
  paint: {
    'text-color': '#000000',
    'text-halo-width': 1.8,
    'text-halo-color': '#ffffff',
    'text-opacity': 1,
  },
  layout: {
    'text-field': ['get', 'zone_name'],
    'text-anchor': 'center',
    'text-justify': 'auto',
    'text-size': ['interpolate', ['linear'], ['zoom'], 9, 10, 13, 16],
    'text-font': FONT_NAMES.bold,
    'text-allow-overlap': ['step', ['zoom'], false, 13, true],
  },
});

export const getLabelLayerStyle = (
  evacZoneUIds: string[],
  isAllEvacZoneLayerOn?: boolean,
): LayerProps => ({
  id: 'zones-label',
  type: 'symbol',
  'source-layer': LABELS_SOURCE_LAYER,
  minzoom: LABEL_LAYER_MIN_ZOOM,
  paint: {
    'text-color': '#000000',
    'text-halo-width': 1.8,
    'text-halo-color': '#ffffff',
    'text-opacity': 1,
  },
  layout: {
    'text-field': ['get', 'zone_name'],
    'text-anchor': 'center',
    'text-justify': 'auto',
    'text-size': ['interpolate', ['linear'], ['zoom'], 9, 10, 13, 16],
    'text-font': FONT_NAMES.bold,
    'text-allow-overlap': ['step', ['zoom'], false, 13, true],
  },
  filter: getLayerFilter(evacZoneUIds, isAllEvacZoneLayerOn),
});
