import { useTranslation } from 'react-i18next';
import { Box, Button, Container, Grid, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Link } from 'react-router-dom';
import PlacesImg from 'assets/places.svg?react';

const useStyles = makeStyles()((theme) => ({
  imgContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 36,
    marginBottom: 20,
  },
  img: {
    width: 343,
    height: 343,
  },
}));

const EmptyPlaces = (): JSX.Element => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <Box sx={{ width: '100%', overflow: 'auto' }}>
      <Container maxWidth="xs">
        <Grid container spacing={2}>
          <Grid item xs={12} className={classes.imgContainer}>
            <PlacesImg className={classes.img} />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h2" align="center">
              {t('places.title')}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography>{t('places.findPlaces')}</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography>
              <b>{t('places.privatePlaces')}</b>
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Button
              fullWidth
              size="large"
              to="/add_place#allow-back"
              component={Link}
            >
              {t('places.button')}
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default EmptyPlaces;
