import { Grid, Theme, Typography, Alert, Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { GeoEvent } from '../../../../shared/types';

const useStyles = makeStyles()((theme: Theme) => ({
  newLine: {
    whiteSpace: 'pre-line',
    wordBreak: 'break-word',
  },
}));

type AutomatedIncidentDisclaimerProps = {
  geoEvent: GeoEvent;
};

const AutomatedIncidentDisclaimer = (
  props: AutomatedIncidentDisclaimerProps,
): JSX.Element => {
  const {
    geoEvent: {
      createdByName: { displayName, abbreviationName },
      userCreatedId,
    },
  } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();

  const shortNameWrapped = abbreviationName ? ` (${abbreviationName})` : '';

  return (
    <Grid container spacing={2} data-testid="evacInfo">
      <Grid item xs={12}>
        <Alert severity="warning" icon={false}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography className={classes.newLine}>
                <Trans i18nKey="geoEvent.automatedDisclaimer.updates">
                  All updates for this incident are automated and provided by
                  the
                  <b>
                    <>{{ displayName }}</>
                  </b>
                  {{ shortNameWrapped }}.
                </Trans>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.newLine}>
                <Trans i18nKey="geoEvent.automatedDisclaimer.notMonitoring">
                  Watch Duty reporters are
                  <b>not actively monitoring this incident</b>.
                </Trans>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button
                component={Link}
                variant="watchduty-text-uppercase"
                to={`/profile/${userCreatedId}#allow-back`}
                sx={{
                  color: 'text.primary',
                }}
              >
                {t('geoEvent.evacuation.learnMore')}
              </Button>
            </Grid>
          </Grid>
        </Alert>
      </Grid>
    </Grid>
  );
};

export default AutomatedIncidentDisclaimer;
