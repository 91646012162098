import { Grid, TextField } from '@mui/material';
import FilePicker from 'components/FilePicker';
import { t } from 'i18next';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { Photo } from '@watchduty/camera';
import { ChangeEvent, useCallback } from 'react';
import { AlertCamera } from 'hooks/useAlertCameras';
import { GeoEvent, ReportAsset, Report } from 'shared/types';
import { MediaMapForm } from './MediaMapForm';
import { ImageUrlPreview, ImageDataPreview } from './ImagePreview';
import { parseFile } from './MediaForm.utils';

type ImageFormProps = {
  latFieldName: string;
  lngFieldName: string;
  azFieldName: string;
  geoEvent?: GeoEvent;
  report?: Report;
  mediaUrl?: string;
  asset: ReportAsset;
  allowCoordinates: boolean;
};

export const ImageForm = (props: ImageFormProps): JSX.Element => {
  const {
    latFieldName,
    lngFieldName,
    azFieldName,
    geoEvent,
    report,
    mediaUrl,
    asset,
    allowCoordinates,
  } = props;
  const { control, formState, setValue, setError } = useFormContext();

  const media: Blob | File | AlertCamera | null = useWatch({
    control,
    name: 'media',
    defaultValue: null,
    disabled: Boolean(report),
  });

  const handleFilePicked = async (
    newFile: File | File[] | Photo,
  ): Promise<void> => {
    const { fileType, file, bearing, lat, lng } = await parseFile(newFile);

    if (!fileType || !file) return;

    setValue('fileType', fileType);
    setValue('media', file);

    if (lat && lng) {
      setValue(latFieldName, lat);
      setValue(lngFieldName, lng);
    }

    if (bearing) {
      setValue(azFieldName, bearing);
    }
  };

  const handleInvalidFiles = (error: string): void => {
    setError('media', { message: error });
  };

  const handleDeleteImagePreview = useCallback((): void => {
    setValue('media', null);
    setValue('fileType', '');
    setValue(latFieldName, '');
    setValue(lngFieldName, '');
    setValue(azFieldName, 0);
  }, [azFieldName, latFieldName, lngFieldName, setValue]);

  const handleCoordsChange = (e: ChangeEvent<HTMLInputElement>): void => {
    // Allow users to paste `lat, lng` as a string and lets parse it for them
    const { name, value } = e.target;

    const latLng = value.split(/, ?/);

    if (latLng.length === 2) {
      // User pasted string values
      const [lat, lng] = latLng;

      setValue(latFieldName, lat);
      setValue(lngFieldName, lng);
      return;
    }

    setValue(name, value);
  };

  const handleDeleteImage = (): void => {
    const msg = t('editReport.deleteImageConfirmation');
    // eslint-disable-next-line no-restricted-globals, no-alert
    if (!confirm(msg)) return;

    if (report) setValue('mediaUrl', '');
    setValue('asset', null);
    setValue(latFieldName, '');
    setValue(lngFieldName, '');
    setValue(azFieldName, 0);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        {mediaUrl ? (
          <Controller
            name="mediaUrl"
            control={control}
            render={({ field }): JSX.Element => {
              return (
                <ImageUrlPreview
                  url={field.value}
                  onDelete={handleDeleteImage}
                />
              );
            }}
          />
        ) : (
          <Controller
            name="media"
            control={control}
            render={({ field, fieldState }): JSX.Element => {
              if (!field.value) {
                return (
                  <FilePicker
                    message={t(`addIncidentReport.inputs.${asset}.label`)}
                    disabled={formState.isSubmitting}
                    error={fieldState.error?.message}
                    onFilesPicked={handleFilePicked}
                    onFilesInvalid={handleInvalidFiles}
                  />
                );
              }
              return (
                <ImageDataPreview
                  data={field.value}
                  onDelete={handleDeleteImagePreview}
                />
              );
            }}
          />
        )}
      </Grid>
      {(media || Boolean(mediaUrl)) && (
        <Grid item xs={12} sm={6}>
          <Grid item container spacing={2}>
            {allowCoordinates && (
              <>
                <Grid item xs={6}>
                  <Controller
                    name={latFieldName}
                    control={control}
                    render={({ field, fieldState }): JSX.Element => {
                      const { ref, ...muiFieldProps } = field;
                      return (
                        <TextField
                          id="field-control-lat"
                          label={t('addIncidentReport.inputs.latitude.label')}
                          fullWidth
                          {...muiFieldProps}
                          inputRef={ref}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          placeholder="00.000000"
                          onChange={handleCoordsChange}
                          disabled={formState.isSubmitting}
                          slotProps={{
                            htmlInput: {
                              pattern: '[0-9]+([.,][0-9]+)?',
                            },
                          }}
                        />
                      );
                    }}
                  />
                </Grid>

                <Grid item xs={6}>
                  <Controller
                    name={lngFieldName}
                    control={control}
                    render={({ field, fieldState }): JSX.Element => {
                      const { ref, ...muiFieldProps } = field;
                      return (
                        <TextField
                          id="field-control-lng"
                          label={t('addIncidentReport.inputs.longitude.label')}
                          fullWidth
                          {...muiFieldProps}
                          inputRef={ref}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          placeholder="00.000000"
                          onChange={handleCoordsChange}
                          disabled={formState.isSubmitting}
                          slotProps={{
                            htmlInput: {
                              pattern: '[0-9]+([.,][0-9]+)?',
                            },
                          }}
                        />
                      );
                    }}
                  />
                </Grid>
              </>
            )}

            <MediaMapForm
              geoEvent={geoEvent}
              latFieldName={latFieldName}
              lngFieldName={lngFieldName}
              azFieldName={azFieldName}
              allowAzimuth={allowCoordinates}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
