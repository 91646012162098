import { WheelEvent } from 'react';
import { FormControl, FormHelperText, Grid, TextField } from '@mui/material';
import {
  Controller,
  UseFormReturn,
  UseWatchProps,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDebounceValue } from 'usehooks-ts';
import { GeoEvent } from 'shared/types';
import Slider from 'components/Slider';
import { DEFAULT_LAT, DEFAULT_LON } from '../../constants';
import { MediaMap } from './MediaMap';

type MediaMapFormProps = {
  geoEvent?: GeoEvent;
  latFieldName: string;
  lngFieldName: string;
  azFieldName: string;
  allowAzimuth: boolean;
};

type UseDebouncedWatchOptions<T> = Omit<UseWatchProps, 'name'> & {
  name: string;
  defaultValue?: T;
  delay?: number;
  control: UseFormReturn['control'];
};

const useDebouncedWatch = <T,>({
  control,
  name,
  defaultValue,
  delay = 500,
  ...restOptions
}: UseDebouncedWatchOptions<T>): T => {
  const value = useWatch({
    control,
    name,
    defaultValue,
    ...restOptions,
  }) as T;

  const [debouncedValue] = useDebounceValue<T>(value, delay);

  return debouncedValue;
};

export const MediaMapForm = (props: MediaMapFormProps): JSX.Element | null => {
  const { geoEvent, latFieldName, lngFieldName, azFieldName, allowAzimuth } =
    props;
  const { t } = useTranslation();
  const { control, formState } = useFormContext();

  const latitude = useDebouncedWatch<string>({
    control,
    name: latFieldName,
    defaultValue: DEFAULT_LAT.toString(),
  });

  const longitude = useDebouncedWatch<string>({
    control,
    name: lngFieldName,
    defaultValue: DEFAULT_LON.toString(),
  });

  if (!latitude || !longitude) {
    return null;
  }

  return (
    <>
      {allowAzimuth && (
        <Grid item xs={12}>
          <Controller
            name={azFieldName}
            control={control}
            render={({ field, fieldState }): JSX.Element => {
              const { ref, onChange, ...muiFieldProps } = field;
              return (
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      id="field-control-az"
                      label={t('addIncidentReport.inputs.azimuth.label')}
                      fullWidth
                      {...muiFieldProps}
                      onChange={(e) => onChange(Number(e.target.value))}
                      inputRef={ref}
                      error={!!fieldState.error}
                      type="number"
                      disabled={formState.isSubmitting}
                      slotProps={{
                        htmlInput: {
                          pattern: '[0-9]+([.,][0-9]+)?',
                          inputMode: 'numeric',
                          onWheel: (e: WheelEvent<HTMLInputElement>) =>
                            e.currentTarget.blur(),
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <Slider
                        value={field.value}
                        onChange={(_, newValue: number | number[]): void => {
                          const azValue =
                            typeof newValue === 'number'
                              ? newValue
                              : newValue[0];
                          field.onChange(azValue);
                        }}
                        max={360}
                        min={0}
                        disabled={formState.isSubmitting}
                      />
                      {!!fieldState.error?.message && (
                        <FormHelperText error>
                          {fieldState.error.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
              );
            }}
          />
        </Grid>
      )}

      <Grid item xs={12}>
        <MediaMap
          geoEvent={geoEvent}
          lat={Number(latitude)}
          lng={Number(longitude)}
          azFieldName={azFieldName}
        />
      </Grid>
    </>
  );
};
