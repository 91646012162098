import { useContext, useRef, useState } from 'react';
import {
  Grid,
  Box,
  useMediaQuery,
  useTheme,
  Typography,
  Button,
  List,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Trans, useTranslation } from 'react-i18next';
import {
  ActionSheet,
  ActionSheetButton,
  ActionSheetButtonStyle,
} from '@capacitor/action-sheet';
import Sticky from 'react-sticky-el';
import { Capacitor } from '@capacitor/core';
import { Location } from 'shared/types';
import { getDateFormatted, getTimePass } from 'shared/dates';
import { getDeviceInfo } from 'state/localStorageTyped';
import RichTextContent from 'components/RichTextContent';
import LinkItem from 'components/LinkItem';
import { Helmet } from 'react-helmet';
import { MapEntityDrawerContext } from 'components/Map/MapEntityDrawer';
import { MapEntityDrawerHeader } from 'components/Map/MapEntityDrawerHeader';
import useGeoEventQuery from 'hooks/useGeoEventQuery';
import { LoadingAndErrors } from 'components/LoadingAndErrors';
import { useShareOrCopy } from 'hooks/useShareOrCopy';

const openAppleMaps = (address: string): void => {
  window.open(
    `https://maps.apple.com/?address=${encodeURIComponent(address)}`,
    '_blank',
  );
};

const openGoogleMaps = (address: string): void => {
  window.open(
    `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
      address,
    )}`,
    '_blank',
  );
};

const openWazeApp = (address: string): void => {
  window.open(`https://waze.com/ul?q=${encodeURIComponent(address)}`, '_blank');
};

const useStyles = makeStyles()((theme) => ({
  sectionHeader: {
    background: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  message: {
    whiteSpace: 'pre-line',
    wordBreak: 'break-word',
    lineHeight: theme.typography.pxToRem(22),
  },
  stickyEl: {
    background: theme.palette.background.paper,
    zIndex: 1,
    '&:after': {
      content: '""',
      position: 'absolute',
      width: 'calc(100% - 8px)',
      bottom: 0,
      left: 4,
      zIndex: -1,
      boxShadow: '0px 0px 4px 4px rgba(0, 0, 0, 0.25)',
    },
  },
  tabHeader: {
    borderBottomStyle: 'solid',
    borderBottomWidth: '2px',
    borderBottomColor: theme.palette.warning.light,
    minWidth: '50%',
    textAlign: 'center',
    display: 'inline-block',
    padding: '12px 8px',
  },
}));

const LocationDetails = (): JSX.Element => {
  const {
    geoEvent: location,
    isLoading,
    isError,
  } = useGeoEventQuery<Location>();
  const { classes } = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const isLargeMediaQuery = useMediaQuery(theme.breakpoints.up(660), {
    defaultMatches: !Capacitor.isNativePlatform(),
  });
  const { canShareOrCopy, shareOrCopyUrl } = useShareOrCopy();
  const { scrollElementRef } = useContext(MapEntityDrawerContext);

  const deviceInfo = getDeviceInfo();

  const eventHeaderRef = useRef<HTMLDivElement>(null);
  const eventHeaderHeight = eventHeaderRef.current?.offsetHeight;
  const [isInfoStuck, setIsInfoStuck] = useState<boolean>(false);

  const handleDirectionsClick = async (): Promise<void> => {
    if (!location) {
      return;
    }

    let options: ActionSheetButton[] = [
      { title: t('location.directions.openGoogle') },
      { title: t('location.directions.openWaze') },
      { title: t('common.cancel'), style: ActionSheetButtonStyle.Cancel },
    ];

    const iosPlatform = deviceInfo?.platform === 'ios';

    if (iosPlatform) {
      options = [{ title: t('location.directions.openApple') }, ...options];
    }

    const { index } = await ActionSheet.showActions({
      title: Capacitor.isNativePlatform()
        ? undefined
        : t('location.directions.title'),
      options,
    });

    if (index === 0) {
      if (iosPlatform) {
        openAppleMaps(location.address);
        return;
      }
      openGoogleMaps(location.address);
      return;
    }

    if (index === 1) {
      if (iosPlatform) {
        openGoogleMaps(location.address);
        return;
      }
      openWazeApp(location.address);
      return;
    }

    if (index === 2 && iosPlatform) {
      openWazeApp(location.address);
    }
  };

  const handleShare = async (): Promise<void> => {
    if (!location) {
      return;
    }

    shareOrCopyUrl({
      title: location.name,
      text: location.name,
      url: `${import.meta.env.VITE_BASE_SHARE_URL}location/${location.id}`,
      androidDialogTitle: t('location.shareLocation'),
    });
  };

  if (isLoading) {
    return <LoadingAndErrors isLoading />;
  }

  if (isError || !location) {
    return <LoadingAndErrors />;
  }

  return (
    <>
      <Helmet>
        <title>{t('home.locationTitle', { name: location.name })}</title>
      </Helmet>

      <Grid container spacing={2}>
        <Grid xs={12} item>
          <Sticky
            scrollElement={scrollElementRef?.current as HTMLElement}
            stickyClassName={classes.stickyEl}
            stickyStyle={{
              paddingTop: isLargeMediaQuery ? 8 : 0,
            }}
          >
            <Box
              sx={{
                paddingX: 2,
                paddingBottom: 1,
                background: theme.palette.background.paper,
              }}
              ref={eventHeaderRef}
            >
              <MapEntityDrawerHeader>{location.name}</MapEntityDrawerHeader>
              <Typography>{location.address}</Typography>
            </Box>
          </Sticky>
        </Grid>

        <Grid item xs={12} sx={{ paddingTop: 0, marginX: 2 }}>
          <Typography>
            <Trans
              i18nKey="location.updated"
              values={{ timeAgo: getTimePass(location.dateModified) }}
            >
              Updated <b>5 minutes ago</b>
            </Trans>
          </Typography>

          <Typography variant="body2" color="textSecondary">
            <Trans
              i18nKey="location.createdBy"
              values={{
                name:
                  location.createdByName.displayNameShort ||
                  t('location.watchDuty'),
                date: getDateFormatted(location.dateCreated),
              }}
            >
              Created by <b>Watch Duty</b> · Aug 4, 12:54 PM
            </Trans>
          </Typography>
        </Grid>

        <Grid item xs={12} sx={{ marginX: 2 }}>
          <Button
            fullWidth
            color="inherit"
            variant="outlined"
            size="large"
            onClick={handleDirectionsClick}
          >
            {t('location.buttons.directions')}
          </Button>
        </Grid>

        {canShareOrCopy && (
          <Grid item xs={12} sx={{ marginX: 2 }}>
            <Button
              fullWidth
              color="inherit"
              variant="outlined"
              size="large"
              onClick={handleShare}
            >
              {t('location.buttons.share')}
            </Button>
          </Grid>
        )}
      </Grid>

      {!!location.data.information && (
        <>
          <Grid item xs={13}>
            <Sticky
              scrollElement={scrollElementRef?.current as HTMLElement}
              stickyClassName={classes.stickyEl}
              topOffset={eventHeaderHeight! * -1}
              onFixedToggle={(isVisible) => setIsInfoStuck(isVisible)}
              stickyStyle={{
                marginTop: eventHeaderHeight,
              }}
            >
              <Box
                className={classes.sectionHeader}
                sx={{
                  paddingTop: isInfoStuck ? 0 : 2,
                  paddingBottom: 0,
                }}
              >
                <Box className={classes.tabHeader}>
                  <Typography
                    sx={{
                      fontSize: 13,
                      fontWeight: 700,
                      textTransform: 'uppercase',
                    }}
                  >
                    {t('location.sections.information')}
                  </Typography>
                </Box>
              </Box>
            </Sticky>
          </Grid>
          <Grid item xs={12} sx={{ marginX: 2 }}>
            <RichTextContent
              content={location.data.information}
              className={classes.message}
            />
          </Grid>
        </>
      )}

      {location.data.links.length > 0 && (
        <>
          <Grid item className={classes.sectionHeader} sx={{ padding: 2 }}>
            <Typography variant="h3">{t('location.sections.links')}</Typography>
          </Grid>
          <Grid item>
            <List>
              {location.data.links.map((link) => (
                <LinkItem key={`${link.label}-${link.value}`} link={link} />
              ))}
            </List>
          </Grid>
        </>
      )}
    </>
  );
};

LocationDetails.displayName = 'LocationDetails';

export default LocationDetails;
