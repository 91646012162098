import { useRef, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Box, Container, Typography, Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Link } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import HeaderBar from 'components/HeaderBar';
import PageLayout from 'components/PageLayout';
import useMembershipState from 'state/useMembershipState';
import { ANALYTICS_EVENTS, logEvent } from 'shared/analytics';
import useLocationSearchParams from 'hooks/useLocationSearchParams';
import { useAuthState } from 'state';
import { WATCHDUTY_SCHEME } from '../../../constants';

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
    overflowY: 'auto',
    backgroundColor: theme.palette.background.paper,
    paddingBottom: 'max(env(safe-area-inset-bottom), 16px)',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  contentContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  atag: {
    textDecoration: 'none',
    width: '100%',
  },
}));

const getMembershipSuccessButtonLink = (input: {
  newUserMembership: boolean;
  isMembershipUpgrade: boolean;
  clientToken: string;
}): string => {
  const { newUserMembership, isMembershipUpgrade, clientToken } = input;
  if (newUserMembership) {
    return `/?clientToken=${clientToken}`;
  }
  if (isMembershipUpgrade) {
    return '/?membershipUpgrade=true';
  }
  return '/?membershipSuccess=true';
};

const Content = (): JSX.Element => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { clientToken, fromMobile, resetMembershipState } =
    useMembershipState();
  const fromMobileRef = useRef<boolean>(fromMobile);
  const clientTokenRef = useRef<string>(clientToken);
  const params = useLocationSearchParams();
  const { isAuthenticated } = useAuthState();
  const queryClient = useQueryClient();

  const newUserMembership = !!clientTokenRef.current;
  const isMembershipUpgrade = params.get('upgrade') === 'true';

  useEffect(() => {
    // send explicit GA events for membership success
    logEvent({
      name: ANALYTICS_EVENTS.SUPPORT.PURCHASE,
      params: {},
    });
    logEvent({
      name: ANALYTICS_EVENTS.SUPPORT.PURCHASE_MEMBERSHIP,
      params: {},
    });
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      queryClient.invalidateQueries({ queryKey: ['membership-status'] });
    }
  }, [isAuthenticated, queryClient]);

  return (
    <>
      <Helmet>
        <title>{t('membershipSuccess.title')}</title>
      </Helmet>

      <div className={classes.root}>
        <Container maxWidth="sm" className={classes.container}>
          <Box className={classes.contentContainer}>
            <Typography variant="h2" align="center" sx={{ marginBottom: 4 }}>
              <b>{t('membershipSuccess.subtitle')}</b>
            </Typography>

            <Typography align="center" sx={{ marginBottom: 3 }}>
              {t(
                `membershipSuccess.thanks.${
                  isMembershipUpgrade ? 'pro' : 'regular'
                }`,
              )}
            </Typography>

            {newUserMembership && (
              <Typography align="center">
                {t('membershipSuccess.message')}
              </Typography>
            )}
          </Box>

          {/* The react-dom-router link component does not consistently open the custom scheme urls correctly, use explicit A tag */}
          {fromMobileRef.current ? (
            <a
              href={`${WATCHDUTY_SCHEME}app.watchduty.org${getMembershipSuccessButtonLink(
                {
                  newUserMembership,
                  isMembershipUpgrade,
                  clientToken: clientTokenRef.current,
                },
              )}`}
              target="_blank"
              className={classes.atag}
              rel="noreferrer"
            >
              <Button
                fullWidth
                size="large"
                sx={{ marginTop: 2 }}
                onClick={resetMembershipState}
              >
                {t('membershipSuccess.button')}
              </Button>
            </a>
          ) : (
            <Button
              fullWidth
              size="large"
              sx={{ marginTop: 2 }}
              component={Link}
              to={getMembershipSuccessButtonLink({
                newUserMembership,
                isMembershipUpgrade,
                clientToken: clientTokenRef.current,
              })}
              onClick={resetMembershipState}
            >
              {t('membershipSuccess.button')}
            </Button>
          )}
        </Container>
      </div>
    </>
  );
};

const MembershipSuccess = (): JSX.Element => {
  return (
    <PageLayout data-cname="MembershipSuccessPage" showBanner={false}>
      {{
        content: <Content />,
        headerBar: <HeaderBar action="noControls" />,
      }}
    </PageLayout>
  );
};

export default MembershipSuccess;
