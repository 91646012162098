import { LocationOn as LocationOnIcon } from '@mui/icons-material';
import { Map } from 'shared/map-exports';
import { formatDate, getTimePass } from 'shared/dates';
import { LOCATION_ZOOM_LEVEL } from '../../components/Map/constants';
import { getGeoEventIcon } from '../../components/Map/Icons';
import { getSearchCardMinSnapPx } from '../../constants';
import { GeoEvent, LatLng, MapLocation } from '../../shared/types';
import {
  getMapSearchResultFormattedAddress,
  getRegionName,
} from '../../shared/utils';
import { GeocodeResult, SearchSource } from './useMapSearch.types';
import {
  fetchGeoEventsForSearch,
  fetchGoogleMapsSearch,
  fetchMapboxSearch,
  getGoogleCoords,
  getLatLngFromString,
  getMapSearchResultName,
} from './useMapSearch.utils';

type GetMapsSearchSourceProps = {
  map?: Map;
  setSelectedValue: (value: GeocodeResult) => void;
  setSelectedLocation: (location: MapLocation) => void;
};

const placeIconSx = {
  color: 'accent.main',
  // For some reason, the place icons have some extra padding that doesn't align with our fire icons
  marginLeft: '-6px',
  marginRight: '6px',
};

export const wildfireGeoEventSearchSource: SearchSource<GeoEvent> = {
  sourceId: 'wildfire',
  action: 'link',
  fetchFn: async (searchQuery) => {
    const results = await fetchGeoEventsForSearch(searchQuery);
    return results;
  },
  getId: (geoEvent) => geoEvent.id,
  getPrimaryText: (geoEvent) => geoEvent.name,
  getSecondaryText: (geoEvent) => getRegionName(geoEvent.regions),
  getTertiaryText: (geoEvent) => getTimePass(geoEvent.dateModified),
  getAvatar: (geoEvent) => (
    <img src={getGeoEventIcon(geoEvent).data} alt="" width={24} />
  ),
  getHref: (geoEvent) => `/i/${geoEvent.id}`,
};

export const inactiveWildfireGeoEventSearchSource: SearchSource<GeoEvent> = {
  ...wildfireGeoEventSearchSource,
  sourceId: 'inactive-wildfire',
  getTertiaryText: (geoEvent) =>
    formatDate(new Date(geoEvent.dateCreated), 'MMM yyyy'),
  fetchFn: async (searchQuery) => {
    const results = await fetchGeoEventsForSearch(searchQuery, true);
    return results;
  },
};

const easeMapToPin = (map: Map | undefined, lng: number, lat: number): void => {
  if (!map) return;

  setTimeout(() => {
    map.easeTo({
      center: [lng, lat],
      zoom: LOCATION_ZOOM_LEVEL,
      offset: [0, -getSearchCardMinSnapPx()],
    });
  }, 0);
};

export const getGoogleMapsSearchSource = (
  props: GetMapsSearchSourceProps,
): SearchSource<GeocodeResult> => {
  const { map, setSelectedValue, setSelectedLocation } = props;
  return {
    sourceId: 'google',
    action: 'click',
    fetchFn: fetchGoogleMapsSearch,
    getId: (result) => result.id,
    getPrimaryText: (result) => getMapSearchResultName(result),
    getSecondaryText: (result) =>
      getMapSearchResultFormattedAddress(
        getMapSearchResultName(result),
        result.placeName,
      ),
    getAvatar: () => <LocationOnIcon fontSize="large" sx={placeIconSx} />,
    onClick: async (result) => {
      // Map search result is a google place prediction, get place by id
      const response = await getGoogleCoords(result.id);
      if (response) {
        const mapLocation: GeocodeResult = {
          ...result,
          center: response.coords,
          placeAddress: response.formattedAddress,
        };

        // setSelectedValue enables the 'save' button, setSelectedLocation drops the pin
        setSelectedValue(mapLocation);
        setSelectedLocation({
          name: getMapSearchResultName(result),
          lat: response.coords[1],
          lng: response.coords[0],
          place: false,
          type: 'LatLngLocation',
        });

        easeMapToPin(map, response.coords[0], response.coords[1]);
      }
    },
  };
};

export const getMapboxMapsSearchSource = (
  props: GetMapsSearchSourceProps,
): SearchSource<GeocodeResult> => {
  const { map, setSelectedValue, setSelectedLocation } = props;
  return {
    sourceId: 'mapbox',
    action: 'click',
    fetchFn: fetchMapboxSearch,
    getId: (result) => result.id,
    getPrimaryText: (result) => getMapSearchResultName(result),
    getSecondaryText: (result) =>
      getMapSearchResultFormattedAddress(
        getMapSearchResultName(result),
        result.placeName,
      ),
    getAvatar: () => <LocationOnIcon fontSize="large" sx={placeIconSx} />,
    onClick: async (result) => {
      // setSelectedValue enables the 'save' button, setSelectedLocation drops the pin
      setSelectedValue(result);
      setSelectedLocation({
        name: getMapSearchResultName(result),
        lat: result.center[1],
        lng: result.center[0],
        place: false,
        type: 'LatLngLocation',
      });

      easeMapToPin(map, result.center[0], result.center[1]);
    },
  };
};

export const getLatLngSearchSource = (
  props: GetMapsSearchSourceProps,
): SearchSource<LatLng> => {
  const { map, setSelectedValue, setSelectedLocation } = props;
  return {
    sourceId: 'latlng',
    action: 'click',
    fetchFn: async (searchQuery) => {
      const latLng = getLatLngFromString(searchQuery);
      return latLng ? [latLng] : [];
    },
    getId: (latLng) => latLng.toString(),
    getPrimaryText: (latLng) => `${latLng.lat}, ${latLng.lng}`,
    getSecondaryText: () => '',
    getAvatar: () => <LocationOnIcon fontSize="large" sx={placeIconSx} />,
    onClick: async (latLng) => {
      const { lat, lng } = latLng;
      // setSelectedValue enables the 'save' button, setSelectedLocation drops the pin
      setSelectedValue({
        id: latLng.toString(),
        type: 'LatLngLocation',
        text: `${latLng.lat}, ${latLng.lng}`,
        placeName: `${latLng.lat}, ${latLng.lng}`,
        center: [lng, lat],
      });
      setSelectedLocation({
        name: `${latLng.lat}, ${latLng.lng}`,
        lat,
        lng,
        place: false,
        type: 'LatLngLocation',
      });

      easeMapToPin(map, lng, lat);
    },
  };
};
