import { ReactNode } from 'react';
import { Box, Button, Container, Grid, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import WarningIcon from '@mui/icons-material/WarningTwoTone';
import {
  NativeSettings,
  IOSSettings,
  AndroidSettings,
} from 'capacitor-native-settings';
import { getResponsiveFontSize } from 'shared/utils';

type NoPermissionAccessProps = {
  title: string;
  description: string | ReactNode;
  buttonTitle: string;
};

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: 'max(env(safe-area-inset-bottom), 16px)',
    overflowY: 'auto',
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 16,
  },
  icon: {
    fontSize: getResponsiveFontSize('8rem'),
  },
  description: {
    '& > :first-child': {
      marginBottom: theme.spacing(3),
    },
  },
}));

const NoPermissionAccess = (props: NoPermissionAccessProps): JSX.Element => {
  const { title, description, buttonTitle } = props;
  const { classes } = useStyles();

  const handleEnableLocationServices = (): void => {
    NativeSettings.open({
      optionAndroid: AndroidSettings.ApplicationDetails,
      optionIOS: IOSSettings.App,
    });
  };

  return (
    <Box className={classes.root}>
      <Container maxWidth="md" className={classes.container}>
        <Grid container spacing={2} direction="column">
          <Grid item>
            <Box className={classes.iconContainer}>
              <WarningIcon color="error" className={classes.icon} />
            </Box>
          </Grid>

          <Grid item>
            <Typography variant="h1" align="center">
              {title}
            </Typography>
          </Grid>

          {typeof description === 'string' ? (
            <Grid item>
              <Typography>{description}</Typography>
            </Grid>
          ) : (
            <Grid item className={classes.description}>
              {description}
            </Grid>
          )}

          <Grid item>
            <Button
              fullWidth
              size="large"
              onClick={handleEnableLocationServices}
            >
              {buttonTitle}
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default NoPermissionAccess;
