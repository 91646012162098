import { Capacitor } from '@capacitor/core';
import * as Sentry from '@sentry/capacitor';
import * as SentryReact from '@sentry/react';
import { rewriteFramesIntegration } from '@sentry/react';
import { getAppVersion } from './utils';

const isReleaseBuild = (): boolean => {
  const isWebProductionBuild =
    !Capacitor.isNativePlatform() && import.meta.env.PROD;
  const isNativeProductionBuild =
    Capacitor.isNativePlatform() && !Capacitor.DEBUG;

  return isWebProductionBuild || isNativeProductionBuild;
};

const getSentryReleaseName = async (): Promise<string> => {
  if (!isReleaseBuild()) {
    return 'pre-release-testing';
  }

  const sha = import.meta.env.VITE_GIT_SHA;
  const appVersion = await getAppVersion();

  return `${appVersion}-${sha}`;
};

const modifyStacktraceFilenames = (
  frame: Sentry.StackFrame,
): Sentry.StackFrame => {
  // This updates the stacktrace filenames to help point to our map files
  if (!frame.filename) {
    return frame;
  }
  // This prefix tells sentry parse the relative paths of our source mappings with this prefix + relative path
  // eslint-disable-next-line
  frame.filename = frame.filename.replace(/^.*\/assets\//, '/assets/');
  return frame;
};

const SENTRY_IGNORE_LIST = [
  // This is a known error that android raises when using the AppUpdate capacitor plugin.
  'Install Error(',
  // This is raised by appupdate logic as well
  'Failed to bind to the service.',
  // Axios generated
  'Network Error',
  // This is raised by axios for bad reponse codes, but we have no information on what failed
  'Request failed with status code',
  // Catchall for random network errors in axios and other libraries
  'Failed to fetch',
  // This is a capacitor exception only raised in ios, not sure what it is, but doesn't seem to affect users
  'The file "lookup"',
  'The file “lookup”', // beware of smart quotes from iOS >:(
  // Nothing actionable here
  'Failed to bind to the service.',
  // Nothing actionable here
  'Load failed',
  // Nothing actionable here
  'not available',
  // Nothing actionable here
  'ResizeObserver loop completed with undelivered notifications.',
  // Empty tiles from our custome tilesets
  /AJAXError:\s*\(403\):\s*https:\/\/(staging\.)?tiles\.watchduty\.org\/maptiles\//,
  // Safari Webkit bug - https://github.com/mapbox/mapbox-gl-js/issues/8480, https://bugs.webkit.org/show_bug.cgi?id=215771
  'Fetch is aborted',
];

export const initSentry = async (): Promise<void> => {
  if (!import.meta.env.VITE_SENTRY_DSN) {
    return;
  }

  const release = await getSentryReleaseName();

  const isNative = Capacitor.isNativePlatform();
  Sentry.init(
    {
      dsn: import.meta.env.VITE_SENTRY_DSN,
      environment: import.meta.env.VITE_SENTRY_ENV,
      release,
      // https://github.com/getsentry/sentry-capacitor/issues/63
      enableNative: isNative,
      integrations: [
        rewriteFramesIntegration({ iteratee: modifyStacktraceFilenames }),
      ],
      ignoreErrors: SENTRY_IGNORE_LIST,
      dist: '1', // we only vary release, not the dist. This matches the dist for source map bundles we upload,
      enableCaptureFailedRequests: false,
    },
    // Forward the init method from @sentry/react
    SentryReact.init,
  );
  Sentry.setTag('isNative', isNative);
};
