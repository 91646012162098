import {
  FloodCategory,
  FloodCategoryLevel,
} from 'features/riverGauges/riverGauges.types';

export const getFloodCategoryFromLevel = (
  level: number,
  categoryLevels: FloodCategoryLevel[],
): FloodCategory | null => {
  const sortedCategoryLevelsDescending = categoryLevels.sort(
    (a, b) => b.level - a.level,
  );
  const category = sortedCategoryLevelsDescending.find(
    (fc) => level >= fc.level,
  );
  return category?.category ?? null;
};
