import {
  ChangeEventHandler,
  MouseEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Button,
  Container,
  Grid,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import { Link, useHistory } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { defer } from 'lodash-es';
import { Capacitor } from '@capacitor/core';
import { formatToUSDollars, generateClientToken } from 'shared/utils';
import { useAuthState, useSnackbarState } from 'state';
import useDonationState from 'state/useDonationState';
import { API } from 'api';
import { LoadingAndErrors } from 'components/LoadingAndErrors';
import { User } from 'shared/types';
import useLocationSearchParams from 'hooks/useLocationSearchParams';

type DonationType = 'one-time' | 'monthly';

const ONE_DONATION_AMOUNTS = [10, 25, 50, 100];
const MONTHLY_DONATION_AMOUNTS = [5, 10, 20, 40];

const DEFAULT_DONATION_AMOUNT: Record<DonationType, number> = {
  'one-time': 25,
  monthly: 10,
};

const saveVerificationToken = async (
  userId: number,
  verificationToken: string,
): Promise<void> => {
  await API.post('recurly_integration/one_time_donation_token/', {
    userId,
    verificationToken,
  });
};

const getDonateLink = (
  donationAmount: string,
  donationRenews: string,
  token: string,
  isSupporter: boolean,
  user: User | null,
  continueOnWeb: boolean,
): string => {
  const params = new URLSearchParams({ donationAmount, donationRenews });
  if (isSupporter && user) {
    params.append('email', encodeURIComponent(user.email));
    if (user.firstName) {
      params.append('firstName', user.firstName);
    }
    if (user.lastName) {
      params.append('lastName', user.lastName);
    }
    params.append('userId', user.id.toString());
    params.append('verificationToken', token);
  } else {
    params.append('clientToken', token);

    // if this is a legacy user with no membership or prior donation, we should still pass along their info
    if (user) {
      params.append('email', encodeURIComponent(user.email));
    }
    if (user?.firstName) {
      params.append('firstName', user.firstName);
    }
    if (user?.lastName) {
      params.append('lastName', user.lastName);
    }
  }
  if (continueOnWeb) {
    return `${
      import.meta.env.VITE_BASE_WEB_URL
    }donate/account_info?${params.toString()}`;
  }
  return '/donate/account_info';
};

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
    overflowY: 'auto',
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(3),
    paddingBottom: 'max(env(safe-area-inset-bottom), 16px)',
  },
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
}));

const DonateContent = (): JSX.Element => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const [donationType, setDonationType] = useState<DonationType>('one-time');
  const [donationAmount, setDonationAmount] = useState(
    DEFAULT_DONATION_AMOUNT['one-time'],
  );
  const [customDonationAmount, setCustomDonationAmount] = useState(0);
  const [customAmountError, setCustomAmountError] = useState('');
  const [verificationError, setVerificationError] = useState(false);
  const { user, isSupporter } = useAuthState();
  const { updateDonationState } = useDonationState();
  const { setSnackbar } = useSnackbarState();
  const history = useHistory();

  // search params
  const searchParams = useLocationSearchParams();
  const paramFrequency = searchParams.get('frequency');
  const paramAmount = searchParams.get('amount');

  useEffect(() => {
    if (paramFrequency && paramAmount) {
      const frequency: DonationType =
        paramFrequency === 'onetime' ? 'one-time' : 'monthly';

      const isOneAmount: boolean = ONE_DONATION_AMOUNTS.some(
        (el) => el === Number(paramAmount),
      );

      const isMonthAmount: boolean = MONTHLY_DONATION_AMOUNTS.some(
        (el) => el === Number(paramAmount),
      );

      setDonationType(frequency);

      if (frequency === 'one-time') {
        if (!isOneAmount) {
          setCustomDonationAmount(Number(paramAmount));
          setDonationAmount(0);
        } else {
          setDonationAmount(Number(paramAmount));
        }
      }
      if (frequency === 'monthly') {
        if (!isMonthAmount) {
          setDonationAmount(DEFAULT_DONATION_AMOUNT.monthly);
        } else {
          setDonationAmount(Number(paramAmount));
        }
      }
    }
  }, [paramFrequency, paramAmount]);

  // this is used as either a verificationToken or a clientToken depending if they are already a supporter
  const tokenRef = useRef<string | null>(null);
  if (!tokenRef.current) {
    tokenRef.current = generateClientToken();
  }

  const { mutate: saveDonationToken, isPending } = useMutation({
    mutationFn: async (userId: number) => {
      setVerificationError(false);
      await saveVerificationToken(userId, tokenRef.current as string);
    },
    onError: () => {
      setSnackbar(t('common.unknownErrorTryAgain'), 'error');
      setVerificationError(true);
    },
  });

  const userId = user?.id;

  useEffect(() => {
    if (isSupporter && userId) {
      saveDonationToken(userId);
    }
  }, [saveDonationToken, userId, isSupporter]);

  const continueOnWeb = Capacitor.isNativePlatform();

  const handleCustomAmountChange: ChangeEventHandler<HTMLInputElement> = (
    event,
  ): void => {
    const { value } = event.target;
    if (value.split('.')[1]?.length > 2) {
      // Allow only 2 decimal places
      return;
    }
    const amount = Number(value);
    if (!isNaN(amount)) {
      setCustomDonationAmount(amount);
      if (amount === 0) {
        setDonationAmount(DEFAULT_DONATION_AMOUNT['one-time']);
      } else if (donationAmount) {
        setDonationAmount(0);
      }
    }
  };

  const handleNext = (): void => {
    if (continueOnWeb) {
      // Go back to main page - use case: user goes back to wd app
      // Defer action to prevent user from noticing the page change before
      // being redirected to the donate account information page
      defer(() => history.push('/'));
    } else if (isSupporter && user) {
      updateDonationState({
        donationAmount: customDonationAmount || donationAmount,
        donationRenews: donationType === 'monthly',
        email: user.email,
        firstName: user.firstName || '',
        lastName: user.lastName || '',
        existingUser: true,
        verificationToken: tokenRef.current as string,
        userId: user.id,
      });
    } else {
      updateDonationState({
        donationAmount: customDonationAmount || donationAmount,
        donationRenews: donationType === 'monthly',
        clientToken: tokenRef.current as string,
        existingUser: false,
      });
    }
  };

  const donateLink = getDonateLink(
    (customDonationAmount || donationAmount).toString(),
    donationType === 'monthly' ? 'true' : 'false',
    tokenRef.current as string,
    isSupporter,
    user,
    continueOnWeb,
  );

  if (isPending) {
    return <LoadingAndErrors isLoading />;
  }

  const donationAmounts =
    donationType === 'monthly'
      ? MONTHLY_DONATION_AMOUNTS
      : ONE_DONATION_AMOUNTS;

  return (
    <div className={classes.root}>
      <Container maxWidth="sm" className={classes.container}>
        <Grid
          container
          spacing={2}
          sx={{
            flexDirection: 'column',
            flex: 1,
            marginBottom: 3,
          }}
        >
          <Grid item>
            <Typography variant="h2">
              <b>{t('donate.title')}</b>
            </Typography>
          </Grid>

          <Grid item>
            <Typography>{t('donate.description')}</Typography>
          </Grid>

          <Grid item sx={{ marginBottom: -2 }}>
            <ToggleButtonGroup
              fullWidth
              exclusive
              value={donationType}
              onChange={(
                event: MouseEvent<HTMLElement>,
                newValue: string | null,
              ): void => {
                const newDonType = newValue as DonationType;
                setDonationType(newDonType);
                if (newDonType !== donationType) {
                  setDonationAmount(DEFAULT_DONATION_AMOUNT[newDonType]);
                }
                if (newDonType === 'monthly' && customDonationAmount) {
                  setCustomDonationAmount(0);
                }
              }}
            >
              <ToggleButton value="one-time">
                {t('donate.donationType.oneTime')}
              </ToggleButton>
              <ToggleButton value="monthly">
                {t('donate.donationType.monthly')}
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>

          <Grid item>
            <ToggleButtonGroup
              fullWidth
              exclusive
              variant="watchduty-gap"
              value={donationAmount}
              onChange={(
                event: MouseEvent<HTMLElement>,
                newValue: string | null,
              ): void => {
                if (newValue === null) return;
                setDonationAmount(parseInt(newValue as string, 10));
                if (customDonationAmount) {
                  setCustomDonationAmount(0);
                }
              }}
            >
              {donationAmounts.map((amount) => (
                <ToggleButton
                  key={`donate-${amount}`}
                  value={amount}
                  size="large"
                >
                  <b>{formatToUSDollars(amount)}</b>
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </Grid>

          {donationType === 'one-time' && (
            <Grid item>
              <TextField
                id="custom-donation-amount"
                label={t('donate.inputs.customAmount.label')}
                fullWidth
                type="number"
                required
                value={customDonationAmount || ''}
                error={!!customAmountError}
                helperText={customAmountError}
                onChange={handleCustomAmountChange}
                onBlur={() => {
                  if (customDonationAmount > 0 && customDonationAmount < 10) {
                    setCustomAmountError(
                      t('donate.inputs.customAmount.invalid'),
                    );
                  } else {
                    setCustomAmountError('');
                  }
                }}
                slotProps={{
                  input: {
                    startAdornment: customDonationAmount ? <>$</> : undefined,
                  },
                  htmlInput: { pattern: '\\d*' },
                }}
              />
            </Grid>
          )}

          <Grid item>
            <Typography
              data-testid="total-donation-amount"
              sx={{
                fontWeight: 'bold',
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: 2,
              }}
            >
              <span>{t('donate.total')}</span>
              <span>
                {formatToUSDollars(customDonationAmount || donationAmount)}
                {donationType === 'monthly' && (
                  <Typography component="span" color="secondary">
                    &nbsp;{t('donate.perMonth')}
                  </Typography>
                )}
              </span>
            </Typography>
          </Grid>
        </Grid>

        <Button
          fullWidth
          size="large"
          component={Link}
          to={continueOnWeb ? { pathname: donateLink } : donateLink}
          target={continueOnWeb ? '_blank' : undefined}
          onClick={handleNext}
          disabled={verificationError}
        >
          {t('common.next')}
        </Button>
      </Container>
    </div>
  );
};

export default DonateContent;
