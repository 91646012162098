import { Helmet } from 'react-helmet';
import {
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  ListSubheader,
  Box,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useHistory, useLocation } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useTranslation } from 'react-i18next';
import PageLayout from 'components/PageLayout';
import SearchBar from 'components/Map/SearchBar';
import { useMapSearchState } from 'state/useMapSearchState';
import { MapLocation, MapSearchApi, PlaceLocation } from 'shared/types';
import { placeLocationToMapLocation, removeAddressCountry } from 'shared/utils';
import { PlaceLocationPinIcon, getPlaceIcon } from 'components/Map/Icons';
import useMapPlaces from 'hooks/useMapPlaces';
import { useAuthState } from '../../../state';

const useStyles = makeStyles()((theme) => ({
  container: {
    paddingLeft: `env(safe-area-inset-left)`,
    paddingRight: `env(safe-area-inset-right)`,
    paddingTop: `env(safe-area-inset-top)`,
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    height: '100%',
  },
  searchbarContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px 16px 16px',
  },
  menuButton: {
    marginRight: theme.spacing(1),
    // hard-coding to prevent iOS Dynamic Type from resizing this
    width: 48,
    height: 48,
  },
  itemIconContainer: {
    minWidth: theme.spacing(4),
    position: 'relative',
  },
}));

const Content = (): JSX.Element => {
  const { state: { returnLink } = {} } = useLocation();
  const { classes } = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const {
    permissions: { isInternalUser },
  } = useAuthState();
  const { pristine, reset } = useMapSearchState();
  const { places } = useMapPlaces();

  const handlePlaceClick = (location: PlaceLocation): void => {
    const mapLocation = placeLocationToMapLocation(location);
    history.replace('/', mapLocation);
  };

  const handleResultClick = (locationDetails: MapLocation | null): void => {
    const link = returnLink || '/';
    history.replace(link, locationDetails);
  };

  const searchApi: MapSearchApi = isInternalUser ? 'google' : 'mapbox';
  return (
    <>
      <Helmet>
        <title>{t('mapSearchResults.title')}</title>
      </Helmet>

      <div className={classes.container}>
        <div className={classes.searchbarContainer}>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={() => {
              reset();
              // @ts-expect-error goBack is defined, safe to ignore
              history.goBack();
            }}
            size="large"
          >
            <ArrowBackIcon />
          </IconButton>

          <SearchBar
            fullScreenView
            includeGeoEvents
            searchApi={searchApi}
            returnLink={returnLink}
            onLocationChange={handleResultClick}
          />
        </div>

        {pristine && places.length > 0 && (
          <List
            dense
            subheader={
              <ListSubheader>
                <Typography variant="h3" color="textPrimary">
                  {t('mapSearchResults.places')}
                </Typography>
              </ListSubheader>
            }
          >
            {places.map((place) => {
              const icon = getPlaceIcon(place.icon);
              return (
                <ListItemButton
                  key={place.id}
                  alignItems="flex-start"
                  onClick={() => handlePlaceClick(place)}
                >
                  <ListItemIcon
                    sx={(theme) => ({ minWidth: theme.spacing(4) })}
                  >
                    <Box sx={{ position: 'relative' }}>
                      <img
                        src={PlaceLocationPinIcon.data}
                        alt="Place"
                        width={PlaceLocationPinIcon.width}
                        height={PlaceLocationPinIcon.height}
                      />
                      <img
                        src={icon.data}
                        alt={place.name}
                        width={icon.width}
                        height={icon.height}
                        style={{
                          position: 'absolute',
                          left: `calc(50% - ${icon.width / 2}px)`,
                          top: `calc(50% - ${icon.height}px)`,
                        }}
                      />
                    </Box>
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography>
                        <b>{place.name}</b>
                      </Typography>
                    }
                    secondary={removeAddressCountry(
                      place.address.formattedAddress || place.address.name,
                    )}
                  />
                </ListItemButton>
              );
            })}
          </List>
        )}
      </div>
    </>
  );
};

const MapSearchResults = (): JSX.Element => {
  return (
    <PageLayout data-cname="MapSearchResults">
      {{ content: <Content /> }}
    </PageLayout>
  );
};

export default MapSearchResults;
