import { TFunction } from 'i18next';
import { subDays } from 'date-fns';
import { AlertCamera } from 'hooks/useAlertCameras';

const ATTRIBUTION_TEXT = {
  UCSD: 'ALERTCalifornia | UC San Diego',
  ALERTWILDFIRE: 'ALERT Wildfire',
  UO: 'ALERTWest | OHAZ | University of Oregon',
  UNR: 'ALERTWest | Nevada Seismological Laboratory | University of Nevada, Reno',
  ALERTWEST: 'ALERTWest',
};

export const getSimplifiedCameraNames = (
  cameras: AlertCamera[],
  t: TFunction,
): AlertCamera[] => {
  const defaultName = t('alertCameras.main');

  const firstCameraCharacters = cameras[0].name.split('');

  const commonCharacters = firstCameraCharacters.reduce((acc, character) => {
    const isCommon = cameras.every((camera) =>
      camera.name.startsWith(acc + character),
    );

    return isCommon ? acc + character : acc;
  }, '');

  if (commonCharacters.trim() === '') {
    return cameras;
  }

  const simplified = cameras.map((camera) => {
    const simpleName =
      camera.name.replace(commonCharacters, '').trim() || defaultName;
    return {
      ...camera,
      name: simpleName,
    };
  });

  if (simplified.filter((c) => c.name === defaultName).length > 1) {
    return cameras;
  }

  return simplified.map((camera) => ({
    ...camera,
    name: camera.name
      .replace('(', '')
      .replace(')', '')
      .replace('[', '')
      .replace(']', '')
      .trim(),
  }));
};

export const getRelatedCameras = (
  camera: AlertCamera,
  allCameras: AlertCamera[],
  t: TFunction,
): AlertCamera[] => {
  if (allCameras.length === 0) return [];

  const relatedCameras = allCameras.filter(
    (c) =>
      c.approximateLatlng.lat === camera.approximateLatlng.lat &&
      c.approximateLatlng.lng === camera.approximateLatlng.lng,
  );

  return getSimplifiedCameraNames(relatedCameras, t).sort(
    (a: AlertCamera, b: AlertCamera) => a.name.localeCompare(b.name),
  );
};

export const getCameraAttributionImage = (
  camera: AlertCamera,
  imgUrls: Record<string, string>,
): string | null => {
  const attributionImageUrl = imgUrls[camera.attribution.toUpperCase()];

  if (attributionImageUrl) {
    return attributionImageUrl;
  }

  if (camera.hasPtz && camera.provider === 'alertwest') {
    return imgUrls.ALERTWEST;
  }

  return null;
};

export const isCameraOffline = (timestamp: Date): boolean =>
  timestamp.getTime() < subDays(new Date(), 1).getTime();

export const isTimelapseSupported = (camera: AlertCamera): boolean =>
  camera.provider === 'alertwest';

export const getCameraAttributionLabelTransKey = (
  camera: AlertCamera,
): string => {
  const attributionToLabelTransKey = {
    UCSD: 'alertCameras.operatedBy',
    ALERTWEST: 'alertCameras.operatedBy',
    ALERTWILDFIRE: 'alertCameras.operatedBy',
    UO: 'alertCameras.operatedAndProvidedBy',
    UNR: 'alertCameras.operatedAndProvidedBy',
  };

  return (
    attributionToLabelTransKey[camera.attribution] || 'alertCameras.operatedBy'
  );
};

export const getCameraAttributionRichText = (camera: AlertCamera): string => {
  const attributionText =
    ATTRIBUTION_TEXT[camera.attribution.toUpperCase()] || '';

  const cameraUrl = `${import.meta.env.VITE_BASE_WEB_URL}camera/${camera.id}`;
  const attributionSuffix = attributionText ? ` - ${attributionText}` : '';

  return `<p><a href="${cameraUrl}">${camera.name}</a>${attributionSuffix}</p>`;
};

export const parseReplayTime = (seconds: number, t: TFunction): string => {
  const hours = Math.floor(seconds / 60 / 60);
  if (hours) return t('common.hours', { count: hours });
  const minutes = Math.floor((seconds / 60) % 60);
  return t('common.minutes', { count: minutes });
};
