import {
  useState,
  useMemo,
  ChangeEvent,
  useCallback,
  useRef,
  UIEvent,
  useEffect,
} from 'react';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  useTheme,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { NotificationOption, NotificationSetting } from 'shared/types';
import { useNotifications } from 'hooks/useNotifications';
import { getSortedRegionsSections } from 'shared/utils';
import { useAuthState, useSnackbarState } from 'state';
import SearchIcon from '@mui/icons-material/Search';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { debounce, isEqual, differenceWith } from 'lodash-es';
import { useSessionStorage } from 'hooks/useSessionStorage';
import { useBlocker } from 'hooks/useBlocker';
import RegionList from './RegionList';
import RegionListItem from './RegionListItem';
import { NotificationTypes, MEMBERSHIP_PLANS } from '../../constants';

type RegionSelectorProps = {
  regionSettings: NotificationSetting[];
  onboarding?: boolean;
  maxChecked?: number;
};

const MAX_SUBSCRIBED_REGIONS_FOR_NON_MEMBERS = 4;
const REGION_SETTINGS_STORAGE_KEY = 'REGION_SETTINGS';
const COLLAPSED_LISTS_STORAGE_KEY = 'COLLAPSED_LISTS';

const useStyles = makeStyles()((theme) => ({
  container: {
    paddingTop: theme.spacing(3),
    paddingBottom: 'max(env(safe-area-inset-bottom), 16px)',
    width: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  lists: {
    overflowY: 'auto',
    flex: 1,
  },
  subheader: {
    lineHeight: theme.typography.pxToRem(26),
  },
  search: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  shadowTop: {
    boxShadow: '0px 4px 4px 0px #00000040',
  },
  shadowBottom: {
    boxShadow: '0px -4px 4px 0px #00000040',
  },
}));

const updateRegionSettings = (
  existingSettings: NotificationSetting[],
  newSetting: NotificationSetting,
): NotificationSetting[] =>
  existingSettings.map((regionSetting) => {
    if (regionSetting.region.id === newSetting.region.id) {
      return newSetting;
    }

    return regionSetting;
  });

const RegionSelector = (props: RegionSelectorProps): JSX.Element => {
  const {
    regionSettings,
    onboarding,
    maxChecked = MAX_SUBSCRIBED_REGIONS_FOR_NON_MEMBERS,
  } = props;
  const { classes } = useStyles();
  const { putNotificationSettings } = useNotifications();
  const { mutate, isPending } = putNotificationSettings;
  /**
   * Prompt blocker changes the app's route before prompting the user,
   * if the user cancels navigation, the current page route is restored
   * which causes a re-render, and the page state is reset. We use the
   * session storage to avoid losing the state changes.
   * Similar issue: https://github.com/remix-run/react-router/issues/5405
   */
  const [regionsState, setRegionsState] = useSessionStorage<
    NotificationSetting[]
  >(REGION_SETTINGS_STORAGE_KEY, regionSettings);
  const [search, setSearch] = useState<string>('');
  const [scrollPosition, setScrollPosition] = useState({
    top: true,
    bottom: false,
  });
  const [isScrollable, setIsScrollable] = useState(false);
  const [collapsedLists, setCollapsedLists] = useSessionStorage<
    Record<string, boolean>
  >(COLLAPSED_LISTS_STORAGE_KEY, {});
  const listsBoxRef = useRef<HTMLDivElement>(null);
  const savedPressedRef = useRef(false);
  const { t } = useTranslation();
  const { showMembershipFeatures } = useAuthState();
  const { setSnackbar } = useSnackbarState();
  const history = useHistory();
  const theme = useTheme();

  const pendingChanges = !isEqual(regionsState, regionSettings);

  const resetLocalState = useCallback(() => {
    sessionStorage.removeItem(REGION_SETTINGS_STORAGE_KEY);
    sessionStorage.removeItem(COLLAPSED_LISTS_STORAGE_KEY);
  }, []);

  useBlocker({
    shouldBlockNavigation: !savedPressedRef.current && pendingChanges,
    confirmationMessage: t(
      'notificationSettingsRegionAdd.unsavedChangesMessage',
    ),
    onConfirm: resetLocalState,
  });

  const numChecked = regionsState.filter(
    (r) => r.setting !== NotificationTypes.OFF.key,
  ).length;

  const transitionDuration = theme.transitions.duration.standard;

  const handleToggleCollapse = useCallback(
    (sectionTitle?: string): void => {
      if (sectionTitle) {
        setCollapsedLists({
          ...collapsedLists,
          [sectionTitle]: !collapsedLists[sectionTitle],
        });
      }
      setTimeout(() => {
        const containerElement = listsBoxRef.current;
        const canScroll = containerElement
          ? containerElement.scrollHeight >
            containerElement.getBoundingClientRect().height
          : false;
        setIsScrollable(canScroll);
        // Fire after collapse has hidden content
      }, transitionDuration);
    },
    [collapsedLists, setCollapsedLists, transitionDuration],
  );

  useEffect(() => {
    handleToggleCollapse();
  }, [handleToggleCollapse]);

  const debouncedSetScrollPosition = useMemo(
    () => debounce(setScrollPosition, transitionDuration, { leading: true }),
    [transitionDuration],
  );

  const handleScroll = (event: UIEvent<HTMLDivElement>): void => {
    if (!event.target) return;
    const { scrollTop, scrollHeight, clientHeight } =
      event.target as HTMLDivElement;
    debouncedSetScrollPosition({
      top: scrollTop === 0,
      bottom: scrollTop + clientHeight >= scrollHeight,
    });
  };

  const handleToggleRegion = (regionSetting: NotificationSetting): void => {
    const wasChecked = regionSetting.setting !== NotificationTypes.OFF.key;

    // eslint-disable-next-line prefer-destructuring
    let key: NotificationOption = NotificationTypes.OFF.key;
    if (!wasChecked) {
      const originalKeySetting = regionSettings.find(
        (rSetting) => rSetting.region.id === regionSetting.region.id,
      )?.setting;

      if (
        originalKeySetting &&
        originalKeySetting !== NotificationTypes.OFF.key
      ) {
        key = originalKeySetting;
      } else {
        key = NotificationTypes.ALL.key;
      }
    }

    // Currently selected items plus the one we want to add
    const updatedNumCheck = numChecked + 1;
    if (
      !showMembershipFeatures &&
      key !== NotificationTypes.OFF.key &&
      updatedNumCheck > maxChecked
    ) {
      if (onboarding) {
        setSnackbar(
          t('notifications.regionLimitReached', {
            maxCounties: maxChecked,
          }),
          'error',
        );
        return;
      }
      history.push(`/support_us/${MEMBERSHIP_PLANS.yearly}`);
      return;
    }

    const newRegionSetting: NotificationSetting = {
      ...regionSetting,
      setting: key,
    };

    setRegionsState(updateRegionSettings(regionsState, newRegionSetting));
  };

  const regionSettingsSections = useMemo(
    () => getSortedRegionsSections(regionsState, search),
    [regionsState, search],
  );

  const handleSearch = (event: ChangeEvent<HTMLInputElement>): void => {
    const searchText = event.target.value.toLowerCase();
    setSearch(searchText);
  };

  const handleSave = (): void => {
    savedPressedRef.current = true;

    // order matters here
    const updatedSettings = differenceWith(
      regionsState,
      regionSettings,
      isEqual,
    );

    mutate(updatedSettings, {
      onSuccess: () => {
        resetLocalState();
        // @ts-expect-error - goBack is defined
        history.goBack();
      },
      onError: () => {
        savedPressedRef.current = false;
      },
    });
  };

  return (
    <>
      <Box
        className={classes.container}
        sx={{ pointerEvents: isPending ? 'none' : 'auto' }}
      >
        <Box
          className={
            isScrollable && !scrollPosition.top ? classes.shadowTop : undefined
          }
        >
          <TextField
            placeholder={t('notificationSettingsRegionAdd.search')}
            fullWidth
            className={classes.search}
            onChange={handleSearch}
            sx={{ marginBottom: 3 }}
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: search ? (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setSearch('')}
                      size="small"
                      color="inherit"
                      aria-label={t(
                        'notificationSettingsRegionAdd.clearSearch',
                      )}
                    >
                      <HighlightOffIcon />
                    </IconButton>
                  </InputAdornment>
                ) : null,
                value: search,
              },
              htmlInput: {
                'aria-label': t('notificationSettingsRegionAdd.search'),
              },
              inputLabel: { shrink: false },
            }}
          />
        </Box>

        <Box
          className={classes.lists}
          onScroll={handleScroll}
          ref={listsBoxRef}
          aria-label={t('notificationSettingsRegionAdd.countiesLists')}
        >
          {regionSettingsSections.map((regionSection) => (
            <RegionList
              key={regionSection.title}
              name={regionSection.title}
              onToggleCollapse={() => handleToggleCollapse(regionSection.title)}
              expanded={!!search || collapsedLists[regionSection.title]}
            >
              {regionSection.regionSettings.map((regionSetting) => (
                <RegionListItem
                  key={regionSetting.region.id}
                  onChange={handleToggleRegion}
                  regionSetting={regionSetting}
                />
              ))}
            </RegionList>
          ))}
        </Box>

        <Box
          sx={{ padding: 2, paddingBottom: 0 }}
          className={
            isScrollable && !scrollPosition.bottom
              ? classes.shadowBottom
              : undefined
          }
        >
          <Button
            fullWidth
            size="large"
            onClick={handleSave}
            disabled={!pendingChanges}
            sx={{ fontWeight: 'bold' }}
          >
            {isPending ? (
              <CircularProgress color="inherit" size={24} />
            ) : (
              t('common.save')
            )}
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default RegionSelector;
