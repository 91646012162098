import { useRouteMatch } from 'react-router-dom';
import { ROUTER_ENTITY_TO_ROUTE_PATH_MAP } from '../constants';

type LocationGeoEventParams = {
  locationId?: string;
};

export const useSelectedLocationGeoEventId = (): number | undefined => {
  // Using `useRouteMatch()` instead of `useParams()` since we're not always inside the Location <Router>
  const match = useRouteMatch<LocationGeoEventParams>(
    ROUTER_ENTITY_TO_ROUTE_PATH_MAP.location,
  );
  const locationIdStr = match?.params.locationId;

  return locationIdStr ? parseInt(locationIdStr, 10) : undefined;
};
