import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Container,
  Grid,
  Typography,
  FormControl,
  TextField,
  Button,
  Slider as MuiSlider,
  FormLabel,
  FormHelperText,
  Autocomplete,
} from '@mui/material';
import { makeStyles, withStyles } from 'tss-react/mui';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { noop, debounce } from 'lodash-es';
import { grey } from '@mui/material/colors';
import { GeoEvent, LatLng, Report } from 'shared/types';
import {
  useAuthState,
  useCacheState,
  useMapState,
  useSnackbarState,
} from 'state';
import { getDateFormatted, getTimePass } from 'shared/dates';
import { API } from 'api';
import Map from 'components/Map';
import { LoadingAndErrors } from 'components/LoadingAndErrors';
import BlackButton from 'components/BlackButton';
import { VisuallyBusyMapLayers } from 'components/Map/constants';
import { useHistory } from 'react-router-dom';

import GrayButton from 'components/GrayButton';
import { DynamicMapCenter } from 'components/Map/DynamicMapCenter';
import { TEXTAREA_MAX_ROWS } from '../../../../constants';

type ModerateReportFormProps = {
  report: Report;
};

type FormValues = {
  geoEventId: number | null;
  message: string;
  lat: string;
  lng: string;
  az: number;
};

type ReportUpdate = Partial<Report> & { id: number };

// todo: react-query function type returns can be addressed after react-query 5 upgrade
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const fetchGeoEvents = () => API.get('geo_events/search?limit=250');

const parseNumericString = (value: string): number => {
  if (!value) return 0;
  const parsedNumber = parseFloat(value);
  if (isNaN(parsedNumber)) return 0;
  return parsedNumber;
};

const isValidLat = (value: number): boolean => {
  return value >= -90 && value <= 90;
};

const isValidLng = (value: number): boolean => {
  return value >= -180 && value <= 180;
};

const getDefaultMapCenter = (incidentMap: {
  center: { lat: number; lng: number };
}): LatLng => {
  const { lat } = incidentMap.center;
  const { lng } = incidentMap.center;

  if (lat && lng) {
    return { lat, lng };
  }

  return Map.DefaultConfig.center;
};

const Slider = withStyles(MuiSlider, {
  root: {
    height: 8,
    padding: '14px 0px 8px',
  },
  thumb: {
    width: 16,
    height: 16,
    marginLeft: -1,
  },
  track: {
    height: 6,
    borderRadius: 4,
  },
  rail: {
    height: 6,
    borderRadius: 4,
    color: grey[800],
  },
});

const useStyles = makeStyles()((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    width: '100%',
    overflow: 'auto',
    paddingTop: theme.spacing(2),
    paddingBottom: 'max(env(safe-area-inset-bottom), 16px)',
  },
  imagePreview: {
    minHeight: 400,
    width: '100%',
    borderRadius: theme.shape.borderRadius * 2,
  },
  mapContainer: {
    width: '100%',
    height: 253,
    borderRadius: theme.shape.borderRadius * 1.34,
    overflow: 'hidden',
  },
}));

const ModerateReportForm = (props: ModerateReportFormProps): JSX.Element => {
  const { report } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();
  const {
    permissions: { canModerate },
  } = useAuthState();
  const { incidentMapState } = useMapState();
  const [initialMapCenter] = useState(getDefaultMapCenter(incidentMapState));
  const history = useHistory();
  const { setCacheState } = useCacheState();
  const queryClient = useQueryClient();
  const { setSnackbar } = useSnackbarState();

  const media = report.media[0];

  const [latitude, setLatitude] = useState(media.lat || 0);
  const [longitude, setLongitude] = useState(media.lng || 0);

  const initialValues: FormValues = {
    geoEventId: report.geoEventId,
    message: report.message || '',
    lat: latitude.toString() || '',
    lng: longitude.toString() || '',
    az: media.az || 0,
  };

  const validationSchema = Yup.object().shape(
    {
      geoEventId: Yup.number()
        .nullable()
        .required(t('moderateReport.inputs.incident.required')),
      message: Yup.string()
        .required(t('moderateReport.inputs.description.required'))
        .min(3, t('moderateReport.inputs.description.minError')),
      lat: Yup.number()
        .typeError(t('moderateReport.inputs.latitude.typeError'))
        .min(-90, t('moderateReport.inputs.latitude.minError'))
        .max(90, t('moderateReport.inputs.latitude.maxError'))
        .when('lng', {
          is: (lng?: string) => !!lng,
          then: Yup.number()
            .transform(parseNumericString)
            .nullable()
            .required(t('moderateReport.inputs.latitude.required')),
          otherwise: Yup.number().transform(parseNumericString).nullable(),
        }),
      lng: Yup.number()
        .typeError(t('moderateReport.inputs.longitude.typeError'))
        .min(-180, t('moderateReport.inputs.longitude.minError'))
        .max(180, t('moderateReport.inputs.longitude.maxError'))
        .when('lat', {
          is: (lat?: string) => !!lat,
          then: Yup.number()
            .transform(parseNumericString)
            .nullable()
            .required(t('moderateReport.inputs.longitude.required')),
          otherwise: Yup.number().transform(parseNumericString).nullable(),
        }),
      az: Yup.number()
        .typeError(t('moderateReport.inputs.azimuth.typeError'))
        .min(0, t('moderateReport.inputs.azimuth.minError'))
        .max(360, t('moderateReport.inputs.azimuth.maxError'))
        .nullable()
        .notRequired()
        .test(
          'has-lat-and-lng',
          t('moderateReport.inputs.azimuth.required'),
          (value, context) =>
            !value || (context.parent.lat && context.parent.lng),
        ),
    },
    [['lat', 'lng']],
  );

  const { control, handleSubmit, setValue, watch } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
    mode: 'onBlur',
  });

  useEffect(() => {
    /**
     * Edge case: admin updates the media lat/lng on the api's admin dashboard.
     * We want to reflect this change on the form and map - fixes initial values
     * issues because of the react-query cache.
     */
    setLatitude(media.lat || 0);
    setLongitude(media.lng || 0);
    setValue('lat', media.lat?.toString() || '');
    setValue('lng', media.lng?.toString() || '');
  }, [media, setValue]);

  const deleteReportMutation = useMutation({
    mutationFn: (id: number) => API.delete(`reports/${id}/`),
  });

  const updateReportMutation = useMutation({
    mutationFn: ({ id, ...params }: ReportUpdate) =>
      API.patch(`reports/${id}`, params),
    onSuccess: () => {
      setCacheState(null);
      queryClient.invalidateQueries({
        queryKey: ['report', report.id],
      });
    },
  });

  const { data, isLoading: geoEventsLoading } = useQuery({
    queryKey: ['search_geo_events'],
    queryFn: fetchGeoEvents,
    enabled: canModerate,
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });

  const handleApproveReport = async (
    values: FormValues,
    showInReportsView: boolean,
  ): Promise<void> => {
    const updateData: ReportUpdate = {
      id: report.id,
      message: values.message,
      showInReportsView,
    };
    if (values.geoEventId) updateData.geoEventId = values.geoEventId;
    updateData.media = [
      {
        ...media,
        lat: values.lat ? parseFloat(values.lat) : null,
        lng: values.lng ? parseFloat(values.lng) : null,
        az: values.az ?? null,
      },
    ];
    await updateReportMutation.mutateAsync(updateData);
    setSnackbar('Report Updated', 'success');
    history.push(`/i/${values.geoEventId}`);
  };

  const updateLatitude = useMemo(() => debounce(setLatitude, 500), []);
  const updateLongitude = useMemo(() => debounce(setLongitude, 500), []);

  const handleCoordsChange = (e: ChangeEvent<HTMLInputElement>): void => {
    // Allow users to paste `lat, lng` as a string and lets parse it for them
    const val = e.target.value;
    const name = e.target.name as 'lat' | 'lng';

    const latLng = val.split(/, ?/);

    if (latLng.length === 2) {
      updateLatitude(parseNumericString(latLng[0]));
      updateLongitude(parseNumericString(latLng[1]));
      setValue('lat', latLng[0]);
      setValue('lng', latLng[1]);
      return;
    }

    setValue(name, val);

    if (name === 'lat') updateLatitude(parseNumericString(val));
    if (name === 'lng') updateLongitude(parseNumericString(val));
  };

  const handleRejectReport = async (): Promise<void> => {
    // `confirm` is super ghetto but its only seen by CIO's
    // eslint-disable-next-line
    const r = confirm('Are you sure you want to delete?');
    if (r === true) {
      await deleteReportMutation.mutateAsync(report.id);
      // @ts-expect-error go back is indeed defined
      history.goBack();
    }
  };

  const geoEvents = (data?.data.results || []) as GeoEvent[];
  const azimuth = watch('az');
  const geoEventId = watch('geoEventId');
  const selectedGeoEvent = geoEvents.find(
    (geoEvent) => geoEvent.id === geoEventId,
  );

  if (geoEventsLoading) {
    return <LoadingAndErrors isLoading />;
  }

  const mapLat = isValidLat(latitude) ? latitude : null;
  const mapLng = isValidLng(longitude) ? longitude : null;

  return (
    <Box className={classes.root}>
      <Container maxWidth="sm">
        <form
          onSubmit={handleSubmit((values) =>
            handleApproveReport(values, false),
          )}
          noValidate
        >
          <Grid container spacing={2} direction="column">
            <Grid item>
              <Box>
                <img
                  src={media.url}
                  alt="uploaded"
                  className={classes.imagePreview}
                />
              </Box>

              {canModerate && report?.dateCreated && (
                <Box sx={{ marginTop: 1 }}>
                  <Typography
                    variant="subtitle1"
                    color="textSecondary"
                    component="h5"
                  >
                    {t('moderateReport.reported')}&nbsp;
                    {getDateFormatted(report.dateCreated)}&nbsp;
                    <i>({getTimePass(report.dateCreated)})</i>
                  </Typography>
                </Box>
              )}
            </Grid>

            <Grid item>
              <Controller
                name="geoEventId"
                control={control}
                render={({ field, fieldState }): JSX.Element => {
                  const hasError = !!fieldState.error;
                  return (
                    <FormControl fullWidth required>
                      <Autocomplete
                        id="geoEventId"
                        options={geoEvents}
                        getOptionLabel={(geoEvent: GeoEvent): string =>
                          `${geoEvent.name}${
                            geoEvent.address ? `, ${geoEvent.address}` : ''
                          }`
                        }
                        isOptionEqualToValue={(
                          option,
                          value: GeoEvent | null,
                        ): boolean => {
                          return option.id === value?.id;
                        }}
                        value={selectedGeoEvent || null}
                        onChange={(e, value): void => field.onChange(value?.id)}
                        renderInput={(params): JSX.Element => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label={t('moderateReport.inputs.incident.label')}
                            required
                            error={hasError}
                            helperText={
                              hasError ? fieldState.error?.message : undefined
                            }
                          />
                        )}
                      />
                    </FormControl>
                  );
                }}
              />
            </Grid>

            <Grid item>
              <Controller
                name="message"
                control={control}
                render={({ field, fieldState }): JSX.Element => {
                  const { ref, ...muiFieldProps } = field;
                  return (
                    <TextField
                      id="field-control-message"
                      label={t('moderateReport.inputs.description.label')}
                      fullWidth
                      {...muiFieldProps}
                      inputRef={ref}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      required
                      multiline
                      minRows={4}
                      maxRows={TEXTAREA_MAX_ROWS}
                    />
                  );
                }}
              />
            </Grid>

            <Grid item>
              <Box sx={{ marginTop: 2 }}>
                <Typography variant="h3">
                  {t('moderateReport.location')}
                </Typography>
              </Box>
            </Grid>

            <Grid item>
              <Box className={classes.mapContainer}>
                <Map
                  center={initialMapCenter}
                  disableMapLayers={VisuallyBusyMapLayers}
                  noControls
                  withPlaces={false}
                  zoomControlEnabled
                >
                  <DynamicMapCenter lat={mapLat} lng={mapLng} />

                  {selectedGeoEvent && (
                    <Map.WildFireGeoEventMarker geoEvent={selectedGeoEvent} />
                  )}

                  {report && mapLat && mapLng && (
                    <Map.ReportMarker
                      key={report.id}
                      media={report.media}
                      onClick={noop}
                      isFadable={false}
                      lat={mapLat}
                      lng={mapLng}
                      az={azimuth}
                      overrideCoords
                    />
                  )}
                </Map>
              </Box>
            </Grid>

            <Grid item container spacing={2}>
              <Grid item xs={6}>
                <Controller
                  name="lat"
                  control={control}
                  render={({ field, fieldState }): JSX.Element => {
                    const { ref, ...muiFieldProps } = field;
                    return (
                      <TextField
                        id="field-control-lat"
                        label={t('moderateReport.inputs.latitude.label')}
                        fullWidth
                        {...muiFieldProps}
                        inputRef={ref}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        placeholder="00.000000"
                        onChange={handleCoordsChange}
                        slotProps={{
                          htmlInput: { pattern: '[0-9]+([.,][0-9]+)?' },
                        }}
                      />
                    );
                  }}
                />
              </Grid>

              <Grid item xs={6}>
                <Controller
                  name="lng"
                  control={control}
                  render={({ field, fieldState }): JSX.Element => {
                    const { ref, ...muiFieldProps } = field;
                    return (
                      <TextField
                        id="field-control-lng"
                        label={t('moderateReport.inputs.longitude.label')}
                        fullWidth
                        {...muiFieldProps}
                        inputRef={ref}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        placeholder="00.000000"
                        onChange={handleCoordsChange}
                        slotProps={{
                          htmlInput: { pattern: '[0-9]+([.,][0-9]+)?' },
                        }}
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>

            <Grid item>
              <Controller
                name="az"
                control={control}
                render={({ field, fieldState }): JSX.Element => {
                  return (
                    <Box sx={{ width: '100%', marginBottom: 2 }}>
                      <FormControl fullWidth>
                        <FormLabel component="legend">
                          <Typography variant="body2" color="textSecondary">
                            {t('moderateReport.inputs.azimuth.label')}
                          </Typography>
                        </FormLabel>
                        <Slider
                          value={field.value}
                          onChange={(_, newValue: number | number[]): void => {
                            const azValue =
                              typeof newValue === 'number'
                                ? newValue
                                : newValue[0];
                            field.onChange(azValue);
                          }}
                          max={360}
                          min={0}
                        />
                        {!!fieldState.error?.message && (
                          <FormHelperText error>
                            {fieldState.error.message}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Box>
                  );
                }}
              />
            </Grid>

            <Grid item>
              <Button type="submit" fullWidth size="large">
                {t('moderateReport.buttons.approvePostMap')}
              </Button>
            </Grid>

            <Grid item>
              <GrayButton
                fullWidth
                size="large"
                onClick={() =>
                  handleSubmit((values) => handleApproveReport(values, true))()
                }
              >
                {t('moderateReport.buttons.approveAndPost')}
              </GrayButton>
            </Grid>

            <Grid item>
              <BlackButton fullWidth size="large" onClick={handleRejectReport}>
                {t('moderateReport.buttons.reject')}
              </BlackButton>
            </Grid>
          </Grid>
        </form>
      </Container>
    </Box>
  );
};

export default ModerateReportForm;
