import { Stack, IconButton, Button } from '@mui/material';
import { bindTrigger } from 'material-ui-popup-state/hooks';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import StopIcon from '@mui/icons-material/Stop';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CameraFullScreenButton } from './CameraFullScreenButton';
import { parseReplayTime } from './CameraDetails.utils';
import { TimelapseSlider } from './TimelapseSlider';
import { CameraPlayerControlCommonProps } from './CameraPlayerControl.types';

type CameraPlayerControlsProps = CameraPlayerControlCommonProps & {
  fullScreen: boolean;
  handleStopTimelapse: () => void;
};

const useStyles = makeStyles<{ fullScreen: boolean }>()(
  (theme, { fullScreen }) => ({
    button: {
      borderRadius: theme.shape.borderRadius * 2,
      backgroundColor: theme.palette.grey[300],
      '&:hover': {
        backgroundColor: theme.palette.grey[500],
      },
      '&:disabled': {
        backgroundColor: theme.palette.grey[700],
      },
    },
    replayTimeButton: {
      fontWeight: 'bold',
      '.MuiButton-endIcon': {
        marginLeft: '2px',
      },
      '&:disabled': {
        color: fullScreen
          ? theme.palette.background.paper
          : theme.palette.text.primary,
      },
    },
  }),
);

export const CameraPlayerControls = (
  props: CameraPlayerControlsProps,
): JSX.Element => {
  const {
    camera,
    fullScreen,
    imageTimestamp,
    isLoading,
    playerStatus,
    popupState,
    timelapseFramesLength,
    timelapseFrameNumber,
    timelapseReplayTime,
    handleSliderChange,
    handleStopTimelapse,
    handleTogglePlay,
  } = props;
  const fullscreenButtonVisible = !fullScreen;
  const { classes } = useStyles({ fullScreen });
  const { t } = useTranslation();

  return (
    <>
      <TimelapseSlider
        imageTimestamp={imageTimestamp}
        isLoading={isLoading}
        playerStatus={playerStatus}
        timelapseFrameNumber={timelapseFrameNumber}
        timelapseFramesLength={timelapseFramesLength}
        handleSliderChange={handleSliderChange}
        dark={fullScreen}
      />

      <Stack
        direction="row"
        spacing={1}
        sx={{
          alignItems: 'center',
          justifyContent: 'space-between',
          marginTop: 1,
        }}
      >
        <Stack direction="row" spacing={1}>
          <IconButton
            className={classes.button}
            onClick={handleTogglePlay}
            disabled={isLoading}
            aria-label={
              playerStatus === 'playingTimelapse'
                ? t('alertCameras.pauseTimelapse')
                : t('alertCameras.playTimelapse')
            }
          >
            {playerStatus === 'playingTimelapse' ? (
              <PauseIcon fontSize="medium" sx={{ color: 'text.primary' }} />
            ) : (
              <PlayArrowIcon fontSize="medium" sx={{ color: 'text.primary' }} />
            )}
          </IconButton>
          {playerStatus !== 'streamingLive' && (
            <IconButton
              className={classes.button}
              onClick={handleStopTimelapse}
              disabled={isLoading}
              aria-label={t('alertCameras.stopTimelapse')}
            >
              <StopIcon fontSize="medium" sx={{ color: 'text.primary' }} />
            </IconButton>
          )}
        </Stack>

        <Stack direction="row">
          <Button
            {...bindTrigger(popupState)}
            disabled={isLoading}
            variant="text"
            color="inherit"
            size="small"
            endIcon={
              <ExpandMoreIcon
                fontSize="medium"
                sx={{ color: fullScreen ? 'background.paper' : 'text.primary' }}
              />
            }
            className={classes.replayTimeButton}
          >
            {parseReplayTime(timelapseReplayTime, t)}
          </Button>

          {fullscreenButtonVisible && (
            <CameraFullScreenButton cameraId={camera.id} disabled={isLoading} />
          )}
        </Stack>
      </Stack>
    </>
  );
};
