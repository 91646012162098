import { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react';
import { Box, Drawer, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { BottomSheet } from 'react-spring-bottom-sheet';
import { RefHandles, SpringEvent } from 'react-spring-bottom-sheet/dist/types';
import { Keyboard } from '@capacitor/keyboard';
import { useTranslation } from 'react-i18next';
import { usePoisState } from 'state/usePoisState';
import { Capacitor } from '@capacitor/core';
import useSearchDrawerState from 'state/useSearchDrawerState';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import useForegroundState from 'hooks/useForegroundState';
import usePrevious from 'hooks/usePrevious';
import { useSelectedWildfireGeoEventId } from 'hooks/useSelectedWildfireGeoEventId';
import { useWindowSize } from 'usehooks-ts';
import {
  SEARCH_DRAWER_WIDTH,
  getSearchCardMinSnapPx,
} from '../../../constants';
import { SearchResults } from './SearchResults';
import { SearchBar } from './SearchBar';

const SEARCH_DRAWER_ID = 'search-bottom-sheet';
const DRAWER_SNAP_QUICK_VELOCITY = 9999;

const useStyles = makeStyles<{ open: boolean }>()((theme, { open }) => ({
  drawerContainer: {
    position: 'relative',
    transition: theme.transitions.create('transform', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    transform: `translateX(${SEARCH_DRAWER_WIDTH}px)`,
    ...(open && {
      transition: theme.transitions.create('transform', {
        easing: theme.transitions.easing.easeIn,
        duration: theme.transitions.duration.enteringScreen,
      }),
      transform: 'translateX(0px)',
    }),
  },
  drawer: {
    flexShrink: 0,
    height: '100%',
    width: SEARCH_DRAWER_WIDTH,
  },
  drawerPaper: {
    paddingBottom: `env(safe-area-inset-bottom)`,
    width: SEARCH_DRAWER_WIDTH,
    position: 'relative !important' as 'relative',
    boxShadow: theme.shadows[16],
  },
  pullTab: {
    position: 'absolute',
    top: 'calc(50% - 30px)',
    left: -24,
    height: 60,
    width: 25,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[16],
    clipPath: 'inset(-32px 0 -32px -32px)',
    zIndex: theme.zIndex.drawer,
    borderTopLeftRadius: theme.shape.borderRadius * 1.34,
    borderBottomLeftRadius: theme.shape.borderRadius * 1.34,
    cursor: 'pointer',
    border: 'none',
    outline: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const minimizeSheet = (sheetRef: MutableRefObject<RefHandles | null>): void => {
  sheetRef.current?.snapTo(({ snapPoints }) => Math.min(...snapPoints));
};

const maximizeSheet = (
  sheetRef: MutableRefObject<RefHandles | null>,
  velocity?: number,
): void => {
  sheetRef.current?.snapTo(({ snapPoints }) => Math.max(...snapPoints), {
    ...(velocity !== undefined ? { velocity } : {}),
  });
};

export const SearchDrawer = (): JSX.Element => {
  const { open, toggleOpen } = useSearchDrawerState();
  const { classes } = useStyles({ open });
  const theme = useTheme();
  const isLargeMediaQuery = useMediaQuery(theme.breakpoints.up('tablet'));
  const focusRef = useRef(null);
  const sheetRef = useRef<RefHandles | null>(null);
  const { clearSelectedPois } = usePoisState();
  const { t } = useTranslation();
  const appState = useForegroundState();
  const prevAppState = usePrevious(appState);
  const selectedGeoEventId = useSelectedWildfireGeoEventId();
  const mobileScrollbarRef = useRef<HTMLElement | null>(null);
  const desktopScrollbarRef = useRef<HTMLDivElement | null>(null);
  const { height: windowHeight } = useWindowSize();
  const maxHeightOffset = Capacitor.getPlatform() === 'ios' ? 100 : 50;

  const searchCardMinSnapPx = getSearchCardMinSnapPx();

  const maxSnap = windowHeight - maxHeightOffset;
  const snapPoints: number[] = useMemo(
    () => [searchCardMinSnapPx, maxSnap],
    [searchCardMinSnapPx, maxSnap],
  );

  useEffect(() => {
    /**
     * Close bottom sheet whenever there's an app state change from
     * foreground to background or when there's a selected geo event.
     * This case handles geoevents selected programmatically and not
     * by a user's interaction, e.g. tapping on a push notification.
     */
    if (selectedGeoEventId || (prevAppState?.isActive && !appState.isActive)) {
      if ((sheetRef.current?.height ?? 0) > searchCardMinSnapPx) {
        setTimeout(() => {
          minimizeSheet(sheetRef);
        }, 0);
      }
    }
  }, [searchCardMinSnapPx, prevAppState, appState, selectedGeoEventId]);

  const [sheetOpen, setSheetOpen] = useState(false);

  const handleSpringEnd = (event: SpringEvent): void => {
    if (sheetRef.current) {
      setSheetOpen(sheetRef.current.height > searchCardMinSnapPx);
    }
  };

  const handleSpringStart = (event: SpringEvent): void => {
    if (event.type === 'OPEN') {
      return;
    }

    mobileScrollbarRef.current = document.querySelector(
      `#${SEARCH_DRAWER_ID} [data-rsbs-scroll]`,
    );

    clearSelectedPois();
    setSheetOpen(true);
  };

  const handleCloseBottomSheet = (): void => {
    if (Capacitor.isNativePlatform()) {
      Keyboard.hide();
    }

    minimizeSheet(sheetRef);
  };

  // This is only used for results that don't link to a route (i.e., places that drop a pin)
  const handleClickResult = (): void => {
    setTimeout(() => {
      minimizeSheet(sheetRef);
    }, 300);
  };

  const handleSearchFocus = (): void => {
    maximizeSheet(sheetRef);
  };

  const restoreMaxDrawer = (): void => {
    // Keyboard was hidden - make sure the bottom sheet stays up and the
    // keyboard closing doesn't close the drawer unintentionally
    setTimeout(() => maximizeSheet(sheetRef, DRAWER_SNAP_QUICK_VELOCITY), 300);
  };

  if (isLargeMediaQuery) {
    return (
      <Box className={classes.drawerContainer}>
        <Drawer
          classes={{ root: classes.drawer, paper: classes.drawerPaper }}
          variant="persistent"
          anchor="right"
          open={open}
        >
          <Box
            sx={{
              overflow: 'auto',
              maxHeight: '100%',
            }}
            ref={desktopScrollbarRef}
          >
            <SearchBar scrollbarRef={desktopScrollbarRef} />
            <SearchResults />
          </Box>
        </Drawer>
        <button
          type="button"
          className={classes.pullTab}
          onClick={toggleOpen}
          aria-label={
            open
              ? t('home.searchDrawer.pullTab.close')
              : t('home.searchDrawer.pullTab.open')
          }
        >
          {open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </button>
      </Box>
    );
  }

  return (
    <BottomSheet
      id={SEARCH_DRAWER_ID}
      open
      skipInitialTransition
      ref={sheetRef}
      initialFocusRef={focusRef}
      onDismiss={handleCloseBottomSheet}
      snapPoints={() => snapPoints}
      defaultSnap={() => Math.min(...snapPoints)}
      expandOnContentDrag
      blocking={false}
      header
      onSpringEnd={handleSpringEnd}
      onSpringStart={handleSpringStart}
      // Locks maxHeight in cases when orientation changes, preventing
      // changes on snap points and interactions issues
      maxHeight={window.innerHeight}
    >
      <SearchBar
        onCancel={handleCloseBottomSheet}
        scrollbarRef={mobileScrollbarRef}
        onFocus={handleSearchFocus}
        onBeforeFocusDrawerElement={restoreMaxDrawer}
      />
      {sheetOpen && <SearchResults onBeforeClickResult={handleClickResult} />}
    </BottomSheet>
  );
};
