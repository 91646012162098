import { useState } from 'react';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  MenuItem,
  TextField,
  Typography,
  Grid,
  FormHelperText,
  Box,
  CircularProgress,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import { NotificationOption, NotificationSetting } from 'shared/types';
import BlackButton from 'components/BlackButton';
import { useNotifications } from 'hooks/useNotifications';
import GrayButton from 'components/GrayButton';
import { NotificationSettingTypes, NotificationTypes } from '../../constants';

type CountyNotificationDialogProps = {
  open: boolean;
  onClose: () => void;
  notificationSetting: NotificationSetting | null;
};

type NotificationSettingOption = Extract<
  NotificationOption,
  'all' | 'new_incidents_only'
>;

const useStyles = makeStyles()((theme) => ({
  paper: {
    borderRadius: theme.shape.borderRadius * 1.34,
    padding: theme.spacing(3, 2),
    width: '100%',
    [theme.breakpoints.down(480)]: {
      margin: theme.spacing(2),
    },
  },
  title: {
    padding: 0,
  },
  content: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  checkbox: {
    padding: 4,
    marginLeft: 5,
    marginRight: 5,
  },
  helperText: {
    fontSize: theme.typography.subtitle1.fontSize,
  },
  actions: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const notificationSettingToOptions = {
  [NotificationSettingTypes.all]: NotificationSettingTypes.all,
  [NotificationSettingTypes.allWithSilent]: NotificationSettingTypes.all,
  [NotificationSettingTypes.onlyNew]: NotificationSettingTypes.onlyNew,
  [NotificationSettingTypes.onlyNewWithSilent]:
    NotificationSettingTypes.onlyNew,
};

const CountyNotificationDialog = (
  props: CountyNotificationDialogProps,
): JSX.Element => {
  const { open, onClose, notificationSetting } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();
  const [includeSilent, setIncludeSilent] = useState(
    notificationSetting?.setting === NotificationSettingTypes.allWithSilent ||
      notificationSetting?.setting ===
        NotificationSettingTypes.onlyNewWithSilent,
  );
  const [notificationOption, setNotificationOption] =
    useState<NotificationSettingOption>(
      notificationSettingToOptions[notificationSetting?.setting ?? ''] ??
        NotificationSettingTypes.all,
    );
  const { putNotificationSettings } = useNotifications();

  const handleSave = (): void => {
    if (!notificationSetting) return;

    let setting: NotificationOption = notificationOption;

    if (includeSilent) {
      if (setting === NotificationSettingTypes.all) {
        setting = NotificationSettingTypes.allWithSilent;
      } else if (setting === NotificationSettingTypes.onlyNew) {
        setting = NotificationSettingTypes.onlyNewWithSilent;
      }
    }

    putNotificationSettings.mutate(
      [
        {
          ...notificationSetting,
          setting,
        },
      ],
      {
        onSuccess: () => onClose(),
      },
    );
  };

  const handleRemove = (): void => {
    if (!notificationSetting) return;
    putNotificationSettings.mutate(
      [
        {
          ...notificationSetting,
          setting: NotificationSettingTypes.off,
        },
      ],
      {
        onSuccess: () => onClose(),
      },
    );
  };

  return (
    <Dialog
      aria-labelledby="county-notification-dialog-title"
      open={open}
      onClose={onClose}
      classes={{ paper: classes.paper }}
    >
      <DialogTitle
        id="county-notification-dialog-title"
        className={classes.title}
      >
        <Typography variant="h3" component="span">
          {notificationSetting?.region.displayName}
        </Typography>
      </DialogTitle>

      <DialogContent className={classes.content}>
        <Grid container spacing={2} direction="column" sx={{ marginTop: 0 }}>
          <Grid item>
            <TextField
              select
              id="field-control-updates"
              label={t('notifications.dialog.inputs.notificationOption.label')}
              fullWidth
              value={notificationOption}
              onChange={(e) =>
                setNotificationOption(
                  e.target.value as NotificationSettingOption,
                )
              }
              disabled={putNotificationSettings.isPending}
            >
              <MenuItem value={NotificationTypes.ALL.key}>
                {NotificationTypes.ALL.displayName}
              </MenuItem>
              <MenuItem value={NotificationTypes.NEW_INCIDENTS_ONLY.key}>
                {NotificationTypes.NEW_INCIDENTS_ONLY.displayName}
              </MenuItem>
            </TextField>
          </Grid>
          <Grid item component="form" noValidate>
            <FormControlLabel
              control={
                <Checkbox
                  name="isPrescribed"
                  checked={includeSilent}
                  onChange={(event) => setIncludeSilent(event.target.checked)}
                  className={classes.checkbox}
                />
              }
              label={t('notifications.dialog.inputs.includeSilent.label')}
              disabled={putNotificationSettings.isPending}
            />
            <FormHelperText className={classes.helperText}>
              {t('notifications.dialog.inputs.includeSilent.helperText')}
            </FormHelperText>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions className={classes.actions}>
        <Grid container spacing={2} direction="column">
          <Grid item>
            <Button
              fullWidth
              size="large"
              onClick={handleSave}
              disabled={putNotificationSettings.isPending}
            >
              {t('notifications.dialog.buttons.save')}
            </Button>
          </Grid>
          <Grid item>
            <BlackButton
              fullWidth
              size="large"
              onClick={handleRemove}
              disabled={putNotificationSettings.isPending}
            >
              {t('notifications.dialog.buttons.remove')}
            </BlackButton>
          </Grid>
          <Grid item>
            <GrayButton
              fullWidth
              size="large"
              onClick={onClose}
              disabled={putNotificationSettings.isPending}
            >
              {t('notifications.dialog.buttons.cancel')}
            </GrayButton>
          </Grid>

          {putNotificationSettings.isPending && (
            <Grid item>
              <Box className={classes.loaderContainer}>
                <CircularProgress size={32} />
              </Box>
            </Grid>
          )}
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default CountyNotificationDialog;
